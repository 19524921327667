export function createStdArticle(data, callback) {
	let { createStdArticleRequest, createStdArticleError, createStdArticleResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_article',
		this.createBundle(
			'create_article',
			{ article: data },
			this.POST,
			createStdArticleRequest,
			(result) => {
				createStdArticleResult(result)
				callback(result)
			},
			createStdArticleError
		)
	);
}
