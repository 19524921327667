import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { withRouter } from 'react-router'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import {
    Default,
    Mobile,
    formatMoney,
    displayTime,
    displayTimestamp,
    timeAndCosts,
    pureData,
    overviewData,
    timeAndCostsMultiData,
    pureDataMultiData,
    overviewMultiData,
    jobOverview,
} from '../lib/util'

import GaugeChart from 'react-gauge-chart'
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react'
import DatePicker from 'react-datepicker'

import { Header, Button, Input, Card, Modal, Form, Dropdown, Icon, Transition, Statistic } from 'semantic-ui-react'
import { Confirmation, ImageDefault, SimpleTable } from '../components'

import moment from 'moment-timezone'
import Moment from 'react-moment'

const { app, jobs, finalBalance, jobEdit, costEdit, tempJobs, jobMerge } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    3: 'Pausa',
    99: 'Termine Lavori',
}

class Job extends Component {
    constructor(props) {
        super(props)
        let { job } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            selectedJobWork: {},
            visualizationType: 'all',
            job,
        }
    }

    componentDidMount() {
        let { job, jobId, network } = this.props
        if ((!job || !job.jobCode) && jobId) {
            network.getJob(jobId)
        }
        network.getMachineList('', false)
        network.getWorkerList('', false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { job } = this.state
        let { getJob, jobId, network, hadesTokenOk, getFrom, getFromDerived, forcedOpen } = this.props
        if (!forcedOpen) return

        // Job data
        if (hadesTokenOk && getJob && (prevProps.hadesTokenOk != hadesTokenOk || getJob.fetching !== prevProps.getJob.fetching) && !getJob.fetching) {
            if (getJob.status.success && getJob.data[jobId]) {
                let job = getJob.data[jobId][0]
                this.setState({ job })

                let { workOrders = [] } = job
                let id = job.jobCode
                let reqs = []

                for (let order of workOrders) {
                    let sub_id = order.workCode
                    reqs.push({
                        destination: 'job',
                        id: `${id}`,
                        sub_id: `${sub_id}`,
                    })
                }
                network.getFromDerived(reqs)
            }
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props

                let allHadesData = {}
                for (let hdata of hadesData) {
                    let { data = [] } = hdata || {}
                    let firstRow = data[0] || {}
                    let { id, sub_id } = firstRow
                    allHadesData[`${id}##${sub_id}`] = data
                }

                let currentHadesData = [...hadesData]

                let overview = overviewMultiData(null, null, currentHadesData)

                let productQuantity = 0
                let { workOrders = [] } = job

                for (let w of workOrders) {
                    productQuantity += parseInt(w.productQuantity) || 0
                }
                overview.productQuantity = productQuantity
                this.setState(
                    {
                        overview,
                        currentHadesData,
                        hadesData,
                        allHadesData,
                    },
                    () => {
                        this.computeTimeCosts()
                        this.computePureData()
                    }
                )
            }
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network } = this.props
        let { job } = this.state
        network.deleteJob(job)
        this.setState({ deleting: false })
    }

    renderBilledDate() {
        let { noActions, login } = this.props
        let { job, dateModal, newLastBillDate } = this.state
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }
        return (
            !noActions &&
            user &&
            !!user.manage_job && (
                <Card.Meta>
                    <View>
                        <View column>
                            <View>Ultima fatturazione: {!job.lastBillDate ? ' -- ' : ''}</View>
                            <View justify="center">{job.lastBillDate ? moment(job.lastBillDate).format('LL') : ''}</View>
                        </View>

                        <Icon
                            onClick={() => {
                                let date = moment(job.lastBillDate).isValid() ? moment(job.lastBillDate) : moment()
                                this.setState({
                                    newLastBillDate: date,
                                    dateModal: true,
                                })
                            }}
                            name="edit"
                        />
                    </View>
                    <Modal open={dateModal}>
                        <Modal.Header>Modifica data di fatturazione per la commessa {job.jobCode}</Modal.Header>
                        <Modal.Content>
                            <DateInput
                                name="newLastBillDate"
                                placeholder="Data di ultima fatturazione"
                                value={newLastBillDate}
                                iconPosition="left"
                                popupPosition="bottom right"
                                onChange={(event, { name, value }) => {
                                    this.setState({
                                        newLastBillDate: moment(value, 'DD-MM-YYYY'),
                                    })
                                }}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                primary
                                onClick={() => {
                                    this.setState({ dateModal: false })
                                }}
                            >
                                Annulla <Icon name="check" />
                            </Button>
                            <Button
                                primary
                                onClick={() => {
                                    let { job, newLastBillDate } = this.state
                                    let { network } = this.props
                                    let { jobCode, workOrders } = job
                                    job.lastBillDate = newLastBillDate

                                    let date = moment(job.lastBillDate).format('YYYY-MM-DD')
                                    network.createJob(jobCode, {
                                        workOrders,
                                        lastBillDate: date,
                                    })
                                    this.setState({ job, dateModal: false })
                                }}
                            >
                                Conferma <Icon name="check" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Card.Meta>
            )
        )
    }

    renderBillsAndBudjet() {
        let { noActions, forcedOpen } = this.props
        let { job, newBudgeted, newBilled, budgetedModal, billedModal, total = {} } = this.state
        let { budgeted, billed } = job

        return (
            !noActions && (
                <View column fullh fullw justify="space-around" align="center">
                    <View>
                        <Statistic size="mini" style={{ marginBottom: 0, alignSelf: 'center' }}>
                            <Statistic.Label>
                                Preventivato{' '}
                                <Icon
                                    onClick={() => {
                                        this.setState({
                                            newBudgeted: budgeted || 0,
                                            budgetedModal: true,
                                        })
                                    }}
                                    name="edit"
                                />
                                <Modal open={budgetedModal}>
                                    <Modal.Header>Modifica cifra preventivata per la commessa {job.jobCode}</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <Form.Field
                                                control={Input}
                                                type="number"
                                                label="Preventivato"
                                                placeholder="0.0"
                                                step="0.1"
                                                name="budgeted"
                                                value={newBudgeted}
                                                className="rightTextField"
                                                onChange={(e, { value }) => this.setState({ newBudgeted: value })}
                                            />
                                        </Form>{' '}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button
                                            primary
                                            onClick={() => {
                                                this.setState({ budgetedModal: false })
                                            }}
                                        >
                                            Annulla <Icon name="check" />
                                        </Button>
                                        <Button
                                            primary
                                            onClick={() => {
                                                let { network } = this.props

                                                let { jobCode, workOrders } = job
                                                network.createJob(jobCode, {
                                                    workOrders,
                                                    budgeted: newBudgeted,
                                                })
                                                this.setState({ job, budgetedModal: false })
                                            }}
                                        >
                                            Conferma <Icon name="check" />
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Statistic.Label>
                            <Statistic.Value className="smallestStatistic">{newBudgeted || budgeted || ' -- '}€</Statistic.Value>
                        </Statistic>
                    </View>
                    <View>
                        <Statistic size="mini">
                            <Statistic.Label>
                                Fatturato{' '}
                                <Icon
                                    onClick={() => {
                                        this.setState({
                                            newBilled: billed || 0,
                                            billedModal: true,
                                        })
                                    }}
                                    name="edit"
                                />
                                <Modal open={billedModal}>
                                    <Modal.Header>Modifica cifra fatturazione commessa {job.jobCode}</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    control={Input}
                                                    type="number"
                                                    label="Fatturato"
                                                    placeholder="0.0"
                                                    step="0.1"
                                                    name="billed"
                                                    value={newBilled}
                                                    className="rightTextField"
                                                    onChange={(e, { value }) => this.setState({ newBilled: value })}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button
                                            primary
                                            onClick={() => {
                                                this.setState({ billedModal: false })
                                            }}
                                        >
                                            Annulla <Icon name="check" />
                                        </Button>
                                        <Button
                                            primary
                                            onClick={() => {
                                                let { network } = this.props

                                                let { jobCode, workOrders } = job
                                                network.createJob(jobCode, {
                                                    workOrders,
                                                    billed: newBilled,
                                                })
                                                this.setState({ job, billedModal: false })
                                            }}
                                        >
                                            Conferma <Icon name="check" />
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Statistic.Label>
                            <Statistic.Value className="smallestStatistic">{newBilled || billed || ' -- '}€</Statistic.Value>
                        </Statistic>
                    </View>
                    {forcedOpen && (
                        <View>
                            <Statistic size="mini" style={{ marginBottom: 0, alignSelf: 'center' }}>
                                <Statistic.Label>Spese calcolate </Statistic.Label>
                                <Statistic.Value className="smallestStatistic">{formatMoney(total.cost || 0) || ' -- '}€</Statistic.Value>
                            </Statistic>
                        </View>
                    )}
                </View>
            )
        )
    }

    renderCardContent(direction) {
        let { type, forcedOpen, role, machineList = [], workerList = [] } = this.props
        let { job, visualizationType } = this.state
        let { budgeted, billed, budgetedModal, billedModal, client, image, jobCode, jobDescription, jobType, workOrders } = job

        let overview = this.state.overview || this.props.overview || {}
        // console.info('OVERVIEW', this.state.overview, this.props.overview)
        let qr = overview.quantity / (overview.quantity + overview.scrap) || 0

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }
        let worker = workerList.filter((x) => x.workerIdentifier == overview.operator)[0]
        let { workerName } = worker || {}
        let machine = machineList.filter((x) => x.machineIdentifier == overview.terminal)[0]
        let { machineName } = machine || {}
        let gaugeid = jobCode.replace(/\s|\./g, '')

        return (
            <View justify="space-between" fullw {...extraViewProps}>
                <View column width="20%">
                    <View noflex>
                        <ImageDefault fromRoot src={image} ui={false} style={styles.jobImage} />
                    </View>
                    <View column align="center" style={{ textAlign: 'center' }}>
                        <Header as="h3" style={{ margin: 0, marginTop: 10 }}>
                            {jobCode}
                        </Header>
                        <Card.Description>{jobDescription}</Card.Description>
                        {/* <Card.Description>Tipo di Commessa: {jobType}</Card.Description> */}
                        <View height={10} />
                        {this.renderBilledDate()}
                    </View>
                </View>

                <View column width="20%" style={{ maxWidth: 200 }}>
                    <View justify="center">
                        <Header as="h4">Dati</Header>
                    </View>
                    <View column fullw style={{ marginTop: 20 }}>
                        <GaugeChart id={`job-${gaugeid}-qr`} colors={['#FF0000', '#00FF00']} nrOfLevels={20} percent={qr} textColor="#000000" />
                        <View fullw justify="center">
                            <Statistic color="blue" size="mini">
                                <Statistic.Value>{overview.productQuantity || '--'}</Statistic.Value>
                                <Statistic.Label>tot</Statistic.Label>
                            </Statistic>
                            <Statistic color="green" size="mini">
                                <Statistic.Value>{overview.quantity || '--'}</Statistic.Value>
                                <Statistic.Label>corretti</Statistic.Label>
                            </Statistic>
                            <Statistic color="yellow" size="mini">
                                <Statistic.Value>{overview.scrap || '--'}</Statistic.Value>
                                <Statistic.Label>errati</Statistic.Label>
                            </Statistic>
                        </View>
                    </View>
                </View>
                {(!role || role === 'admin') && (
                    <View column width="20%">
                        <View column fullh justify="center">
                            <Header style={{ textAlign: 'center' }} as="h4">
                                Dati Fatturazione
                            </Header>
                            {this.renderBillsAndBudjet()}
                        </View>
                    </View>
                )}
                <View column width="20%">
                    <Header as="h5">
                        <Icon name="building outline" />
                        <Header.Content>
                            Cliente
                            <Header.Subheader>{client}</Header.Subheader>
                        </Header.Content>
                    </Header>
                    {visualizationType != 'all' && (
                        <Header as="h5">
                            <Icon name="user outline" />
                            <Header.Content>
                                Ultimo Impiegato
                                <Header.Subheader>{workerName || '--'}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    )}
                    {visualizationType != 'all' && (
                        <Header as="h5">
                            <Icon name="hdd outline" />
                            <Header.Content>
                                Ultima Macchina
                                <Header.Subheader>{machineName || '--'}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    )}
                    <Header as="h5">
                        <Icon name="clock outline" />
                        <Header.Content>
                            Ultimo Rilevamento
                            <Header.Subheader>{displayTimestamp(overview.timestamp)}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </View>
            </View>
        )
    }

    renderControls() {
        let { selectedJobWork, visualizationType, job } = this.state
        let { network } = this.props
        let { workOrders = [] } = job

        let options0 = [
            {
                key: 'all',
                value: 'all',
                text: 'Commessa Completa',
            },
            {
                key: 'job',
                value: 'job',
                text: 'Per ODL',
            },
        ]
        let options1 = []
        for (let opt of workOrders) {
            options1.push({
                key: `${opt.jobCode}##${opt.workCode}`,
                value: `${opt.jobCode}##${opt.workCode}`,
                text: `${opt.jobCode} - ${opt.workCode}`,
            })
        }

        return (
            <View justify="space-between">
                <Header as="h4">
                    <Icon name="filter" />
                    <Header.Content>
                        Visualizza info per{' '}
                        <Dropdown
                            inline
                            header="Modalità"
                            options={options0}
                            value={visualizationType}
                            onChange={(e, { value }) => {
                                this.setState({ visualizationType: value }, () => {
                                    if (value == 'all') {
                                        let currentHadesData = [...this.state.hadesData]
                                        let overview = overviewMultiData(null, null, currentHadesData)
                                        let productQuantity = 0
                                        for (let w of workOrders) {
                                            productQuantity += parseInt(w.productQuantity) || 0
                                        }
                                        overview.productQuantity = productQuantity
                                        this.setState({ selectedJobWork: {}, overview, currentHadesData }, () => {
                                            this.computeTimeCosts()
                                            this.computePureData()
                                        })
                                    } else {
                                        this.setState({ overview: {}, currentHadesData: [] }, () => {
                                            this.computeTimeCosts()
                                            this.computePureData()
                                        })
                                    }
                                })
                            }}
                        />
                    </Header.Content>
                </Header>
                {
                    {
                        job: (
                            <Dropdown
                                placeholder="Seleziona Lavoro"
                                fluid
                                search
                                selection
                                options={options1}
                                value={`${selectedJobWork.job}##${selectedJobWork.work}`}
                                onChange={(e, { value }) => {
                                    let selected = value.split('##')
                                    let job = selected[0]
                                    let work = selected[1]
                                    let currentHadesData = this.state.allHadesData[value] || []
                                    let currentWork = workOrders.filter((x) => x.workCode == work)[0] || {}
                                    let overview = overviewData(null, null, currentHadesData)
                                    overview.productQuantity = parseInt(currentWork.productQuantity) || 0
                                    this.setState(
                                        {
                                            selectedJobWork: { job, work },
                                            overview,
                                            currentHadesData,
                                        },
                                        () => {
                                            this.computeTimeCosts()
                                            this.computePureData()
                                        }
                                    )
                                }}
                            />
                        ),
                        all: <View> </View>,
                    }[visualizationType]
                }
            </View>
        )
    }

    renderActions() {
        let { type, noActions, onOpened, forcedOpen, role, login } = this.props
        let { job, external, deleting } = this.state

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }
        return (
            type === 'full' &&
            !noActions && (
                <Card.Content extra>
                    {!forcedOpen && !external && (
                        <Button
                            onClick={() => {
                                if (onOpened) {
                                    onOpened(job, true, () => {
                                        this.props.history.push(route_from(app, jobs, `${job.jobCode}`))
                                    })
                                } else {
                                    this.props.history.push(route_from(app, jobs, `${job.jobCode}`))
                                }
                            }}
                        >
                            <Icon name="folder open" />
                            <Default>Apri</Default>
                        </Button>
                    )}

                    {forcedOpen && !external && (
                        <Button
                            disabled={true}
                            onClick={() => {
                                this.props.history.push(route_from(app, jobs, costEdit, `${job.jobCode}`))
                            }}
                        >
                            <Icon name="credit card outline" />
                            Aggiungi costo
                        </Button>
                    )}
                    {external && (
                        <Button
                            color="green"
                            onClick={() => {
                                let { network } = this.props
                                let { jobCode } = job
                                network.createJob(jobCode, {
                                    jobCode,
                                    createdExternal: 0,
                                })
                            }}
                        >
                            <Icon name="check square outline" />
                            Accetta
                        </Button>
                    )}
                    {external && (
                        <Button
                            color="yellow"
                            onClick={() => {
                                let { jobCode } = job
                                console.log('Trying to merge', jobCode)
                                this.props.history.push(route_from(app, tempJobs, jobMerge, `${job.jobCode}`))
                            }}
                        >
                            <Icon name="random" />
                            Unisci a commessa esistente
                        </Button>
                    )}
                    {user && !!user.manage_job && (
                        <Button
                            color="blue"
                            onClick={() => {
                                this.props.history.push(route_from(app, jobs, jobEdit, `${job.jobCode}`))
                            }}
                        >
                            <Icon name="edit" />
                            Modifica
                        </Button>
                    )}
                    {user && !!user.manage_job && (
                        <Button
                            color="red"
                            onClick={() => {
                                this.openRemoveDialog()
                            }}
                        >
                            <Icon name="delete" />
                            Elimina
                        </Button>
                    )}
                    {forcedOpen && !external && (!role || role === 'admin') && (
                        <Button
                            disabled={true}
                            color="blue"
                            onClick={() => {
                                if (onOpened) {
                                    onOpened(job, true, () => {
                                        this.props.history.push(route_from(app, jobs, job.jobCode, finalBalance))
                                    })
                                } else {
                                    this.props.history.push(route_from(app, jobs, job.jobCode, finalBalance))
                                }
                            }}
                        >
                            <Icon name="book" />
                            <Default>Consuntivo</Default>
                        </Button>
                    )}
                    {/* {user && !!user.manage_job && (
                        <Button
                            color="blue"
                            onClick={() => {
                                this.setState({ exportModal: true })
                            }}
                        >
                            <Icon name="clipboard list" />
                            <Default>Esporta documenti relativi alla commessa</Default>
                        </Button>
                    )} */}
                    <Confirmation
                        message="Vuoi davvero eliminare questa commessa? Tutte le lavorazioni e i costi saranno perduti!"
                        showModal={deleting}
                        onConfirm={() => {
                            this.remove()
                        }}
                        onCancel={() => {
                            this.setState({ deleting: false })
                        }}
                    />
                    {/* <Confirmation
                        message="Vuoi davvero eliminare questa mappatura?"
                        showModal={deletingMapping}
                        onConfirm={() => {
                            this.props.network.deleteMapping({
                                jobCode: job.jobCode,
                            })
                            this.setState({ deletingMapping: false })
                        }}
                        onCancel={() => {
                            this.setState({ deletingMapping: false })
                        }}
                    /> */}
                </Card.Content>
            )
        )
    }

    computeTimeCosts() {
        let { machineList, workerList } = this.props
        let { currentHadesData, hadesData } = this.state
        let timeCosts = []
        if (this.state.visualizationType == 'all') {
            timeCosts = timeAndCostsMultiData(null, null, hadesData)
        } else {
            timeCosts = timeAndCosts(null, null, currentHadesData)
        }
        let bodies = []
        let total = {
            elapsed: 0,
            quantity: 0,
            scrap: 0,
            cost: 0,
        }
        for (let key in timeCosts) {
            let reason = REASON_LABELS[key] || 'Altro'
            // console.info('searching for costs', key, timeCosts[key])
            let { elapsed, quantity, scrap, costs } = timeCosts[key]
            let cost = 0
            for (let c of costs) {
                let { operator, terminal, elapsed } = c
                let hours = elapsed / 1000 / 3600

                let worker = workerList.filter(({ workerId }) => workerId == operator)[0] || {}
                let machine = machineList.filter(({ machineId }) => machineId == terminal)[0] || {}

                let { workerHourlyRate = 0 } = worker
                let { machineHourlyRate = 0 } = machine

                cost += hours * workerHourlyRate + hours * machineHourlyRate
            }

            let unitcost = cost / Math.max(1, quantity + scrap)
            total = {
                elapsed: total.elapsed + elapsed,
                quantity: total.quantity + quantity,
                scrap: total.scrap + scrap,
                cost: total.cost + cost,
            }
            bodies.push([reason, displayTime(elapsed), quantity, scrap, `${formatMoney(cost)}€`, `${formatMoney(unitcost)}€`])
        }
        let unitcost = total.cost / Math.max(1, total.quantity + total.scrap)
        let totalRow = [displayTime(total.elapsed), total.quantity, total.scrap, `${formatMoney(total.cost)}€`, `${formatMoney(unitcost)}€`]

        this.setState({
            bodies,
            totalRow,
            total,
        })
    }
    renderPhaseTimeCosts() {
        let { bodies, totalRow } = this.state
        return (
            <SimpleTable
                label="Dati Tempi & Costi"
                labelColor="teal"
                color="teal"
                heads={['Causale', 'Tempo Trascorso', 'Corretti', 'Errati', 'Costo', 'Costo unitario']}
                bodies={bodies}
                total={totalRow}
            />
        )
    }
    computePureData() {
        let { currentHadesData, hadesData } = this.state
        let { machineList = [], workerList = [] } = this.props

        let pure = []
        if (this.state.visualizationType == 'all') {
            pure = pureDataMultiData(null, null, hadesData)
        } else {
            pure = pureData(null, null, currentHadesData)
        }
        let bodies = []
        for (let item of pure) {
            let { prevtime, timestamp, elapsed, reason, id, sub_id, quantityDiff, scrapDiff, operator, terminal } = item
            let worker = workerList.filter((x) => x.workerIdentifier == operator)[0]
            let { workerName = '--' } = worker || {}
            let machine = machineList.filter((x) => x.machineIdentifier == terminal)[0]
            let { machineName = '--' } = machine || {}
            bodies.push([
                displayTimestamp(prevtime),
                displayTimestamp(timestamp),
                displayTime(elapsed),
                REASON_LABELS[reason] || 'Altro',
                id,
                sub_id,
                quantityDiff,
                scrapDiff,
                workerName,
                machineName,
            ])
        }
        this.setState({
            pureBodies: bodies,
        })
    }
    renderPureData() {
        let { pureBodies } = this.state
        return (
            <SimpleTable
                label="Tutti i Dati"
                labelColor="blue"
                color="blue"
                heads={['Inizio', 'Fine', 'Tempo', 'Causale', 'Commessa', 'ODL', 'Corretti', 'Errati', 'Operaio', 'Macchina']}
                bodies={pureBodies}
            />
        )
    }

    render() {
        let { type, selected, isBalance, forcedOpen } = this.props
        let { color, job } = this.state
        if (!job) return null

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }
        let cardExtraStyle = {}

        if (job.urgencyLevel > 0) {
            cardExtraStyle.backgroundColor = '#ffe6a6'
        }
        if (job.active == 0) {
            cardExtraStyle.backgroundColor = '#FF8784'
        }

        if (type === 'compact') {
            return this.renderCompact()
        }

        return (
            <View className="job-content-container" noflex fullw column between>
                <Card
                    id={job.jobCode}
                    className={selected ? 'rowSelected' : ''}
                    style={{ ...styles.jobCard, ...cardExtraStyle }}
                    {...extraprops}
                    color={color}
                >
                    {/* {this.renderExportModal()} */}
                    <Card.Content style={styles.jobContent}>
                        <Default>{this.renderCardContent()}</Default>
                        <Mobile>{this.renderCardContent()}</Mobile>
                    </Card.Content>
                    {this.renderActions()}

                    {type === 'full' && (forcedOpen || isBalance) && (
                        <Transition visible={forcedOpen || isBalance ? true : this.state.showDetails} animation="slide down" duration={200}>
                            <Card.Content>
                                <View fullw column>
                                    {this.renderControls()}
                                    <View height={20} />

                                    {this.renderPhaseTimeCosts()}
                                    {this.renderPureData()}
                                </View>
                            </Card.Content>
                        </Transition>
                    )}
                </Card>
            </View>
        )
    }
}

const compact_styles = {
    header: {
        fontSize: '20pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '18pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 50,
        height: 50,
    },
    jobImage: {
        width: 50,
        height: 50,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        width: 200,
        minWidth: 200,
        height: 200,
        margin: 8,
    },
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    imageContainerMobile: {
        width: 100,
        height: 75,
        margin: 'auto',
    },
    jobImage: {
        width: '100%',
        maxHeight: 150,
        objectFit: 'contain',
    },
    jobImageMobile: {
        width: 100,
        height: 75,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let {
        getJob,
        getMachineList,
        getWorkerList,
        login,
        organization,
        getMachineList: {
            data: { data: machineList },
        },
        getWorkerList: {
            data: { data: workerList },
        },
        hades: {
            getFrom,
            getFromDerived,
            hadesToken: {
                status: { success: hadesTokenOk },
            },
        },
    } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        getJob,
        getMachineList,
        getWorkerList,
        role: rcode,
        machineList,
        workerList,
        getFrom,
        getFromDerived,
        hadesTokenOk,
        organization,
        login,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Job)))
