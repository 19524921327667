export function runReverseGeocoding(data) {
	let { runReverseGeocodingRequest, runReverseGeocodingError, runReverseGeocodingResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'run_reverse_geocoding',
		this.createBundle(
			'replace',
			{ data: data },
			this.GET,
			runReverseGeocodingRequest,
			runReverseGeocodingResult,
			runReverseGeocodingError
		)
	);
}
