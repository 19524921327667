import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Blackbox } from '../components'

const { app, blackboxes, blackboxEdit } = routes

class Blackboxes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { isEmployee, deleteBlackbox, network, search, organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            search !== prevProps.search ||
            (prevProps.deleteBlackbox.fetching && !deleteBlackbox.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            network.getBlackboxList(search.data, isEmployee)
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { isEmployee, sub, network, search } = this.props
        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Crea nuova macchina',
                    icon: 'add',
                    action: () => {
                        this.props.history.push(route_from(app, blackboxes, blackboxEdit))
                    },
                },
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }
        network.getBlackboxList(search.data, isEmployee)
    }

    renderBlackbox(key, blackbox) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (blackbox) => {
                if (onChange) {
                    onChange(blackbox)
                }
                this.setState({ selected: blackbox })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return <Blackbox {...extraProps} selected={blackbox.id_blackbox === selected.id_blackbox} type="full" key={key} blackbox={blackbox} />
    }

    render() {
        let { blackboxes, fetching, onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (blackboxes.data) {
            content = Object.keys(blackboxes.data).map((key) => {
                let value = blackboxes.data[key]
                return this.renderBlackbox(key, value)
            })
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getBlackboxList, deleteBlackbox, search, organization } = state

    return {
        blackboxes: getBlackboxList.data,
        fetching: getBlackboxList.fetching,
        deleteBlackbox,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Blackboxes)))
