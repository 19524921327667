export function writeSWA300Config(data) {
    let { writeSWA300ConfigRequest, writeSWA300ConfigError, writeSWA300ConfigResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'write_swa300_config',
        this.createBundle(
            'write_swa300_config',
            { config: data },
            this.POST,
            writeSWA300ConfigRequest,
            writeSWA300ConfigResult,
            writeSWA300ConfigError
        )
    )
}
