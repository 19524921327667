export function getStdArticleList(data) {
	let { getStdArticleListRequest, getStdArticleListError, getStdArticleListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_article_list',
		this.createBundle(
			'get_article_list',
			{ search: data || '' },
			this.GET,
			getStdArticleListRequest,
			getStdArticleListResult,
			getStdArticleListError
		)
	);
}
