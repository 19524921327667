export function getPicture(data) {
	let { getPictureRequest, getPictureError, getPictureResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_picture',
		this.createBundle(
			'get_picture',
			{ picture: data },
			this.GET,
			getPictureRequest,
			getPictureResult,
			getPictureError
		)
	);
}
