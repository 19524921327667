export function getVariantList(data) {
	let { getVariantListRequest, getVariantListError, getVariantListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_variant_list',
		this.createBundle(
			'get_variant_list',
			{ search: data || '' },
			this.GET,
			getVariantListRequest,
			getVariantListResult,
			getVariantListError
		)
	);
}
