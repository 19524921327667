import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Menu, Modal } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { buildFileSelector } from '../lib/util'

class PickFile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            items: 12,
            targetFile: this.props.value ? this.props.value : '',
            fetching: true,
            targetFiles: [],
            showModal: false,
            fileSelector: buildFileSelector(this.handleUpload),
            type: this.props.type ? this.props.type : 'file',
        }
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log('PickFile checking', this.props.value, 'with', prevProps.value);
        if (this.props.value !== prevProps.value) {
            this.setState({
                targetFile: this.props.value,
            })
        }

        //console.log('Pick targetFile updated', this.props.uploadFile);
        if (this.state.uploadFile !== prevState.uploadFile) {
            if (this.state.uploadFile.status.success) {
                this.pickFile(this.state.uploadFile.data.file)
                this.closeModal()
                return
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    pickFile(targetFile) {
        let { onChange } = this.props
        if (onChange) {
            onChange(targetFile)
        }

        this.setState({
            targetFile,
        })
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    uploadProgram = () => {
        this.state.fileSelector.click()
    }

    handleUpload = (event, name) => {
        //console.log('Handle upload called');
        let obj = { loaded: 0 }
        obj[name] = event.target.files[0]
        this.setState(obj)
        //console.log('Selected file', event.target.files[0]);

        this.props.network.uploadFile(event.target.files[0], this.state.type)
    }

    render() {
        let { buttonText } = this.props
        if (!buttonText) buttonText = 'Scegli programma'

        return (
            <Button style={{ height: 64 }} onClick={this.uploadProgram}>
                {buttonText}
            </Button>
        )
    }
}

const mapStateToProps = (state) => {
    let { uploadFile } = state

    return {
        uploadFile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PickFile)))
