export function createVariant(data, callback) {
	let { createVariantRequest, createVariantError, createVariantResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_variant',
		this.createBundle(
			'create_variant',
			{ variant: data },
			this.POST,
			createVariantRequest,
			(result) => {
				createVariantResult(result)
				callback(result)
			},
			createVariantError
		)
	);
}
