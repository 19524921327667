import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

moment.locale('it')

class SWA300Editor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            power_main: 24,
            power_second: 0,
            power_third: 0,
            pulse_type: 0,
            pulse_duration_main: 69,
            pulse_duration_second: 0,
            pulse_duration_third: 0,
            pulse_repetition: 17,
            spot_diameter: 12,
            current_recipe_number: 0,
            recipe_number_to_save: 0,
            max_frequency: 145,
            laser_in_alarm: 0,
            delayed_mode_or_first_pulse_suppress: 8,
        }
    }

    componentDidMount() {
        this.props.toolbarResult([
            {
                name: 'Invia',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        this.state.network.getLastSWA300Data()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sendingConfig !== prevProps.sendingConfig) {
            if (!this.props.sendingConfig) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                        errorMessage: '',
                    })
                }
            }
        }

        let { getLastSWA300Data } = this.props
        if (getLastSWA300Data && getLastSWA300Data.fetching !== prevProps.getLastSWA300Data.fetching && !getLastSWA300Data.fetching) {
            if (getLastSWA300Data.status.success) {
                let config = getLastSWA300Data.data.data

                this.setState(config)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        let {
            power_main,
            power_second,
            power_third,
            pulse_type,
            pulse_duration_main,
            pulse_duration_second,
            pulse_duration_third,
            pulse_repetition,
            spot_diameter,
            current_recipe_number,
            recipe_number_to_save,
            max_frequency,
            laser_in_alarm,
            delayed_mode_or_first_pulse_suppress,
        } = this.state

        this.props.network.writeSWA300Config({
            power_main,
            power_second,
            power_third,
            pulse_type,
            pulse_duration_main,
            pulse_duration_second,
            pulse_duration_third,
            pulse_repetition,
            spot_diameter,
            current_recipe_number,
            recipe_number_to_save,
            max_frequency,
            laser_in_alarm,
            delayed_mode_or_first_pulse_suppress,
        })

        this.props.history.goBack()
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    render() {
        let {
            power_main,
            power_second,
            power_third,
            pulse_type,
            pulse_duration_main,
            pulse_duration_second,
            pulse_duration_third,
            pulse_repetition,
            spot_diameter,
            current_recipe_number,
            recipe_number_to_save,
            max_frequency,
            delayed_mode_or_first_pulse_suppress,
            errorMessage,
        } = this.state

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    // loading={sendingConfig}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="power_main"
                            type="number"
                            placeholder="0"
                            name="power_main"
                            value={power_main}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="power_second"
                            type="number"
                            placeholder="0"
                            name="power_second"
                            value={power_second}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="power_third"
                            type="number"
                            placeholder="0"
                            name="power_third"
                            value={power_third}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="pulse_type"
                            type="number"
                            placeholder="0"
                            name="pulse_type"
                            value={pulse_type}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="pulse_duration_main"
                            type="number"
                            placeholder="0"
                            name="pulse_duration_main"
                            value={pulse_duration_main}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="pulse_duration_second"
                            type="number"
                            placeholder="0"
                            name="pulse_duration_second"
                            value={pulse_duration_second}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="pulse_duration_third"
                            type="number"
                            placeholder="0"
                            name="pulse_duration_third"
                            value={pulse_duration_third}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="pulse_repetition"
                            type="number"
                            placeholder="0"
                            name="pulse_repetition"
                            value={pulse_repetition}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="spot_diameter"
                            type="number"
                            placeholder="0"
                            name="spot_diameter"
                            value={spot_diameter}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="max_frequency"
                            type="number"
                            placeholder="0"
                            name="max_frequency"
                            value={max_frequency}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="delayed_mode_or_first_pulse_suppress"
                            type="number"
                            placeholder="0"
                            name="delayed_mode_or_first_pulse_suppress"
                            value={delayed_mode_or_first_pulse_suppress}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="current_recipe_number"
                            type="number"
                            placeholder="0"
                            name="current_recipe_number"
                            value={current_recipe_number}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-config-powermain"
                            control={Input}
                            label="recipe_number_to_save"
                            type="number"
                            placeholder="0"
                            name="recipe_number_to_save"
                            value={recipe_number_to_save}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Costo inserito con successo!" />
                    <Message error header="Errore" content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`} />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        writeSWA300Config: { data: createResult, fetching: sendingConfig, status: createStatus },
        getLastSWA300Data,
    } = state

    return {
        createResult,
        sendingConfig,
        createStatus,
        getLastSWA300Data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SWA300Editor)))
