export function getOrganizationList(data) {
    let { getOrganizationListRequest, getOrganizationListError, getOrganizationListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_organization_list',
        this.createBundle(
            'get_organization_list',
            { organization: data },
            this.GET,
            getOrganizationListRequest,
            getOrganizationListResult,
            getOrganizationListError
        )
    )
}
