export function createPicture(data) {
	let { createPictureRequest, createPictureError, createPictureResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_picture',
		this.createBundle(
			'create_picture',
			{ picture: data },
			this.POST,
			createPictureRequest,
			createPictureResult,
			createPictureError
		)
	);
}
