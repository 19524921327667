import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickFile } from '../components'

const { app, dataimports, dataimportEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class DataImport extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        this.state = {
            fileToImport: null,
        }
    }

    doImport() {
        this.props.network.createDataImport(this.state.fileToImport);
    }

    componentDidMount() {
        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.data_import) {
            extraTools.push({
                name: 'Salva',
                icon: 'save',
                action: this.doImport.bind(this),
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                // Not paginated yet
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: 'none',
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }
    }

    render() {
        return (
            <View fullw column between>
                <PickFile
                    type="archive"
                    buttonText="Scegli file da importare"
                    onChange={(fileToImport) => {
                        this.setState({ fileToImport: fileToImport })
                    }}
                    value={this.state.fileToImport}
                />
            </View>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    dataimportImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    dataimportContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataimportCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DataImport)))
