import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import { Confirmation, ImageDefault } from '../components'
import { Button, Icon, Card, Table, Input } from 'semantic-ui-react'
import View from 'lib/View'
import { timeString, floatTimeToString } from '../lib/util'

import {} from 'containers'
import Moment from 'react-moment'
import moment from 'moment-with-locales-es6'

import { route_from, routes } from 'lib/routes'
const { app, stock, stockEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class Article extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, article } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...article,
            articleMinutes: article ? parseFloat(article.articleMinutes) : 0,
        }

        if (onCostChange && article) {
            onCostChange(this.totalCost(), article.code)
        }
    }

    totalCost() {
        let { articleHourlyRate, articleHours, articleMinutes } = this.state
        let total = (articleHours + articleMinutes / 60) * parseFloat(articleHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { article, code, network } = this.props

        if ((!article || !article.code) && code) {
            network.getArticle(code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getArticle, login } = this.props
        let { code, articleHourlyRate } = this.state
        if (articleHourlyRate !== prevState.articleHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), code)
            }
        }

        console.log('Article getArticle fetching', getArticle.fetching)
        if (getArticle && getArticle.fetching !== prevProps.getArticle.fetching && !getArticle.fetching) {
            console.log('Done calling getArticle')
            if (getArticle.status.success) {
                console.log(getArticle.data)
                let article = getArticle.data.data

                this.setState({ ...article })
                console.log('Calling Get article', login, article)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                console.log('calling No article found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { code, articleHourlyRate, articleHours, articleMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={articleHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    articleHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{articleHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideArticle && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideArticleHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideArticle, hideArticleHours, isBalance } = this.props
        let { code, articleHours, articleMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideArticle && <Table.Cell>{code}</Table.Cell>}
                    {!hideArticleHours && <Table.Cell>{timeString(articleHours, articleMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, article } = this.props

        network.deleteArticle(article)

        this.setState({ deleting: false })
    }

    render() {
        let { type, article, onClick, selected, noActions, role } = this.props
        let {
            description,
            deleting,
            code,
            id_deposit,
            qty,
            id,
            name,
            device_key,
            organizationId,
            // articleImage,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(article)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        if (article && article.code === 1) {
            return null
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()

            default:
                return (
                    <Card className={selected ? 'rowSelected' : ''} style={styles.articleCard} {...extraprops} color={this.state.colors[col]}>
                        <Confirmation
                            message="Vuoi davvero eliminare questo articolo?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        {/* <Card.Content style={styles.articleContent}>
								<View fullw row>
									<div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={articleImage}
											ui={false}
											style={styles.articleImage}
										/>
									</div>
									<View fullw column>
										<span style={styles.header}>{code}</span>
										{!noActions &&
										(!role || role === 'admin') && (
											<Card.Description>
												Deposito: {name}
											</Card.Description>
										)}
										<Card.Description>
											Quantita': {qty}
										</Card.Description>
									</View>
								</View>
							</Card.Content> */}
                        <Card.Content>
                            <Card.Header>{description || '--'}</Card.Header>
                            <View between>
                                <Card.Meta>{code}</Card.Meta>
                                <Card.Meta>{name}</Card.Meta>
                            </View>
                            <View between style={{ paddingTop: 8 }}>
                                <View>
                                    <Card.Description>Quantit&agrave; &nbsp;</Card.Description>
                                    <Card.Meta> {qty}</Card.Meta>
                                </View>
                                <View />
                            </View>
                        </Card.Content>
                        {/* Solo visualizzazione */}
                        {/* {!noActions && (
							<Card.Content extra>
								<Button
									color='blue'
									onClick={() => {
										this.props.history.push(
											route_from(app, stock, stockEdit, `${id_deposit}`, `${code}`)
										)
									}}>
									<Icon name='edit' />
									Modifica
								</Button>
								<Button
									color='red'
									onClick={() => {
										this.openRemoveDialog()
									}}>
									<Icon name='delete' />
									Elimina
								</Button>
							</Card.Content>
						)} */}
                    </Card>
                )
            // return (
            // 	<View fullw column between>
            // 		<Card
            // 			className={selected ? 'rowSelected' : ''}
            // 			style={styles.articleCard}
            // 			{...extraprops}
            // 			color={this.state.colors[col]}>
            // 			<Confirmation
            // 				message='Vuoi davvero eliminare questo articolo?'
            // 				showModal={deleting}
            // 				onConfirm={() => {
            // 					this.remove()
            // 				}}
            // 				onCancel={() => {
            // 					this.setState({ deleting: false })
            // 				}}
            // 			/>
            // 			<Card.Content style={styles.articleContent}>
            // 				<View fullw row>
            // 					{/* <div style={styles.imageContainer}>
            // 						<ImageDefault
            // 							fromRoot
            // 							src={articleImage}
            // 							ui={false}
            // 							style={styles.articleImage}
            // 						/>
            // 					</div> */}
            // 					<View fullw column>
            // 						<span style={styles.header}>{code}</span>
            // 						{!noActions &&
            // 						(!role || role === 'admin') && (
            // 							<Card.Description>Deposito: {name}</Card.Description>
            // 						)}
            // 						<Card.Description>Quantita': {qty}</Card.Description>
            // 					</View>
            // 				</View>
            // 			</Card.Content>
            // 			{!noActions && (
            // 				<Card.Content extra>
            // 					<Button
            // 						color='blue'
            // 						onClick={() => {
            // 							this.props.history.push(
            // 								route_from(app, stock, stockEdit, `${id_deposit}`, `${code}`)
            // 							)
            // 						}}>
            // 						<Icon name='edit' />
            // 						Modifica
            // 					</Button>
            // 					<Button
            // 						color='red'
            // 						onClick={() => {
            // 							this.openRemoveDialog()
            // 						}}>
            // 						<Icon name='delete' />
            // 						Elimina
            // 					</Button>
            // 				</Card.Content>
            // 			)}
            // 		</Card>
            // 	</View>
            // )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    articleImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    articleContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    articleCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state, ownProps) => {
    let { login, getArticle } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getArticle, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Article)))
