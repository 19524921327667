import { testContract } from '../util'

export function getContract(data) {
    let { getContractRequest, getContractResult } = this.props

    getContractRequest({
        fetching: true,
        data: { data: {} },
    })

    setTimeout(() => {
        getContractResult({
            fetching: false,
            data: { ...testContract },
        })
    }, 200)

    // this.doAction(
    // 	this.WEB_REQUEST_URL + 'get_contract',
    // 	this.createBundle(
    // 		'get_contract',
    // 		{ contract: data },
    // 		this.GET,
    // 		getContractRequest,
    // 		getContractResult,
    // 		getContractError
    // 	)
    // );
}
