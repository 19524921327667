
import { GETLABELLIST_TYPES } from '../actions/getLabelList'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(GETLABELLIST_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer