import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import { Numpad } from '../components'
import { Button, Confirm, Dropdown, Grid, Icon, Statistic, Modal } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
import EllipsisText from 'react-ellipsis-text'

const REASON_COLORS = ['yellow', 'green', 'red', 'orange', 'red']
const MACHINE_COLORS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown'].sort(
    () => Math.random() - 0.5
)

const REASONS = [
    {
        id: 0,
        label: 'Setup',
    },
    {
        id: 1,
        label: 'Produzione',
    },
    {
        id: 2,
        label: 'Blocco',
    },
    {
        id: 3,
        label: 'Pausa',
    },
    {
        id: 99,
        label: 'Termina',
    },
]

class TerminalView extends Component {
    constructor(props) {
        super(props)
        let {
            network: { getCookie },
        } = props

        this.state = {
            machine: getCookie('terminal_machine'),
            incrementValue: 1,
            activeReason: undefined,
            job: undefined,
            work: undefined,
            productQuantity: undefined,
            productGood: 0,
            productScrap: 0,
            prevGood: 0,
            prevScrap: 0,
        }
    }

    componentDidMount() {
        let { network } = this.props
        network.getRecentJobList(0, 20, '', undefined, true, false)
        network.getMachineList('', true)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            network,
            realtimeData,
            createJob: {
                status: { success: cjSuccess },
                fetching: cjFetching,
            },
        } = this.props
        let {
            createJob: { fetching: pcjFetching },
        } = prevProps
        let { job, work } = this.state
        if (prevProps.realtimeData !== realtimeData) {
            let { destination, data = [] } = realtimeData || {}
            if (destination == 'job' && data.length > 0) {
                let { id, sub_id, quantity, scrap, reason } = data[0] || {}
                if (id == job && sub_id == work) {
                    if (reason == 99) {
                        network.getRecentJobList(0, 20, '', undefined, true, false)
                        this.setState({
                            activeReason: undefined,
                            job: undefined,
                            work: undefined,
                            productQuantity: undefined,
                            prevGood: 0,
                            prevScrap: 0,
                            productGood: 0,
                            productScrap: 0,
                        })
                    } else {
                        this.setState({
                            productGood: parseInt(quantity),
                            productScrap: parseInt(scrap),
                            activeReason: parseInt(reason),
                        })
                    }
                }
            }
        }
        if (pcjFetching === true && cjFetching === false && cjSuccess === true) {
            console.info('REFRESH')
            let { waitForConfirm, productGood, productScrap } = this.state
            this.setState(
                {
                    terminateDialog: false,
                    activeReason: waitForConfirm,
                    prevGood: productGood,
                    prevScrap: productScrap,
                },
                () => {
                    this.sendHadesData()
                    network.getRecentJobList(0, 20, '', undefined, true, false)
                    this.setState({
                        activeReason: undefined,
                        job: undefined,
                        work: undefined,
                        productQuantity: undefined,
                        prevGood: 0,
                        prevScrap: 0,
                        productGood: 0,
                        productScrap: 0,
                    })
                }
            )
        }
    }

    renderMachineSelection() {
        let { machines = [], network } = this.props
        return machines.length > 0 ? (
            <View fullw fullh>
                <Grid columns={Math.min(machines.length, 4)} style={{ width: '100%', margin: 0 }}>
                    {machines.map((value, index) => (
                        <Grid.Column key={index}>
                            <Button
                                color={MACHINE_COLORS[index % MACHINE_COLORS.length]}
                                style={{ height: '100%', width: '100%', padding: 0, fontSize: 'x-large' }}
                                onClick={() => {
                                    network.setCookie('terminal_machine', value)
                                    this.setState({ machine: value })
                                }}
                            >
                                <EllipsisText text={value.machineName} length={18} />
                            </Button>
                        </Grid.Column>
                    ))}
                </Grid>
            </View>
        ) : null
    }

    retrieveLatestInfo() {
        let { job, work, machine } = this.state
        let { network, worker, error } = this.props
        if (!network.socket()) {
            return error(createAlert('Problema di connessione', 'Errore di connessione al Cloud, riprovare.'))
        }
        network.socket().emit(
            'insert',
            {
                destination: 'worker_job',
                data: [
                    {
                        id: worker.identifier.toString(),
                        sub_id: 'default',
                        job: `${job}`,
                        work: `${work}`,
                    },
                ],
            },
            (response) => console.info(response)
        )
        network.socket().emit(
            'insert',
            {
                destination: 'machine_job',
                data: [
                    {
                        id: machine.machineIdentifier.toString(),
                        sub_id: 'default',
                        job: `${job}`,
                        work: `${work}`,
                    },
                ],
            },
            (response) => console.info(response)
        )

        network.socket().emit(
            'get_from',
            {
                destination: 'job',
                id: job,
                sub_id: work,
                limit: 1,
            },
            (response) => {
                let { success, data } = response
                if (success && data.length > 0) {
                    let { reason, scrap, quantity } = data[0]
                    this.setState({
                        activeReason: parseInt(reason),
                        productScrap: parseInt(scrap),
                        productGood: parseInt(quantity),
                        prevScrap: parseInt(scrap),
                        prevGood: parseInt(quantity),
                    })
                }
            }
        )
    }
    sendHadesData() {
        let { job, work, machine, activeReason, productGood, productScrap } = this.state
        let { network, worker, error } = this.props
        if (!network.socket()) {
            return error(createAlert('Problema di connessione', 'Errore di connessione al Cloud, riprovare.'))
        }
        if (!job || !work) return
        network.socket().emit(
            'insert',
            {
                destination: 'job',
                data: [
                    {
                        id: job,
                        sub_id: work,
                        terminal: machine.machineIdentifier.toString(),
                        operator: worker.identifier.toString(),
                        quantity: productGood,
                        scrap: productScrap,
                        reason: activeReason,
                    },
                ],
            },
            (response) => console.info(response)
        )
    }

    renderSelection() {
        let { jobs } = this.props
        let { job, work, filteredJobs = [] } = this.state

        if (filteredJobs.length == 0) filteredJobs = [...jobs]
        let options = filteredJobs.map((value) => {
            return {
                key: value.jobCode,
                text: `${value.jobCode} - ${value.description}` + (value.urgencyLevel == 1 ? ' [URGENTE]' : ''),
                value: value.jobCode,
            }
        })

        let currentJob = jobs.filter((value) => value.jobCode == job)[0] || {}
        let { workOrders = [] } = currentJob
        let w_options = []
        for (let w of workOrders) {
            w_options.push({
                key: w.workCode,
                text: `${w.workCode} - ${w.product}`,
                value: w.workCode,
            })
        }

        let allorders_options = []
        for (let job of jobs) {
            let { workOrders = [] } = job
            for (let w of workOrders) {
                if (!allorders_options.some((el) => el.key === w.workCode)) {
                    allorders_options.push({
                        key: w.workCode,
                        text: `${w.workCode} - ${w.product}`,
                        value: w.workCode,
                    })
                }
            }
        }
        return (
            <>
                <div style={{ alignSelf: 'center', width: '80%' }}>
                    <Dropdown
                        placeholder="Filtra per ODL"
                        search
                        selection
                        clearable
                        options={allorders_options}
                        value={this.state.searchOrder}
                        onChange={(e, { value }) => {
                            let filteredJobs = []
                            for (let job of jobs) {
                                for (let w of job.workOrders) {
                                    if (w.workCode == value) {
                                        if (!filteredJobs.some((el) => el.jobCode === job.jobCode)) {
                                            filteredJobs.push({ ...job })
                                        }
                                    }
                                }
                            }

                            this.setState({
                                searchOrder: value,
                                filteredJobs,
                                activeReason: undefined,
                                job: undefined,
                                work: undefined,
                                productQuantity: undefined,
                                prevGood: 0,
                                prevScrap: 0,
                                productGood: 0,
                                productScrap: 0,
                            })
                        }}
                    />
                </div>
                <div style={{ alignSelf: 'center', width: '80%' }}>
                    <Dropdown
                        placeholder="Scegli commessa"
                        selection
                        options={options}
                        onChange={(e, { value }) => {
                            this.setState(
                                {
                                    //pause
                                    activeReason: 3,
                                },
                                () => {
                                    this.sendHadesData()

                                    this.setState({
                                        activeReason: undefined,
                                        job: value,
                                        work: undefined,
                                        productQuantity: undefined,
                                        prevGood: 0,
                                        prevScrap: 0,
                                        productGood: 0,
                                        productScrap: 0,
                                    })
                                }
                            )
                        }}
                    />
                </div>
                {job !== undefined && (
                    <div style={{ alignSelf: 'center', width: '80%' }}>
                        <Dropdown
                            placeholder="Scegli ODL"
                            selection
                            options={w_options}
                            onChange={(e, { value }) => {
                                this.setState(
                                    {
                                        //pause
                                        activeReason: 3,
                                    },
                                    () => {
                                        this.sendHadesData()
                                        let currentWork =
                                            workOrders.filter((item) => {
                                                return item.workCode == value
                                            })[0] || {}

                                        let { productQuantity } = currentWork
                                        this.setState(
                                            {
                                                activeReason: undefined,
                                                job,
                                                work: value,
                                                productQuantity,
                                                productGood: 0,
                                                productScrap: 0,
                                            },
                                            () => {
                                                this.retrieveLatestInfo()
                                            }
                                        )
                                    }
                                )
                            }}
                        />
                    </div>
                )}
            </>
        )
    }

    renderStatistics() {
        let { productQuantity, productGood, productScrap } = this.state
        return (
            <View fullw style={{ justifyContent: 'space-around' }}>
                <Statistic color="blue" size="mini">
                    <Statistic.Value>{productQuantity || '--'}</Statistic.Value>
                    <Statistic.Label>da fare</Statistic.Label>
                </Statistic>
                <Statistic color="green" size="mini">
                    <Statistic.Value>{productGood || 0}</Statistic.Value>
                    <Statistic.Label>corretti</Statistic.Label>
                </Statistic>
                <Statistic color="yellow" size="mini">
                    <Statistic.Value>{productScrap || 0}</Statistic.Value>
                    <Statistic.Label>errati</Statistic.Label>
                </Statistic>
            </View>
        )
    }

    renderReasonManagement() {
        let { incrementValue, prevGood, prevScrap, productGood, productScrap, work, activeReason, job } = this.state
        let disabled = work === undefined
        let incrementDisables = disabled || activeReason === undefined

        return (
            <>
                {/* add remove */}
                <View fullw between style={{ height: '30%' }}>
                    <Button
                        disabled={incrementDisables}
                        style={{ width: '25%' }}
                        icon
                        color="green"
                        labelPosition="left"
                        onClick={() => {
                            this.setState(
                                (prevState) => ({
                                    productGood: prevState.productGood + incrementValue,
                                    productScrap,
                                }),
                                () => this.sendHadesData()
                            )
                        }}
                    >
                        <Icon name="add circle" />
                        <b>{incrementValue}pz.</b> Corretti
                    </Button>
                    <Button
                        disabled={incrementDisables}
                        style={{ width: '25%' }}
                        icon
                        color="red"
                        labelPosition="left"
                        onClick={() => {
                            this.setState(
                                (prevState) => ({
                                    productGood: Math.max(0, prevState.productGood - incrementValue),
                                    productScrap,
                                }),
                                () => this.sendHadesData()
                            )
                        }}
                    >
                        <b>{incrementValue}pz.</b> Corretti
                        <Icon name="minus circle" />
                    </Button>
                    <Button
                        disabled={incrementDisables}
                        style={{ width: '25%' }}
                        icon
                        color="yellow"
                        labelPosition="left"
                        onClick={() => {
                            this.setState(
                                (prevState) => ({
                                    productGood,
                                    productScrap: prevState.productScrap + incrementValue,
                                }),
                                () => this.sendHadesData()
                            )
                        }}
                    >
                        <Icon name="add circle" />
                        <b>{incrementValue}pz.</b> Scarti
                    </Button>
                    <Button
                        disabled={incrementDisables}
                        style={{ width: '25%' }}
                        icon
                        color="red"
                        labelPosition="left"
                        onClick={() => {
                            this.setState(
                                (prevState) => ({
                                    productGood,
                                    productScrap: Math.max(0, prevState.productScrap - incrementValue),
                                }),
                                () => this.sendHadesData()
                            )
                        }}
                    >
                        <b>{incrementValue}pz.</b> Scarti
                        <Icon name="minus circle" />
                    </Button>
                </View>
                {/* reason */}
                <View fullw style={{ height: '50%' }}>
                    <Button.Group fluid>
                        {REASONS.map((value, index) => (
                            <Button
                                disabled={disabled}
                                key={value.id}
                                color={activeReason === value.id ? REASON_COLORS[index] : null}
                                onClick={() => {
                                    if (value.id == 99) return this.setState({ terminateDialog: true, waitForConfirm: value.id })
                                    if (activeReason >= 0 && activeReason <= 2 && prevGood == productGood && prevScrap == productScrap) {
                                        // Going from a phase to another and nothing was added or removed, show dialog
                                        return this.setState({
                                            confirmToSetup: true,
                                            waitForConfirm: index,
                                        })
                                    }

                                    this.setState(
                                        {
                                            activeReason: value.id,
                                            prevGood: productGood,
                                            prevScrap: productScrap,
                                        },
                                        () => this.sendHadesData()
                                    )
                                }}
                            >
                                {value.label}
                            </Button>
                        ))}
                    </Button.Group>
                </View>
                <Confirm
                    open={this.state.confirmToSetup}
                    content="Non e&#39; stato inserito o scartato nessuno pezzo nuovo. Vuoi davvero passare alla prossima fase?"
                    cancelButton="Annulla"
                    confirmButton="Continua"
                    onCancel={() => this.setState({ confirmToSetup: false })}
                    onConfirm={() => {
                        let { waitForConfirm } = this.state
                        this.setState(
                            {
                                confirmToSetup: false,
                                activeReason: waitForConfirm,
                                prevGood: productGood,
                                prevScrap: productScrap,
                            },
                            () => this.sendHadesData()
                        )
                    }}
                />
                <Modal
                    open={this.state.terminateDialog}
                    closeOnEscape={() => this.setState({ terminateDialog: false })}
                    closeOnDimmerClick={() => this.setState({ terminateDialog: false })}
                    onClose={() => this.setState({ terminateDialog: false })}
                >
                    <Modal.Header>Terminazione</Modal.Header>
                    <Modal.Content>
                        <p>Terminare la Commessa o l'Ordine di lavoro?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({ terminateDialog: false })} negative>
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState(
                                    {
                                        terminateDialog: false,
                                        activeReason: 3,
                                        prevGood: productGood,
                                        prevScrap: productScrap,
                                    },
                                    () => this.sendHadesData()
                                )
                            }}
                            positive
                            labelPosition="right"
                            icon="checkmark"
                            content="Chiudi Ordine di Lavoro"
                        />
                        <Button
                            onClick={() => {
                                let { jobs } = this.props
                                let currentJob = jobs.filter((value) => value.jobCode == job)[0] || {}
                                let { workOrders } = currentJob
                                this.props.network.createJob(
                                    job,
                                    {
                                        workOrders,
                                        urgencyLevel: 0,
                                        active: 0,
                                    },
                                    true
                                )
                            }}
                            color="red"
                            icon="checkmark"
                            content="Chiudi Commessa"
                        />
                    </Modal.Actions>
                </Modal>
            </>
        )
    }

    render() {
        let { machine } = this.state
        if (machine === undefined) return this.renderMachineSelection()
        return (
            <View column fullw fullh between style={{ padding: 10 }}>
                {/* header */}
                <View row fullw style={{ height: '30%' }}>
                    {/* select object */}
                    <View column style={{ width: '50%', justifyContent: 'space-around' }}>
                        {this.renderSelection()}
                    </View>
                    {/* charts */}
                    <View style={{ width: '50%', alignItems: 'center' }}>{this.renderStatistics()}</View>
                </View>
                {/* Body */}
                <View row fullw style={{ height: '70%' }}>
                    {/* left cell */}
                    <View column fullh style={{ width: '70%', justifyContent: 'space-around' }}>
                        {this.renderReasonManagement()}
                    </View>
                    {/* right cell */}
                    <View fullh style={{ width: '30%' }}>
                        <Numpad
                            label="Incremento"
                            value={this.state.incrementValue}
                            onChange={(value) => {
                                if (value > 1000000) return
                                this.setState({ incrementValue: value })
                            }}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getMachineList: {
            data: { data: machines },
        },
        getRecentJobList: {
            data: { data: jobdata },
        },
        loginEmployee: {
            data: { user: worker },
        },
        realtime: { data: realtimeData = {} },
        createJob,
    } = state

    let jobs = []
    for (let job in jobdata) {
        let jb = jobdata[job][0]
        if (jb.active == 1) jobs.push(jb)
    }

    return {
        jobs,
        machines,
        worker,
        realtimeData,
        createJob,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TerminalView)))
