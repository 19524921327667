import React, { Component } from 'react'

//router
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { route_from, routes } from 'lib/routes'
import { withRouter } from 'react-router'
// store
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from 'reducers/reducers'

import { connect } from 'react-redux'

import { Image } from 'semantic-ui-react'

// network provider
import Network from 'lib/NetworkProvider'
import { connectNetwork } from 'lib/NetworkProvider'
import { Main, Login } from 'containers'

import { CookiesProvider } from 'react-cookie'

import MetaTags from 'react-meta-tags'
import { Default } from 'lib/util'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

const { app, login, employee, insertion, terminal } = routes
const store = createStore(reducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

class PrivateRoute extends Component {
    constructor(props) {
        super(props)
        let {
            location: { pathname },
        } = this.props
        this.state = {
            pathToRestore: pathname,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { isEmployee, login, loginEmployee, history } = this.props
        let { pathToRestore } = this.state

        if (
            (login.authenticated !== prevProps.login.authenticated && login.authenticated && !isEmployee) ||
            (loginEmployee.authenticated !== prevProps.loginEmployee.authenticated && loginEmployee.authenticated && isEmployee)
        ) {
            if (pathToRestore !== '' && pathToRestore !== '/' && !pathToRestore.toLowerCase().startsWith('/Impiegato'.toLowerCase())) {
                console.log('Replacing empty string')
                history.replace(this.state.pathToRestore)
                this.setState({ pathToRestore: '' })
            } else {
                if (isEmployee) {
                    console.log('Replacing WORKER path')
                    history.replace(route_from(employee, routes[process.env.REACT_APP_DEFAULT_WORKER_ROUTE]))
                } else {
                    console.log('Replacing ADMIN path:', process.env, process.env.REACT_APP_DEFAULT_ROUTE)
                    history.replace(route_from(app, routes[process.env.REACT_APP_DEFAULT_ROUTE]))
                }
            }
        }
    }

    render() {
        let { component, isEmployee, render, login: loginStatus, loginEmployee: loginEmployeeStatus, ...rest } = this.props
        let Target = component

        return (
            <Route
                {...rest}
                render={(props) => {
                    if (!isEmployee) {
                        if (loginStatus.authenticated) {
                            return component ? <Target {...props} /> : render()
                        } else {
                            return <Redirect to={route_from(login)} />
                        }
                    } else {
                        if (loginEmployeeStatus.authenticated) {
                            return component ? <Target isEmployee {...props} /> : render()
                        } else {
                            return <Redirect to={route_from(employee)} />
                        }
                    }
                }}
            />
        )
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee } = state

    return {
        login,
        loginEmployee,
    }
}

const PrivateRouteConnected = withRouter(connect(mapStateToProps)(connectNetwork(PrivateRoute)))

class App extends Component {
    renderSyncodeLogo = () => {
        return (
            <>
                <Image
                    style={{
                        zIndex: 100,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: 170,
                        top: -5,
                    }}
                    src={require('assets/black.png')}
                />
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 80,
                        top: 5,
                        left: 5,
                    }}
                    src={require('assets/logo.png')}
                />
            </>
        )
    }

    renderVfStampiLogo = () => {
        return (
            <>
                {/* <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 170,
                        top: 0,
                        left: 0,
                    }}
                    src={require('assets/logo-vf.png')}
                /> */}
                <Image
                    style={{
                        zIndex: 101,
                        pointerEvents: 'none',
                        position: 'absolute',
                        width: 110,
                        height: 90,
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                    }}
                    src={require('assets/logo-vf-alt.jpg')}
                />
            </>
        )
    }

    render() {
        let extraRouterProps = {}
        if (this.props.history) {
            extraRouterProps.history = this.props.history
        }

        let hostname = window.location.hostname
        let location = window.location.pathname
        let adminHost = true
        let employeeHost = true

        let title = process.env.REACT_APP_ADMIN_TITLE

        if (hostname === process.env.REACT_APP_ADMIN_DOMAIN) {
            title = process.env.REACT_APP_ADMIN_TITLE
            employeeHost = false
        }

        if (hostname === process.env.REACT_APP_EMPLOYEE_DOMAIN) {
            title = process.env.REACT_APP_EMPLOYEE_TITLE
            adminHost = false
        }

        if (!employeeHost && location.startsWith(route_from(employee))) {
            window.location = route_from(app)
        }

        if (!adminHost && !location.startsWith(route_from(employee))) {
            window.location = route_from(employee)
        }

        return (
            <CookiesProvider>
                <Provider store={store}>
                    <Network>
                        <React.Fragment>
                            <Router {...extraRouterProps} basename={''}>
                                <MetaTags>
                                    <title>{title}</title>
                                </MetaTags>

                                {adminHost && <Route exact path={route_from(login)} component={Login} />}
                                {employeeHost && <Route exact path={route_from(employee)} render={() => <Login isEmployee />} />}
                                {adminHost && <PrivateRouteConnected path={route_from(app)} component={Main} />}
                                {employeeHost && <PrivateRouteConnected isEmployee path={route_from(employee)} render={() => <Main isEmployee />} />}
                            </Router>
                            {/* TODO Resp */}
                            {process.env.REACT_APP_BRANDING === 'syncode' && <Default>{this.renderSyncodeLogo()}</Default>}
                            {process.env.REACT_APP_BRANDING === 'vfstampi' && <Default>{this.renderVfStampiLogo()}</Default>}
                        </React.Fragment>
                    </Network>
                </Provider>
            </CookiesProvider>
        )
    }
}

export default App
