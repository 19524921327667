import React, { Component } from 'react'
import { Button, Header, Icon, Modal, Segment } from 'semantic-ui-react'

class Confirmation extends Component {
    cancel = () => {
        let { onCancel } = this.props
        if (onCancel) {
            onCancel()
        }
    }

    confirm = () => {
        let { onConfirm } = this.props
        if (onConfirm) {
            onConfirm()
        }
    }

    render() {
        let { showModal, message } = this.props

        return (
            <Modal onClose={this.closeModal} open={showModal}>
                <Modal.Header>Conferma azione</Modal.Header>
                <Modal.Content image scrolling style={{ padding: 0 }}>
                    <Segment placeholder style={{ width: '100%' }}>
                        <Header style={{ fontSize: '30pt' }} icon>
                            <Icon name="warning" />
                            {message ? message : 'Sei sicuro?'}
                        </Header>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.cancel}>
                        Annulla <Icon name="check" />
                    </Button>
                    <Button primary onClick={this.confirm}>
                        Conferma <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default Confirmation
