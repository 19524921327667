import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Input, Message, Select } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
import { PickImage, PickFile } from '../components'

moment.locale('it')

const { app, jobs } = routes

class JobEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        let { job } = this.props.match.params

        if (!id) {
            id = job
        }

        this.state = {
            updating: false,
            id,
            jobCode: id,
            description: '',
            active: 1,
            jobType: 2,
            client: '',
            image: '',
            urgencyLevel: 0,
            createdExternal: 0,
            errorMessage: '',
            addDate: false,
            workOrders: [
                {
                    workCode: 'Ordine di lavoro 1',
                    product: 'Lavorazione 1',
                    productQuantity: 1,
                },
            ],
            lastBillDate: new Date(),
            billed: 0.0,
            budgeted: 0.0,
            productCode: false,
            status: 'ok', // warning, error, success
        }
    }

    componentDidMount() {
        let { jobCode } = this.state
        let { sub } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Salva',
                    icon: 'save',
                    position: 'right',
                    action: () => {
                        this.save()
                    },
                },
            ])
        }

        this.state.network.getProductList(null, null, '')
        this.state.network.getJobTypeList()

        if (jobCode) {
            this.state.network.getJob(jobCode)
            this.setState({ updating: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { success, error } = this.props

        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creatingJob !== prevProps.creatingJob) {
            if (!this.props.creatingJob) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    success(
                        createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                            this.props.history.push(route_from(app, jobs, `${this.state.finalJobCode}`))
                        })
                    )
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    if (
                        this.props.createStatus.error &&
                        this.props.createStatus.error.response &&
                        this.props.createStatus.error.response.status === 409
                    ) {
                        error(createAlert('Il codice commessa inserito esiste già', 'Modificare il codice commessa o modifica dalla lista commesse'))

                        this.setState({
                            status: 'error',
                            errorMessage: 'Il codice commessa inserito esiste già: modificare il codice commessa o modifica dalla lista commesse.',
                        })
                    } else {
                        error(createAlert('Errore del server', 'Riprova piú tardi'))
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }

        let { id } = this.state
        let { getJob } = this.props
        if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
            if (getJob.status.success) {
                let job = getJob.data[id][0]

                let momobj = moment(job.lastBillDate, 'YYYY-MM-DD').add('days', 1)
                console.log('momobj is', momobj)
                if (momobj.isValid()) {
                    job.lastBillDate = momobj.toDate()
                } else {
                    job.lastBillDate = new Date()
                }
                this.setState(job)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let {
            id,
            jobCode,
            description,
            jobType,
            client,
            image,
            prodMap,
            productCode,
            billed,
            budgeted,
            active,
            urgencyLevel,
            createdExternal,
            addDate,
            workOrders,
            updating,
            jobProgram,
        } = this.state

        let { warning } = this.props

        if (!jobCode || jobCode === '') {
            warning(createAlert('Inserire il codice commessa', 'Il codice commessa è un campo richiesto'))
            this.setState({
                status: 'warning',
                errorMessage: 'Il codice commessa è un campo richiesto',
            })
            return
        }

        // let finalJobCode = jobCode
        let finalDescription = description

        if (productCode) {
            let product = prodMap[productCode]
            finalDescription = `${finalDescription}\n${product.description}`
        }

        // console.log('JobEditor updating:', updating)

        let finalJobCode = jobCode

        // if (!updating && productCode) finalJobCode = productCode + finalJobCode
        // if (!updating && addDate) finalJobCode = finalJobCode + moment().format('DDMMMYY').toUpperCase()

        console.log('Final job code will be', finalJobCode)

        this.setState({ finalJobCode })

        this.props.network.createJob(id, {
            jobCode: finalJobCode,
            description: finalDescription,
            jobType,
            client,
            image,
            //lastBillDate: moment(lastBillDate).format('YYYY-MM-DD'),
            billed,
            budgeted,
            workOrders,
            urgencyLevel,
            createdExternal,
            active,
            jobProgram,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        let { onChange } = this.props
        if (onChange) {
            let { jobCode, description, jobType, client, image, urgencyLevel, createdExternal, billed, active, budgeted, jobProgram } = this.state
            onChange({
                jobCode,
                description,
                jobType,
                client,
                image,
                urgencyLevel,
                createdExternal,
                billed,
                active,
                budgeted,
                jobProgram,
            })
        }
    }

    render() {
        let {
            fetching,
            creatingJob,
            options,
            prodOptions,
            jobCode,
            description,
            jobType,
            client,
            image,
            lastBillDate,
            billed,
            budgeted,
            errorMessage,
            urgencyLevel,
            createdExternal,
            active,
            productCode,
            workOrders,
            addDate,
            id,
            updating,
            jobProgram,
        } = this.state

        console.log('Id is')
        console.dir(id)

        let finalJobCode = jobCode ? jobCode : ''
        if (productCode) finalJobCode = productCode + finalJobCode
        if (addDate) finalJobCode = finalJobCode + moment().format('DDMMMYY').toUpperCase()

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creatingJob}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-article-type"
                            control={Select}
                            search
                            options={prodOptions}
                            label="Codice prodotto (Opzionale)"
                            placeholder="Codice prodotto (Opzionale)"
                            name="productCode"
                            value={productCode}
                            disabled={updating}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Tipo selected', id)
                                this.setState({ productCode: id })
                            }}
                        />
                    </Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-id"
                            disabled={this.state.updating}
                            control={Input}
                            label="Codice commessa"
                            name="jobCode"
                            value={jobCode}
                            // disabled={!!id || updating}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="C000"
                        />
                        <Form.Field
                            id="form-input-control-job-description"
                            control={Input}
                            label="Descrizione"
                            placeholder="Fresature"
                            name="description"
                            value={description}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-job-type"
                            control={Select}
                            options={options}
                            label="Tipo commessa"
                            placeholder="Tipo commessa"
                            name="jobType"
                            value={jobType}
                            onChange={(e, data) => {
                                let id = data.value
                                this.setState({ jobType: id })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Checkbox}
                            label="Aggiungi data al codice commessa"
                            checked={addDate}
                            disabled={updating}
                            onChange={(e, data) => {
                                this.setState({ addDate: data.checked })
                            }}
                        />
                        <Form.Field id="form-input-control-job-id" control={Input} label="Codice commessa finale" value={finalJobCode} disabled />
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label="Immagine"
                            placeholder="Immagine"
                            name="image"
                            value={image}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ image: image })
                            }}
                            value={this.state.image}
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-job-program"
                            control={Input}
                            label="Programma"
                            placeholder="Programma"
                            name="jobProgram"
                            value={jobProgram}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickFile
                            onChange={(jobProgram) => {
                                this.setState({ jobProgram: jobProgram })
                            }}
                            value={this.state.jobProgram}
                        />
                    </Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-client"
                            control={Input}
                            label="Cliente"
                            placeholder="Cliente"
                            name="client"
                            value={client}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    {workOrders.map((value, index) => {
                        return (
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-workorder-id"
                                    control={Input}
                                    label="Codice ordine di lavoro"
                                    name="workCode"
                                    disabled={!!id}
                                    value={value.workCode}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].workCode = value
                                        this.setState({ workOrders })
                                    }}
                                    placeholder="Ordine di lavoro 1"
                                />
                                <Form.Field
                                    id="form-input-control-workorder-product"
                                    control={Input}
                                    label="Oggetto da produrre"
                                    placeholder="Prodotto 1"
                                    name="description"
                                    value={value.product}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].product = value
                                        this.setState({ workOrders })
                                    }}
                                />
                                <Form.Field
                                    id="form-input-control-workorder-quantity"
                                    control={Input}
                                    label="Quantita&#39; da produrre"
                                    placeholder="Quantita"
                                    name="productQuantity"
                                    value={value.productQuantity}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].productQuantity = value
                                        this.setState({ workOrders })
                                    }}
                                />
                                {!id && (
                                    <Button
                                        onClick={() => {
                                            console.log('Adding work order')
                                            let { workOrders } = this.state
                                            console.log('Work orders in state', workOrders, index)
                                            workOrders.splice(index, 1)
                                            console.log('Work orders in state', workOrders, index)
                                            this.setState({ workOrders })
                                        }}
                                    >
                                        -
                                    </Button>
                                )}
                            </Form.Group>
                        )
                    })}
                    {!id && (
                        <Button
                            onClick={() => {
                                console.log('Adding work order')
                                let { workOrders } = this.state
                                workOrders.push({
                                    workCode: 'Nuovo ordine di lavoro',
                                    product: 'Prodotto',
                                    productQuantity: 1,
                                })
                                this.setState({ workOrders })
                            }}
                        >
                            +
                        </Button>
                    )}

                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-bill-date"
                            control={DatePicker}
                            label="Data ultima fatturazione"
                            name="lastBillDate"
                            value={lastBillDate}
                            dateFormat="dd/MM/yyyy"
                            selected={lastBillDate}
                            onChange={(data) => {
                                this.setState({
                                    lastBillDate: data,
                                })
                            }}
                        />
                        <Form.Field
                            id="form-input-control-job-billed"
                            control={Input}
                            type="number"
                            label="Fatturato"
                            placeholder="0.0"
                            step="0.1"
                            name="billed"
                            value={billed}
                            className="rightTextField"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                        <Form.Field
                            id="form-input-control-job-budgeted"
                            control={Input}
                            type="number"
                            label="Preventivato"
                            placeholder="0.0"
                            step="0.1"
                            name="budgeted"
                            value={budgeted}
                            className="rightTextField"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                    </Form.Group>

                    <Form.Field
                        control={Checkbox}
                        label="Commessa URGENTE"
                        checked={urgencyLevel > 0}
                        onChange={(e, data) => {
                            this.setState({ urgencyLevel: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        jobType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    } = this.state
                                    onChange({
                                        jobType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    })
                                }
                            })
                        }}
                    />
                    <Form.Field
                        control={Checkbox}
                        label="Commessa creata esternamente"
                        checked={createdExternal === 1}
                        onChange={(e, data) => {
                            this.setState({ createdExternal: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        jobType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    } = this.state
                                    onChange({
                                        jobType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    })
                                }
                            })
                        }}
                    />
                    <Form.Field
                        control={Checkbox}
                        label="Commessa attiva"
                        checked={active === 1}
                        onChange={(e, data) => {
                            this.setState({ active: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        jobType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    } = this.state
                                    onChange({
                                        jobType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        jobProgram,
                                    })
                                }
                            })
                        }}
                    />
                    <View>
                        <Form.Field
                            id="form-button-control-cancel"
                            control={Button}
                            content="Annulla"
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        />
                        <Form.Field
                            id="form-button-control-save"
                            control={Button}
                            content="Salva"
                            onClick={() => {
                                this.save()
                            }}
                        />
                    </View>
                    <Message success header="Form Completed" content="Commessa inserita con successo!" />
                    <Message error header="Errore" content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`} />
                    <Message warning header="Errore inserimento" list={[`${errorMessage}`]} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getProductList: {
            data: { product: products },
            fetching: fetchProduct,
        },
        getJobTypeList: {
            data: { data: types },
            fetching,
        },
        createJob: { data: createResult, fetching: creatingJob, status: createStatus },
        getJob,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        options.push({
            key: type.jobType,
            text: type.typeName,
            value: type.jobType,
        })
    }

    let prodMap = {}
    let prodOptions = []
    for (let i in products) {
        let type = products[i]
        prodOptions.push({
            key: type.code,
            text: type.code + ' ' + type.description,
            value: type.code,
        })
        prodMap[type.code] = type
    }
    prodOptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })

    return {
        prodOptions,
        prodMap,
        fetchProduct,
        options,
        types,
        fetching,
        createResult,
        creatingJob,
        createStatus,
        getJob,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(JobEditor)))
