export function deleteWorkList(data, isEmployee) {
    let { deleteWorkListRequest, deleteWorkListError, deleteWorkListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'delete_work_list',
        this.createBundle(
            'delete_work_list',
            { works: data },
            this.POST,
            deleteWorkListRequest,
            deleteWorkListResult,
            deleteWorkListError,
            isEmployee
        )
    )
}
