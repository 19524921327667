export function createLabel(data) {
	let { createLabelRequest, createLabelError, createLabelResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_label',
		this.createBundle(
			'create_label',
			{ label: data },
			this.GET,
			createLabelRequest,
			createLabelResult,
			createLabelError
		)
	);
}
