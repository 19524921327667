import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Loader } from 'semantic-ui-react'
import Job from '../job/Job'
import { baseViewDispatch, createAlert, initializePage } from '../lib/util'

class MoldMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            totalPages: 1,
            search: '',
        }

        this.printRef = React.createRef()
    }

    next() {
        this.setState(
            {
                page: this.state.page + 1,
            },
            () => {
                this.setToolbar()
            }
        )
    }

    prev() {
        if (this.state.page === 0) return

        this.setState(
            {
                page: this.state.page - 1,
            },
            () => {
                this.setToolbar()
            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getMappingData, search, network, isEmployee } = this.props

        if (
            (!this.props.deleteMapping.fetching && prevProps.deleteMapping.fetching) ||
            (!this.props.createMapping.fetching && prevProps.createMapping.fetching)
        ) {
            network.getMappingData(isEmployee)
        }

        if (!getMappingData.fetching && prevProps.getMappingData.fetching) {
            this.setState(
                {
                    totalPages: Math.ceil(Object.keys(getMappingData.data.data).length / this.state.items),
                },
                () => {
                    this.setToolbar()
                }
            )
        }

        if (search !== prevProps.search) {
            this.setState({
                search,
                page: 0,
            })

            if (getMappingData.data.data) {
                this.setState(
                    {
                        totalPages: Math.ceil(
                            Object.keys(getMappingData.data.data).filter((key) => {
                                const value = getMappingData.data.data[key]
                                return (
                                    search === '' ||
                                    value.jobCode.indexOf(search) >= 0 ||
                                    value.description.indexOf(search) >= 0 ||
                                    value.row == search ||
                                    value.column == search
                                )
                            }).length / this.state.items
                        ),
                    },
                    () => {
                        this.setToolbar()
                    }
                )
            }
        }
    }

    setToolbar() {
        const { sub, login, network } = this.props
        const { page, totalPages } = this.state

        const toolbar = [
            {
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    this.prev()
                },
            },
            {
                name: `${page + 1} su ${totalPages}`,
                position: 'none',
                type: 'text',
            },
            {
                name: '',
                icon: 'arrow right',
                position: 'none',
                action: () => {
                    this.next()
                },
            },
        ]

        const filters = {}
        initializePage(this, toolbar, filters)
    }

    componentDidMount() {
        const { network } = this.props

        network.getMappingData()
    }

    renderJob(key, worklist) {
        return <Job showDetails={false} type={'full'} key={key} job={worklist[0]} works={worklist} selected={false} />
    }

    render() {
        let { getMappingData } = this.props
        const { page, items, search } = this.state

        if (getMappingData.fetching) {
            return <Loader active />
        } else if (getMappingData.data && getMappingData.data.data) {
            const content = []
            content.push(
                ...Object.keys(getMappingData.data.data)
                    .filter((key) => {
                        const value = getMappingData.data.data[key]
                        return (
                            search === '' ||
                            value.jobCode.indexOf(search) >= 0 ||
                            value.description.indexOf(search) >= 0 ||
                            value.row == search ||
                            value.column == search
                        )
                    })
                    .slice(page * items, page * items + items)
                    .map((key) => {
                        let value = getMappingData.data.data[key]
                        return this.renderJob(key, [value])
                    })
            )
            return (
                <View column fullw fullh>
                    <div ref={this.printRef}>{content}</div>
                </View>
            )
        } else {
            return <Loader active />
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getMappingData,
        createMapping,
        search: { data: searchText },
        deleteMapping,
    } = state

    return { getMappingData, createMapping, search: searchText, deleteMapping }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MoldMapping)))
