export function getArticleRecap(page, amount, search, deposit) {
    let { getArticleRecapRequest, getArticleRecapError, getArticleRecapResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'app/stock/get_article_list',
        this.createBundle(
            'app/stock/get_article_list',
            { page, amount, search, deposit },
            this.POST,
            getArticleRecapRequest,
            getArticleRecapResult,
            getArticleRecapError
        )
    )
}
