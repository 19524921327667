export function createMachineType(data) {
    let { createMachineTypeRequest, createMachineTypeError, createMachineTypeResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'create_machine_type',
        this.createBundle(
            'create_machine_type',
            { machinetype: data },
            this.POST,
            createMachineTypeRequest,
            createMachineTypeResult,
            createMachineTypeError
        )
    )
}
