export function setArticleVariantOrdering(data) {
	let { setArticleVariantOrderingRequest, setArticleVariantOrderingError, setArticleVariantOrderingResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'set_article_variant_ordering',
		this.createBundle(
			'set_article_variant_ordering',
			{ articleVariant: data },
			this.POST,
			setArticleVariantOrderingRequest,
			setArticleVariantOrderingResult,
			setArticleVariantOrderingError
		)
	);
}
