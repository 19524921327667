import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { withRouter } from 'react-router'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'

import {
    Default,
    Mobile,
    formatMoney,
    displayTime,
    displayTimestamp,
    timeAndCosts,
    pureData,
    overviewData,
    timeAndCostsMultiData,
    pureDataMultiData,
    overviewMultiData,
} from '../lib/util'

import GaugeChart from 'react-gauge-chart'
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react'
import { Header, Button, Card, Dropdown, Icon, Transition, Statistic } from 'semantic-ui-react'
import { Confirmation, ImageDefault, SimpleTable } from '../components'

import moment from 'moment-timezone'
import Moment from 'react-moment'

const { app, machines, machineEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    3: 'Pausa',
    99: 'Termine Lavori',
}

class Machine extends Component {
    constructor(props) {
        super(props)
        let { machine } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            jobsWorks: [],
            selectedJobWork: {},
            visualizationType: 'job',
            ...machine,
        }
    }

    componentDidMount() {
        let { machine, machineId, network, workerList = [] } = this.props
        if ((!machine || !machine.machineId) && machineId) {
            network.getMachine(machineId)
        }
        if (workerList.length == 0) {
            network.getWorkerList('', false)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getMachine, network, getFrom, getFromDerived } = this.props

        // Machine Data
        if (getMachine && getMachine.fetching !== prevProps.getMachine.fetching && !getMachine.fetching) {
            if (getMachine.status.success) {
                let machine = getMachine.data.data
                this.setState({ ...machine })
                network.getFrom({
                    destination: 'machine_job',
                    id: `${machine.machineIdentifier}`,
                    sub_id: 'default',
                    filters: [
                        {
                            key: 'timestamp',
                            symbol: '>=',
                            value: moment().subtract(1, 'months'),
                        },
                    ],
                })
            } else {
                console.warn('calling No machine found with this id')
            }
        }

        // Job data by machine
        if (getFrom && getFrom.fetching !== prevProps.getFrom.fetching && !getFrom.fetching) {
            let {
                data: { data = [] },
            } = getFrom
            if (data.length > 0) {
                let jobsWorks = []
                for (let dt of data) {
                    let { job, work } = dt
                    if (!jobsWorks.some((el) => el.job === job && el.work == work)) {
                        jobsWorks.push({ job, work })
                    }
                }

                let { job, work } = jobsWorks[0]
                let selectedJobWork = { job, work }
                this.setState(
                    {
                        jobsWorks,
                        selectedJobWork,
                    },
                    () => {
                        let reqs = []
                        for (let jw of jobsWorks) {
                            let { job, work } = jw
                            let filters = []
                            if (this.state.visualizationType != 'job') {
                                filters = [
                                    {
                                        key: 'timestamp',
                                        symbol: '>=',
                                        value: this.state.from.format(),
                                    },
                                    {
                                        key: 'timestamp',
                                        symbol: '<',
                                        value: this.state.to.format(),
                                    },
                                ]
                            }
                            reqs.push({
                                destination: 'job',
                                id: `${job}`,
                                sub_id: `${work}`,
                                filters,
                            })
                        }
                        network.getFromDerived(reqs)
                    }
                )
            }
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props

                let allHadesData = {}
                for (let hdata of hadesData) {
                    let { data = [] } = hdata || {}
                    let firstRow = data[0] || {}
                    let { id, sub_id } = firstRow
                    allHadesData[`${id}##${sub_id}`] = data
                }

                let { data: currentHadesData = [] } = hadesData[0] || {}
                let overview = overviewData(`${this.state.machineIdentifier}`, 'terminal', currentHadesData)
                if (this.state.visualizationType != 'job') {
                    overview = overviewMultiData(`${this.state.machineIdentifier}`, 'terminal', hadesData)
                }
                this.setState({
                    overview,
                    currentHadesData,
                    hadesData,
                    allHadesData,
                })
            }
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, machine } = this.props
        network.deleteMachine(machine)
        this.setState({ deleting: false })
    }

    renderCardContent(direction) {
        let { type, workerList = [] } = this.props
        let { machineName, machineHourlyRate, machineImage, machineType, selectedJobWork, machineId } = this.state

        let overview = this.state.overview || this.props.overview || {}
        let qr = Math.max(0, overview.quantity) / (Math.max(0, overview.quantity) + Math.max(0, overview.scrap)) || 0

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }
        let worker = workerList.filter((x) => x.workerIdentifier == overview.operator)[0]
        let { workerName } = worker || {}
        return (
            <View justify="space-between" fullw {...extraViewProps}>
                <View column width="30%">
                    <View noflex>
                        <ImageDefault fromRoot src={machineImage} ui={false} style={styles.machineImage} />
                    </View>
                    <View column align="center" style={{ textAlign: 'center' }}>
                        <Header as="h3" style={{ margin: 0, marginTop: 10 }}>
                            {machineName}
                        </Header>
                        <Card.Description>Prezzo: {machineHourlyRate}€/h</Card.Description>
                        <Card.Description>Tipo: {machineType}</Card.Description>
                    </View>
                </View>

                <View column width="30%" style={{ maxWidth: 200 }}>
                    <View justify="center">
                        <Header as="h4">Ultimi dati</Header>
                    </View>
                    <View column fullw style={{ marginTop: 20 }}>
                        <GaugeChart id={`machine-${machineId}-qr`} colors={['#FF0000', '#00FF00']} nrOfLevels={20} percent={qr} textColor="#000000" />
                        <View fullw justify="center">
                            <Statistic color="green" size="small">
                                <Statistic.Value>{overview.quantity || '--'}</Statistic.Value>
                                <Statistic.Label>corretti</Statistic.Label>
                            </Statistic>
                            <Statistic color="yellow" size="small">
                                <Statistic.Value>{overview.scrap || '--'}</Statistic.Value>
                                <Statistic.Label>errati</Statistic.Label>
                            </Statistic>
                        </View>
                    </View>
                </View>

                <View column width="30%">
                    <Header as="h5">
                        <Icon name="chart line" />
                        <Header.Content>
                            Commessa attuale
                            <Header.Subheader>
                                {overview.id} {overview.sub_id ? ` - ${overview.sub_id}` : ''}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Header as="h5">
                        <Icon name="user outline" />
                        <Header.Content>
                            Ultimo Impiegato
                            <Header.Subheader>{workerName || '--'}</Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Header as="h5">
                        <Icon name="clock outline" />
                        <Header.Content>
                            Ultimo Rilevamento
                            <Header.Subheader>{displayTimestamp(overview.timestamp)}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </View>
            </View>
        )
    }

    renderControls() {
        let { jobsWorks = [], selectedJobWork, visualizationType, machineIdentifier } = this.state
        let { network } = this.props

        let options0 = [
            {
                key: 'job',
                value: 'job',
                text: 'Commessa',
            },
            {
                key: 'day',
                value: 'day',
                text: 'Singola Giornata',
            },
            {
                key: 'range',
                value: 'range',
                text: 'Range Temporale',
            },
        ]
        let options1 = []
        for (let opt of jobsWorks) {
            options1.push({
                key: `${opt.job}##${opt.work}`,
                value: `${opt.job}##${opt.work}`,
                text: `${opt.job} - ${opt.work}`,
            })
        }

        return (
            <View justify="space-between">
                <Header as="h4">
                    <Icon name="filter" />
                    <Header.Content>
                        Visualizza info per{' '}
                        <Dropdown
                            inline
                            header="Modalità"
                            options={options0}
                            value={visualizationType}
                            onChange={(e, { value }) => {
                                this.setState({ visualizationType: value }, () => {
                                    if (value != 'job') this.setState({ selectedJobWork: {}, overview: {}, currentHadesData: [], hadesData: [] })
                                })
                            }}
                        />
                    </Header.Content>
                </Header>
                {
                    {
                        job: (
                            <Dropdown
                                placeholder="Seleziona Lavoro"
                                fluid
                                search
                                selection
                                options={options1}
                                value={`${selectedJobWork.job}##${selectedJobWork.work}`}
                                onChange={(e, { value }) => {
                                    let selected = value.split('##')
                                    let job = selected[0]
                                    let work = selected[1]
                                    let currentHadesData = this.state.allHadesData[value] || []

                                    this.setState({
                                        selectedJobWork: { job, work },
                                        overview: overviewData(`${this.state.machineIdentifier}`, 'terminal', currentHadesData),
                                        currentHadesData,
                                    })
                                }}
                            />
                        ),
                        day: (
                            <DateInput
                                name="date"
                                placeholder="Data"
                                value={this.state.date}
                                iconPosition="left"
                                popupPosition="bottom right"
                                onChange={(event, { name, value }) => {
                                    let from = moment(value, 'DD-MM-YYYY')
                                    let to = moment(from).add(24, 'hours')
                                    this.setState(
                                        {
                                            [name]: value,
                                            from,
                                            to,
                                        },
                                        () => {
                                            network.getFrom({
                                                destination: 'machine_job',
                                                id: `${machineIdentifier}`,
                                                sub_id: 'default',
                                                filters: [
                                                    {
                                                        key: 'timestamp',
                                                        symbol: '>=',
                                                        value: from.format(),
                                                    },
                                                    {
                                                        key: 'timestamp',
                                                        symbol: '<',
                                                        value: to.format(),
                                                    },
                                                ],
                                            })
                                        }
                                    )
                                }}
                            />
                        ),
                        range: (
                            <DatesRangeInput
                                name="datesRange"
                                placeholder="Da - A"
                                value={this.state.datesRange}
                                iconPosition="left"
                                onChange={(event, { name, value }) => {
                                    let date = value.split(' - ')

                                    let from = moment(date[0], 'DD-MM-YYYY')
                                    let to = moment(date[1], 'DD-MM-YYYY').add(24, 'hours')
                                    this.setState(
                                        {
                                            [name]: value,
                                            from,
                                            to,
                                        },
                                        () => {
                                            if (!from.isValid() || !to.isValid()) return
                                            network.getFrom({
                                                destination: 'machine_job',
                                                id: `${machineIdentifier}`,
                                                sub_id: 'default',
                                                filters: [
                                                    {
                                                        key: 'timestamp',
                                                        symbol: '>=',
                                                        value: from.format(),
                                                    },
                                                    {
                                                        key: 'timestamp',
                                                        symbol: '<',
                                                        value: to.format(),
                                                    },
                                                ],
                                            })
                                        }
                                    )
                                }}
                            />
                        ),
                    }[visualizationType]
                }
            </View>
        )
    }

    renderActions() {
        let { login, noActions, onOpened, forcedOpen } = this.props
        let { deleting, machineId, machineName, machineHourlyRate, machineImage, machineHours, machineMinutes } = this.state
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }
        return (
            !noActions && (
                <Card.Content extra>
                    {!forcedOpen && (
                        <Button
                            onClick={() => {
                                if (onOpened) {
                                    onOpened(
                                        {
                                            machineId,
                                            machineName,
                                            machineImage,
                                            machineHourlyRate,
                                            machineHours,
                                            machineMinutes,
                                        },
                                        true,
                                        () => {
                                            this.props.history.push(route_from(app, machines, `${machineId}`))
                                        }
                                    )
                                } else {
                                    this.props.history.push(route_from(app, machines, `${machineId}`))
                                }
                            }}
                        >
                            <Icon name="folder open" />
                            <Default>Apri</Default>
                        </Button>
                    )}
                    {user && !!user.manage_machine && (
                        <Button
                            color="blue"
                            onClick={() => {
                                this.props.history.push(route_from(app, machines, machineEdit, `${machineId}`))
                            }}
                        >
                            <Icon name="edit" />
                            <Default>Modifica</Default>
                        </Button>
                    )}

                    {user && !!user.manage_machine && (
                        <Button
                            color="red"
                            onClick={() => {
                                this.openRemoveDialog()
                            }}
                        >
                            <Icon name="delete" />
                            <Default>Elimina</Default>
                        </Button>
                    )}
                    <Confirmation
                        message="Vuoi davvero eliminare questa macchina?"
                        showModal={deleting}
                        onConfirm={() => {
                            this.remove()
                        }}
                        onCancel={() => {
                            this.setState({ deleting: false })
                        }}
                    />
                </Card.Content>
            )
        )
    }

    renderPhaseTimeCosts() {
        let { machineIdentifier, currentHadesData, hadesData, machineHourlyRate } = this.state
        let timeCosts = []
        if (this.state.visualizationType != 'job') {
            timeCosts = timeAndCostsMultiData(`${machineIdentifier}`, 'terminal', hadesData)
        } else {
            timeCosts = timeAndCosts(`${machineIdentifier}`, 'terminal', currentHadesData)
        }
        let bodies = []
        let total = {
            elapsed: 0,
            quantity: 0,
            scrap: 0,
            cost: 0,
        }
        for (let key in timeCosts) {
            let reason = REASON_LABELS[key] || 'Altro'

            let { elapsed, quantity, scrap } = timeCosts[key]
            let hours = elapsed / 1000 / 3600
            let cost = hours * machineHourlyRate
            let unitcost = cost / Math.max(1, quantity + scrap)
            total = {
                elapsed: total.elapsed + elapsed,
                quantity: total.quantity + quantity,
                scrap: total.scrap + scrap,
                cost: total.cost + cost,
            }
            bodies.push([reason, displayTime(elapsed), quantity, scrap, `${formatMoney(cost)}€`, `${formatMoney(unitcost)}€`])
        }
        let unitcost = total.cost / Math.max(1, total.quantity + total.scrap)
        let totalRow = [displayTime(total.elapsed), total.quantity, total.scrap, `${formatMoney(total.cost)}€`, `${formatMoney(unitcost)}€`]

        return (
            <SimpleTable
                label="Dati Tempi & Costi"
                labelColor="teal"
                color="teal"
                heads={['Causale', 'Tempo Trascorso', 'Corretti', 'Errati', 'Costo', 'Costo unitario']}
                bodies={bodies}
                total={totalRow}
            />
        )
    }
    renderPureData() {
        let { workerList = [] } = this.props
        let { machineIdentifier, currentHadesData, hadesData } = this.state
        let pure = []
        if (this.state.visualizationType != 'job') {
            pure = pureDataMultiData(`${machineIdentifier}`, 'terminal', hadesData)
        } else {
            pure = pureData(`${machineIdentifier}`, 'terminal', currentHadesData)
        }
        let bodies = []
        for (let item of pure) {
            let { prevtime, timestamp, elapsed, reason, id, sub_id, quantityDiff, scrapDiff, operator } = item
            let worker = workerList.filter((x) => x.workerIdentifier == operator)[0]
            let { workerName = '--' } = worker || {}
            bodies.push([
                displayTimestamp(prevtime),
                displayTimestamp(timestamp),
                displayTime(elapsed),
                REASON_LABELS[reason] || 'Altro',
                id,
                sub_id,
                quantityDiff,
                scrapDiff,
                workerName,
            ])
        }
        return (
            <SimpleTable
                label="Tutti i Dati"
                labelColor="blue"
                color="blue"
                heads={['Inizio', 'Fine', 'Tempo', 'Causale', 'Commessa', 'ODL', 'Corretti', 'Errati', 'Operaio']}
                bodies={bodies}
            />
        )
    }
    render() {
        let { type, machine, onClick, selected, showNoMachine, forcedOpen } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(machine)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)
        if (machine && machine.machineId === 1 && !showNoMachine) return null

        return (
            <View noflex fullw column between>
                <Card className={selected ? 'rowSelected' : ''} style={styles.machineCard} {...extraprops} color={this.state.colors[col]}>
                    <Card.Content style={styles.machineContent}>
                        <Default>{this.renderCardContent()}</Default>
                        <Mobile>{this.renderCardContent('column')}</Mobile>
                    </Card.Content>
                    {this.renderActions()}
                    {type === 'full' && forcedOpen && (
                        <Transition visible={forcedOpen ? true : this.state.showDetails} animation="slide down" duration={200}>
                            <Card.Content>
                                <View fullw column>
                                    {this.renderControls()}
                                    <View height={20} />
                                    {this.renderPhaseTimeCosts()}
                                    {this.renderPureData()}
                                </View>
                            </Card.Content>
                        </Transition>
                    )}
                </Card>
            </View>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    machineImage: {
        width: '100%',
        maxHeight: 120,
        objectFit: 'contain',
    },
    machineContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    machineCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let {
        login,
        organization,
        getMachine,
        hades: { getFrom, getFromDerived },
        getWorkerList: {
            data: { data: workerList },
        },
    } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        role: rcode,
        getMachine,
        workerList,
        organization,
        getFrom,
        getFromDerived,
        login,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Machine)))
