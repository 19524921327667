export function clearParent(data) {
	let { clearParentRequest, clearParentError, clearParentResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'clear_parent',
		this.createBundle(
			'clear_parent',
			{ article: data },
			this.POST,
			clearParentRequest,
			clearParentResult,
			clearParentError
		)
	);
}
