export const types = (name) => ({
    request: `${name}_REQUEST`,
    result: `${name}_RESULT`,
    error: `${name}_ERROR`,
})

export const actions = (name) => ({
    request: (result, dispatch) =>
        dispatch({
            type: types(name).request,
            result,
        }),
    result: (result, dispatch) =>
        dispatch({
            type: types(name).result,
            result,
        }),
    error: (result, dispatch) =>
        dispatch({
            type: types(name).error,
            result,
        }),
})
