import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Header, Icon, Segment, Step } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Job } from '../components'
import { Jobs } from '../containers'

const { app, tempJobs } = routes

class JobMerge extends Component {
    constructor(props) {
        super(props)

        let { job } = this.props.match.params

        this.state = {
            id: job,
            ...this.defaultState(),
        }
    }

    defaultState() {
        return {
            showCreationDialog: false,
            step: 0,
            intoJob: {},
            fromJob: null,
            works: {},
        }
    }

    componentDidMount() {
        let { id } = this.state
        let { sub, network } = this.props

        if (!sub) {
            this.props.toolbarResult([])
        }

        if (id) {
            network.getJob(id)
        }
    }

    prev() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.max(0, step - 1) })
    }

    next() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.min(2, step + 1) })
    }

    clearSearch() {
        this.props.searchResult('')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { id, fromJob } = this.state
        let { getJob, mergingJob, mergeStatus } = this.props

        console.log('JobMerge didupdate:', getJob, fromJob, !fromJob && getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching)
        if (!fromJob && getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
            if (getJob.status.success) {
                let job = getJob.data[id][0]
                console.log('JobMerge got job', job)
                this.setState({ fromJob: job })
            }
        }

        if (mergingJob !== prevProps.mergingJob) {
            if (!mergingJob) {
                if (mergeStatus.success) {
                    // Navigate back, success!
                    this.props.history.replace(route_from(app, tempJobs))
                } else {
                    if (mergeStatus.error && mergeStatus.error.response.status === 409) {
                        this.setState({
                            status: 'error',
                            errorMessage: 'Dati inseriti errati',
                        })
                    } else {
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }
    }

    doMerge() {
        let { intoJob, fromJob } = this.state
        let { network } = this.props

        console.log('Merging', fromJob, 'into', intoJob)
        network.mergeJob(fromJob, intoJob)
    }

    closeModal() {
        this.setState({
            showCreationDialog: false,
        })
    }

    render() {
        let { step, fromJob, intoJob } = this.state

        let content = <div />

        //if (this.state.getDetailedJobList.fetching) {
        //	content = <div>Loading</div>;
        //} else if (this.state.getDetailedJobList.data.data) {
        content = [
            <Step.Group key="stepgroup">
                <Step active={step === 0} completed={step > 0}>
                    <Icon name="book" />
                    <Step.Content>
                        <Step.Title>Commessa di destinazione</Step.Title>
                        <Step.Description>
                            {intoJob.jobCode
                                ? `Unendo ${fromJob.jobCode} con ${intoJob.jobCode}`
                                : 'Seleziona la commessa di destinazione'}
                        </Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                    <Icon name="random" />
                    <Step.Content>
                        <Step.Title>Conferma unione</Step.Title>
                        <Step.Description></Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>,
            <View column fullw fullh style={{ overflow: 'hidden', marginTop: 0 }} key="stepcontent">
                {
                    {
                        0: (
                            <View column fullw fullh style={{ height: '95%' }}>
                                <Jobs
                                    noActions
                                    sub
                                    searchable
                                    selectable
                                    selected={intoJob}
                                    style={{ height: '100%', overflowY: 'scroll' }}
                                    onChange={(intoJob) => {
                                        console.log('Steps chose intoJob', intoJob)
                                        this.next()
                                        this.setState({ intoJob })
                                    }}
                                />
                            </View>
                        ),
                        1: (
                            <View column fullw fullh style={{ overflowY: 'scroll' }}>
                                <Segment.Group>
                                    <Segment>
                                        <Header>Unendo</Header>
                                        <Job type="full" noActions fixedJob={fromJob} works={[fromJob]} />
                                    </Segment>
                                    <Segment>
                                        <Header>A questa commessa</Header>
                                        <Job type="full" noActions jobId={intoJob.jobCode} />
                                    </Segment>
                                    <Segment>
                                        <Header>La commessa di origine verrà eliminata!</Header>
                                    </Segment>
                                </Segment.Group>
                            </View>
                        ),
                    }[step]
                }
                <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.prev()}>Indietro</Button>
                    {step < 1 && (
                        <Button color="black" onClick={() => this.next()} disabled={step === 0 && !intoJob.jobCode}>
                            Avanti
                        </Button>
                    )}
                    {step === 1 && (
                        <Button color="green" onClick={() => this.doMerge()}>
                            Conferma
                        </Button>
                    )}
                </View>
            </View>,
        ]

        return (
            <View fullw fullh column style={{ padding: 16 }}>
                {content}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getJob,
        mergeJob: { data: mergeResult, fetching: mergingJob, status: mergeStatus },
    } = state

    return { getJob, mergeResult, mergingJob, mergeStatus }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(JobMerge))
