export const defaultState = {
    fetching: false,
    status: {
        success: false,
        error: undefined,
    },
    data: {},
    request_data: {},
}

const reducer = (TYPE) => (state = defaultState, action) => {
    // console.log("reducer for action", action);
    switch (action.type) {
        case TYPE.request: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                fetching: true,
                request_data: result,
            }
        }
        case TYPE.result: {
            let { result } = action
            return {
                ...state,
                fetching: false,
                status: {
                    success: true,
                    error: undefined,
                },
                data: result,
            }
        }
        case TYPE.error: {
            let { result } = action
            if (result === undefined) result = {}

            // console.log("Error reducer for action", action);
            return {
                ...state,
                fetching: false,
                status: {
                    success: false,
                    error: result,
                },
                data: result,
            }
        }
        default:
            return null
    }
}
export default reducer
