import moment from 'moment-with-locales-es6'
moment.locale('it')

export function login(email, password) {
    let { loginRequest, loginError, loginResult, cookies } = this.props

    let user = {
        email,
        password,
    }

    this.doAction(
        this.WEB_REQUEST_URL + 'login',
        this.createBundle(
            'login',
            { user: user },
            this.POST,
            loginRequest,
            (data) => {
                cookies.set('user', data.user, {
                    path: '/',
                    expires: moment().add(1, 'months').toDate(),
                })
                loginResult(data)
            },
            loginError
        )
    )
}

export function logout() {
    const { logout, cookies } = this.props

    cookies.remove('user', {
        path: '/',
    })
    logout()
}
