import { combineReducers } from 'redux'

import { GETFROM_TYPES, GETFROM_DERIVED_TYPES, HADESTOKEN_TYPES } from '../actions/hades'
import reducer, { defaultState } from './requests'

const getFrom = (state = defaultState, action) => {
    let requestReducer = reducer(GETFROM_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}

const getFromDerived = (state = defaultState, action) => {
    let requestReducer = reducer(GETFROM_DERIVED_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}

const hadesToken = (state = defaultState, action) => {
    let requestReducer = reducer(HADESTOKEN_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}

export default combineReducers({
    getFrom,
    getFromDerived,
    hadesToken,
})
