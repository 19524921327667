import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Area, AreaChart, CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Header, Segment, Statistic } from 'semantic-ui-react'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Job } from '../components'

moment.locale('it')

const { app, jobs, employees } = routes

const COLORS = { quantity: '#00B75B', qOptimal: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }

const REASON_LABELS = ['Setup', 'Produzione', 'Blocco']
const REASON_COLORS = ['#FDF2E9', '#E9F7EF', '#F9EBEA']
let max = 0

class DashboardChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chartData: [],
            chartDataReference: [],
            buffer: [],
            radialQuantity: [],
            radialScrap: [],
            stopChart: false,
            job: null,
            works: null,
            lastdata: { quantity: 0, scrap: 0 },
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.stopChart !== this.state.stopChart) {
            // console.log(prevProps, prevState)
            // console.log(this.props, this.state)
        }

        let { lastdata } = this.state
        let { getJob } = this.props
        let jobId = lastdata.id

        console.log('Job ID:', jobId)
        console.log('Last data:', lastdata)

        if (getJob) {
            if (getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
                if (getJob.status.success && getJob.data[jobId]) {
                    let job = getJob.data[jobId][0]
                    let works = getJob.data[jobId]

                    console.log('Dashboard chart got job', job)
                    this.setState({ job, works })
                }
            }
        }
    }

    componentDidMount() {
        let { network, login, organization, terminal } = this.props
        this.props.toolbarResult([])
        // TODO getMachineList if this DashboardChart is used elsewhere
        // Right now the list is loaded from the Dashboard component
        // network.getMachineList('')
        // network.getWorkerList()
        if (login && login.data.user && login.data.user.companies) {
            let company = login.data.user.companies[organization.data[0]]

            console.log('Network is')
            console.dir(network)
            network.doAction(
                network.HADES + 'get_from_id_filtering',
                network.createBundle(
                    `get_from_id_filtering_dash_${terminal}`,
                    {
                        name: company + '_machine',
                        id: `${terminal}`,
                        filters: [
                            {
                                key: 'timestamp',
                                symbol: '>',
                                value: moment.utc().subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                            },
                        ],
                    },
                    network.POST,
                    () => {
                        console.log('Get from id request')
                    },
                    (result) => {
                        let { lastdata } = this.state
                        let hadesData = result.data

                        console.log('Dashboard Data from filtering is', hadesData)

                        let fixedData = []
                        if (hadesData) {
                            let condensed = []
                            for (let i = 0; i < hadesData.length; i++) {
                                if (i % 1 === 0) {
                                    // TODO choose resolution
                                    hadesData[i].millis = new Date(hadesData[i].timestamp).getTime() / 1000
                                    condensed.push(hadesData[i])
                                }
                            }
                            fixedData = condensed
                        }

                        console.log('fixedData is: ', fixedData)

                        let _data = []
                        if (this.state.buffer) {
                            const buffer = this.state.buffer;
                            for (let i in buffer) {
                                buffer[i].millis = new Date(buffer[i].timestamp).getTime() / 1000
                            }
                            _data = [...fixedData, ...buffer]
                        } else {
                            _data = [...fixedData]
                        }

                        let { chartDataReference, chartData } = this.state

                        if (_data.length === 0) return null

                        chartDataReference = []
                        chartData = []
                        _data.sort((a, b) => {
                            return a.millis - b.millis
                        })

                        console.log('fixedData is: ', _data)

                        for (let d of _data) {
                            let { timestamp, millis, quantity, scrap, reason, id, sub_id } = d
                            lastdata = {
                                ...d,
                                id: sub_id,
                                sub_id: null,
                            }

                            if (quantity < 0) quantity = 0
                            if (scrap < 0) scrap = 0

                            if (chartData.some((x) => x.timestamp === timestamp)) {
                                //console.log('already in', timestamp);
                                // if alreasy has that timestamp
                                continue
                            }

                            let maxQty = Math.max(quantity, scrap)
                            if (maxQty > max) max = maxQty

                            if (chartDataReference.length !== 0) {
                                let last = chartDataReference.splice(-1)[0]
                                if (last.id === reason) {
                                    last.x2 = millis
                                } else {
                                    last.x2 = millis
                                    chartDataReference.push({ ...last })
                                    last.id = reason
                                    last.x1 = millis
                                    last.x2 = millis
                                    last.label = REASON_LABELS[reason]
                                    last.fillColor = REASON_COLORS[reason]
                                }
                                chartDataReference.push(last)
                            } else {
                                let current = {
                                    id: reason,
                                    label: REASON_LABELS[reason],
                                    x1: millis,
                                    x2: millis,
                                    y1: 0,
                                    fillColor: REASON_COLORS[reason],
                                    opacity: '0.5',
                                }
                                chartDataReference.push(current)
                            }
                            for (let item of chartDataReference) {
                                item.y2 = max + 50
                            }
                            chartData.push({
                                timestamp,
                                millis,
                                corretti: quantity,
                                scarti: scrap,
                                commessa: sub_id,
                                macchina: id,
                            })
                        }
                        let { quantity, scrap } = _data.pop()
                        let radialQuantity = [
                            {
                                name: 'Ottimale',
                                value: 1000,
                                fill: COLORS.qOptimal,
                            },
                            {
                                name: 'Quantità',
                                value: quantity,
                                fill: COLORS.quantity,
                            },
                        ]
                        let radialScrap = [
                            {
                                name: 'Massimo',
                                value: 500,
                                fill: COLORS.sMax,
                            },
                            {
                                name: 'Scarti',
                                value: scrap,
                                fill: COLORS.scrap,
                            },
                        ]

                        this.props.network.getJob(lastdata.id)
                        return this.setState({
                            chartData: [...chartData],
                            chartDataReference: [...chartDataReference],
                            buffer: [],
                            radialQuantity,
                            radialScrap,
                            lastdata: lastdata,
                        })
                    },
                    (error) => {
                        console.error('Get from id error', error)
                    }
                )
            )
        }
    }

    componentWillReceiveProps(nextProps) {
        let { lastdata } = this.state
        let { terminal: terminalId } = this.props
        let { data } = nextProps

        if (Object.keys(data).length === 0) return null
        if (data.terminal !== terminalId) return null

        data.millis = new Date(data.timestamp).getTime() / 1000
        console.log(data.timestamp, 'To millis is', data.millis)

        if (this.state.stopChart) {
            return this.setState({
                buffer: [...this.state.buffer, data],
            })
        }
        let _data = [...this.state.buffer, data]
        let { chartDataReference, chartData } = this.state

        if (chartData.length > 0 && data.id !== chartData[chartData.length - 1].commessa) {
            console.log('componentWillReceiveProps data is', data, 'chartData last:', chartData[chartData.length - 1])
            console.log('Resetting chart data due to job switch')
            this.props.network.getJob(data.id)
            return this.setState({
                chartData: [],
                chartDataReference: [],
                buffer: [],
                radialQuantity: [],
                radialScrap: [],
                stopChart: false,
            })
        } else if (chartData.length === 0) {
            this.props.network.getJob(data.id)
        }

        console.log('Data is:', _data)
        _data.sort((a, b) => {
            return a.millis - b.millis
        })

        for (let d of _data) {
            let { timestamp, millis, quantity, scrap, reason, id, sub_id, terminal } = d

            if (terminal !== terminalId) {
                //Not for this terminal's dashboard
                console.log("Skipping data; it's for a different terminal")
                console.log(terminal, terminalId)
                continue
            }
            lastdata = d

            if (chartData.some((x) => x.timestamp === timestamp)) {
                console.log('already in', timestamp)
                // if alreasy has that timestamp
                continue
            }

            let maxQty = Math.max(quantity, scrap)
            if (maxQty > max) max = maxQty

            if (chartDataReference.length !== 0) {
                let last = chartDataReference.splice(-1)[0]
                if (last.id === reason) {
                    last.x2 = millis
                } else {
                    last.x2 = millis
                    chartDataReference.push({ ...last })
                    last.id = reason
                    last.x1 = millis
                    last.x2 = millis
                    last.label = REASON_LABELS[reason]
                    last.fillColor = REASON_COLORS[reason]
                }
                chartDataReference.push(last)
            } else {
                let current = {
                    id: reason,
                    label: REASON_LABELS[reason],
                    x1: millis,
                    x2: millis,
                    y1: 0,
                    fillColor: REASON_COLORS[reason],
                    opacity: '0.5',
                }
                chartDataReference.push(current)
            }
            for (let item of chartDataReference) {
                item.y2 = max + 50
            }
            chartData.push({
                timestamp,
                millis,
                corretti: quantity,
                scarti: scrap,
                commessa: id,
                lavoro: sub_id,
                macchina: terminal,
            })
        }

        if (!lastdata) lastdata = { quantity: 0, scrap: 0 }

        let { quantity, scrap } = lastdata
        let radialQuantity = [
            {
                name: 'Ottimale',
                value: 1000,
                fill: COLORS.qOptimal,
            },
            {
                name: 'Quantità',
                value: quantity,
                fill: COLORS.quantity,
            },
        ]
        let radialScrap = [
            {
                name: 'Massimo',
                value: 500,
                fill: COLORS.sMax,
            },
            {
                name: 'Scarti',
                value: scrap,
                fill: COLORS.scrap,
            },
        ]

        return this.setState({
            chartData: [...chartData],
            chartDataReference: [...chartDataReference],
            buffer: [],
            radialQuantity,
            radialScrap,
            lastdata: lastdata,
        })
    }

    render() {
        let { job, works, lastdata: data, chartData, chartDataReference, radialQuantity, radialScrap } = this.state
        let { terminal: terminalId, machines, workers } = this.props
        let { id, sub_id, terminal, reason, operator, timestamp, work_code } = data

        let worker = null
        let machine = null
        let productQuantity = null

        for (let i in workers) {
            if (workers[i].workerIdentifier === operator) {
                worker = workers[i]
                break
            }
        }

        for (let i in machines) {
            if (machines[i].machineIdentifier === terminal) {
                machine = machines[i]
                break
            }
        }

        if (!work_code) work_code = sub_id

        if (job) {
            console.log('Checking job ', job, 'For work order', work_code, 'for data point', data)
            for (let key in job.workOrders) {
                if (job.workOrders[key].workCode === work_code && job.workOrders[key].productQuantity && job.workOrders[key].productQuantity !== '') {
                    productQuantity = parseInt(job.workOrders[key].productQuantity)
                }
            }
        }

        console.log('Chart DATA is:', chartData)
        return (
            <Segment placeholder style={{ marginTop: 40, justifyContent: 'center' }}>
                <Header icon>
                    VALORI IN TEMPO REALE
                    <Statistic.Value>{moment(timestamp).tz('Europe/Rome').format('LTS') || '-'}</Statistic.Value>
                </Header>
                <View wrap around className="dashboardHeader">
                    <Statistic
                        size="mini"
                        color="black"
                        onClick={() => {
                            this.props.history.push(route_from(app, jobs, `${id}`))
                        }}
                    >
                        <Statistic.Label>Commessa</Statistic.Label>
                        <Statistic.Value>{id || '-'}</Statistic.Value>
                    </Statistic>

                    <Statistic
                        size="mini"
                        color="black"
                        onClick={() => {
                            this.props.history.push(route_from(app, jobs, `${id}`))
                        }}
                    >
                        <Statistic.Label>Ordine di lavoro</Statistic.Label>
                        <Statistic.Value>{sub_id || work_code || '-'}</Statistic.Value>
                    </Statistic>

                    <Statistic size="mini" color="black">
                        <Statistic.Label>Fase</Statistic.Label>
                        <Statistic.Value>{reason >= 0 ? REASON_LABELS[reason] : '-'}</Statistic.Value>
                    </Statistic>

                    <Statistic
                        size="mini"
                        color="black"
                        onClick={() => {
                            this.props.history.push(route_from(app, employees))
                        }}
                    >
                        {/* <Statistic.Value>{operator >= 0 ? operator : '-'}</Statistic.Value> */}
                        <Statistic.Label>Impiegato</Statistic.Label>
                        <Statistic.Value>{operator >= 0 ? (worker ? worker.workerName : operator) : '-'}</Statistic.Value>
                    </Statistic>

                    <Statistic
                        size="mini"
                        color="black"
                        onClick={() => {
                            this.props.history.push(route_from(app, machines))
                        }}
                    >
                        {/* <Statistic.Value>{terminalId}</Statistic.Value> */}
                        <Statistic.Label>Terminale</Statistic.Label>
                        <Statistic.Value>{machine ? machine.machineName : terminalId}</Statistic.Value>
                    </Statistic>
                </View>

                <View around>
                    <View style={{ width: '70%' }} around>
                        <View fullh fullw style={{ marginTop: 30, justifyContent: 'space-around' }}>
                            <Statistic color="blue" size="huge">
                                <Statistic.Value>{productQuantity || '--'}</Statistic.Value>
                                <Statistic.Label>da fare</Statistic.Label>
                            </Statistic>
                            <Statistic color="green" size="huge">
                                <Statistic.Value>{radialQuantity[1] ? radialQuantity[1].value : '--'}</Statistic.Value>
                                <Statistic.Label>corretti</Statistic.Label>
                            </Statistic>
                            <Statistic color="yellow" size="huge">
                                <Statistic.Value>{radialScrap[1] ? radialScrap[1].value : '--'}</Statistic.Value>
                                <Statistic.Label>errati</Statistic.Label>
                            </Statistic>
                        </View>
                    </View>
                </View>

                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                        data={chartData}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        onMouseOver={() => {
                            this.setState({ stopChart: true })
                        }}
                        onMouseLeave={() => {
                            this.setState({ stopChart: false })
                        }}
                    >
                        <defs>
                            <linearGradient id="colorCorretti" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={COLORS.quantity} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={COLORS.quantity} stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="colorScarti" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={COLORS.scrap} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={COLORS.scrap} stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            scale="time"
                            domain={['dataMin', 'dataMax']}
                            type="number"
                            dataKey="millis"
                            tickFormatter={(value) => {
                                return moment.unix(value).format('DD/MM HH:mm:ss')
                            }}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend verticalAlign="top" height={36} />
                        <Tooltip
                            labelFormatter={(value) => {
                                return moment(value).format('HH:mm:ss')
                            }}
                        />

                        {chartDataReference.map((value, index) => {
                            return (
                                <ReferenceArea
                                    key={index}
                                    ifOverflow="extendDomain"
                                    // alwaysShow={true}
                                    label={value.label}
                                    x1={value.x1}
                                    x2={value.x2}
                                    y1={value.y1}
                                    y2={value.y2}
                                    fill={value.fillColor}
                                    fillOpacity={value.opacity}
                                />
                            )
                        })}
                        <Area type="monotone" dataKey="corretti" stroke={COLORS.quantity} fillOpacity={1} fill="url(#colorCorretti)" />
                        <Area type="monotone" dataKey="scarti" stroke={COLORS.scrap} fillOpacity={1} fill="url(#colorScarti)" />
                        {/* <ReferenceLine y={350} label='Ottimale' stroke='red' strokeDasharray='3 3' /> */}
                    </AreaChart>
                </ResponsiveContainer>
                {job && (
                    <View around column>
                        <Job noRequests noActions type="full" job={job} works={works} />
                    </View>
                )}
            </Segment>
        )
    }
}


const mapStateToProps = (state) => {
    let {
        realtime,
        getWorkerList: {
            fetching: fetchWorkers,
            data: { data: workers },
        },
        getMachineList: {
            fetching: fetchMachines,
            data: { data: machines },
        },
        organization,
        login,
        getJob,
    } = state
    let { data } = realtime
    return {
        data,
        fetchWorkers,
        fetchMachines,
        workers,
        machines,
        organization,
        login,
        getJob,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DashboardChart)))
