import { ORGANIZATION_TYPES } from '../actions/organization'
const Reducer = (
    state = {
        data: '',
    },
    action
) => {
    switch (action.type) {
        case ORGANIZATION_TYPES.result: {
            let { result } = action
            return {
                data: result,
            }
        }
        case ORGANIZATION_TYPES.error: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                data: '',
            }
        }
        default:
            return state
    }
}
export default Reducer
