export function getProductList(page, amount, search, isEmployee) {
    let { getProductListRequest, getProductListError, getProductListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'app/product/get_product_list',
        this.createBundle(
            'app/product/get_product_list',
            { page, amount, search },
            this.POST,
            getProductListRequest,
            getProductListResult,
            getProductListError,
            isEmployee
        )
    )
}
