import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Comment, Input, Table } from 'semantic-ui-react'
import { floatTimeToString, formatMoney, timeString } from '../lib/util'
import { Confirmation } from '../components'

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class Document extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, document } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...document,
            documentMinutes: document ? parseFloat(document.documentMinutes) : 0,
        }

        if (onCostChange && document) {
            onCostChange(this.totalCost(), document.code)
        }
    }

    totalCost() {
        let { documentHourlyRate, documentHours, documentMinutes } = this.state
        let total = (documentHours + documentMinutes / 60) * parseFloat(documentHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { document, code, network } = this.props

        if ((!document || !document.code) && code) {
            network.getDocument(code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getDocument, login } = this.props
        let { code, documentHourlyRate } = this.state
        if (documentHourlyRate !== prevState.documentHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), code)
            }
        }

        console.log('Document getDocument fetching', getDocument.fetching)
        if (getDocument && getDocument.fetching !== prevProps.getDocument.fetching && !getDocument.fetching) {
            console.log('Done calling getDocument')
            if (getDocument.status.success) {
                console.log(getDocument.data)
                let document = getDocument.data.data

                this.setState({ ...document })
                console.log('Calling Get document', login, document)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                console.log('calling No document found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { code, documentHourlyRate, documentHours, documentMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={documentHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    documentHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{documentHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(documentHours, documentMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideDocument, hideDocumentHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideDocument && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideDocumentHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideDocument, hideDocumentHours, isBalance } = this.props
        let { code, documentHours, documentMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideDocument && <Table.Cell>{code}</Table.Cell>}
                    {!hideDocumentHours && <Table.Cell>{timeString(documentHours, documentMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, document } = this.props

        network.deleteDocument(document)

        this.setState({ deleting: false })
    }

    render() {
        let { type, document, onClick, selected, noActions } = this.props
        let {
            deleting,
            id,
            fromDeposit,
            toDeposit,
            id_document_reason,
            note,
            date,
            rows,
            relatedDocuments,
            // documentImage,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(document)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        if (document && document.code === 1) {
            return null
        }

        let reasons = {
            1: 'Vendita',
            2: 'Rimborso',
            3: 'Trasferimento',
            4: 'Conferma trasferimento',
            5: 'Presa in carico riparazione',
            6: 'Reso riparazione',
            7: 'Rimozione',
            8: 'Aggiunta',
        }

        let reason = reasons[id_document_reason]

        let realdocnumber = id
        for (let index in relatedDocuments) {
            let parent = relatedDocuments[index]
            realdocnumber = parent.id
            for (let idx = 0; idx < document.rows.length; idx++) {
                let parentItem = parent.rows[idx]
                let currentItem = document.rows[idx]
                let difference = currentItem.qty - parentItem.qty
                currentItem.difference = difference
                //display label NON CONFORME
                if (difference !== 0) {
                    currentItem.conformity = '(NON CONFORME)'
                    currentItem.iscorrect = false
                }
            }
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View noflex fullw column between>
                        <Card className={selected ? 'rowSelected' : ''} style={styles.documentCard} {...extraprops} color={this.state.colors[col]}>
                            <Confirmation
                                message="Vuoi davvero eliminare questo documento?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            {/* <Card.Content style={styles.documentContent}>
								<View fullw row>
									<div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={documentImage}
											ui={false}
											style={styles.documentImage}
										/>
									</div>
									<View fullw column>
										<span style={styles.header}>Documento N.{id}</span>
										{id_deposit_from > 0 && (
											<Card.Description>Dal deposito: {fromDeposit.name}</Card.Description>
										)}
										{id_deposit_to > 0 && (
											<Card.Description>Al deposito: {toDeposit.name}</Card.Description>
										)}
										<Card.Description>{reason}</Card.Description>
										<Card.Description>
											Fa riferimento al documento N.{id_document_related}
										</Card.Description>
										<Card.Description>
											Data: {moment(date).format('DD/MM/YYYY HH:mm:ss')}
										</Card.Description>
										{note && <Card.Description>Note: {note}</Card.Description>}
									</View>
								</View>
							</Card.Content> */}
                            <Card.Content>
                                <View between>
                                    <Card.Header>Documento N.{realdocnumber}</Card.Header>
                                    <Card.Meta>Da {fromDeposit.name}</Card.Meta>
                                    <Card.Meta>a {toDeposit.name}</Card.Meta>
                                </View>
                                <Card.Description>{reason}</Card.Description>
                                <Card.Description style={{ color: 'orange' }}>{note}</Card.Description>
                                <Comment.Group>
                                    {rows.map((value) => (
                                        <Comment>
                                            <Comment.Content>
                                                <Comment.Author>
                                                    {value.description}{' '}
                                                    {value.difference !== undefined && value.difference !== 0
                                                        ? `${value.conformity} Differenza: ${value.difference}`
                                                        : ''}
                                                </Comment.Author>
                                                <Comment.Metadata>
                                                    <div>{value.code}</div>
                                                </Comment.Metadata>
                                                <Comment.Text>
                                                    {formatMoney(value.price)}€ x {value.qty}
                                                </Comment.Text>
                                                <Comment.Text>
                                                    Sconto {value.discount}
                                                    {' Tot. '}
                                                    {formatMoney(value.amount)}€
                                                </Comment.Text>
                                            </Comment.Content>
                                        </Comment>
                                    ))}
                                </Comment.Group>
                                <View between style={{ paddingTop: 8 }}>
                                    <View />
                                    <View>
                                        <Card.Meta>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</Card.Meta>
                                    </View>
                                </View>
                            </Card.Content>
                            {!noActions && (
                                <Card.Content extra>
                                    {/* <Button
										color="blue"
										onClick={() => {
											this.props.history.push(route_from(app, documents, documentEdit, `${id}`))
										}}
									>
										<Icon name="edit" />
										Modifica
									</Button> */}
                                    {/* <Button
										color="red"
										onClick={() => {
											this.openRemoveDialog()
										}}
									>
										<Icon name="delete" />
										Elimina
									</Button> */}
                                </Card.Content>
                            )}
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    documentImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    documentContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    documentCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getDocument } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getDocument, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Document)))
