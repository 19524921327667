export function getDocumentRecap(search, deposit, start, end, articlecode) {
    let { getDocumentRecapRequest, getDocumentRecapError, getDocumentRecapResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'app/document/get_document_list',
        this.createBundle(
            'app/document/get_document_list',
            { search, deposit, start, end, articlecode },
            this.POST,
            getDocumentRecapRequest,
            getDocumentRecapResult,
            getDocumentRecapError
        )
    )
}
