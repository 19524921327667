import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Organization } from '../components'

class SingleOrganization extends Component {
    componentDidUpdate() {}

    componentDidMount() {
        this.props.toolbarResult([])
    }

    render() {
        let { organization } = this.props.match.params
        console.log('Loading organization', organization)
        return (
            <View column fullw fullh>
                <Organization forcedOpen={true} showDetails={true} organizationId={organization} type="full" />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SingleOrganization)))