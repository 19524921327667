import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Loader } from 'semantic-ui-react'
import Job from '../job/Job'
import ReactToPrint from 'react-to-print'

class PdfExport extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.printRef = React.createRef()
    }

    componentDidUpdate() {}

    componentDidMount() {
        const { sub, login, network } = this.props

        if (!sub) {
            this.props.toolbarResult([])
        }

        network.getMappingData()
    }

    renderJob(key, worklist) {
        return <Job noActions showDetails={false} type={'full'} key={key} job={worklist[0]} works={worklist} selected={false} />
    }

    render() {
        let { getMappingData } = this.props

        if (getMappingData.fetching) {
            return <Loader active />
        } else if (getMappingData.data && getMappingData.data.data) {
            const content = []
            content.push(
                ...Object.keys(getMappingData.data.data).map((key) => {
                    let value = getMappingData.data.data[key]
                    return this.renderJob(key, [value])
                })
            )
            return [
                <ReactToPrint
                    ref={(el) => (this.printButton = el)}
                    trigger={() => (
                        <button id="print-button-report">
                            Stampa
                        </button>
                    )}
                    content={() => {
                        //console.log('printing content', this.printRef);
                        return this.printRef.current
                    }}
                />,
                <View column fullw fullh>
                    <div ref={this.printRef}>{content}</div>
                </View>,
            ]
        } else {
            return <Loader active />
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let { login, getMappingData } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getMappingData }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PdfExport)))

const styles = {
    work_container: {
        borderRight: '1px solid grey',
        width: '30%',
        minWidth: '30%',
        maxWidth: '30%',
    },
}
