const path = require('path')

//vfstampi/commesse/d032/consuntivo

export const routes = {
    login: 'login',

    pdfexport: 'pdfexport',
    dataimport: 'dataimport',
    moldmapping: 'moldmapping',
    labeling: 'labeling',

    collections: 'collections',
    families: 'families',
    articles: 'articles',
    variants: 'variants',
    ordering: 'ordering',
    assignvariants: 'assignvariants',
    offices: 'offices',
    duplicates: 'duplicates',

    collectionEdit: 'collectionEdit',
    familyEdit: 'familyEdit',
    articleEdit: 'articleEdit',
    variantEdit: 'variantEdit',
    officeEdit: 'officeEdit',

    app: 'app',
    jobs: 'jobs',
    job: 'job',
    works: 'works',
    employees: 'employees',
    machines: 'machines',
    contracts: 'contracts',
    deposits: 'deposits',
    products: 'products',
    categories: 'categories',
    corps: 'corps',
    warehouse: 'warehouse',
    monitor: 'monitor',
    admin: 'admin',
    jobEdit: 'jobEdit',
    jobMerge: 'jobMerge',
    newEl: 'newEl',
    turnEdit: 'turnEdit',
    userEdit: 'userEdit',
    workerEdit: 'workerEdit',
    machineEdit: 'machineEdit',
    productEdit: 'productEdit',
    categoryEdit: 'categoryEdit',
    depositEdit: 'depositEdit',
    contractEdit: 'contractEdit',
    corpEdit: 'corpEdit',
    costEdit: 'costEdit',
    costUpdate: 'costUpdate',
    finalBalance: 'finalBalance',
    dashboard: 'dashboard',
    roles: 'roles',
    stock: 'stock',
    documents: 'documents',
    documentEdit: 'documentEdit',
    stockEdit: 'stockEdit',
    dataexport: 'dataexport',
    settings: 'settings',
    turns: 'turns',
    help: 'help',
    about: 'about',
    tempJobs: 'tempJobs',
    organizations: 'organizations',
    message: 'message',
    saldatrice: 'saldatrice',
    babypressa: 'babypressa',
    gt2000: 'gt2000',
    saldatriceeditor: 'saldatriceeditor',

    daconsole: 'daconsole',
    pin: 'pin',
    employee: 'employee',
    insertion: 'insertion',
    terminal: 'terminal',
    calendarView: 'calendarView',
    profile: 'profile',

    workerPanel: 'workerPanel',
}

export const routeNames = {
    dataimport: 'Importazione dati',
    pdfexport: 'Esportazione PDF',
    moldmapping: 'Mappatura stampi',
    labeling: 'Stampa etichette',

    collections: 'Collezioni',
    families: 'Famiglie',
    articles: 'Articoli',
    variants: 'Varianti',
    ordering: 'Ordinamento',
    assignvariants: 'Assegna varianti',
    offices: 'Punti vendita',
    duplicates: 'Assegna Duplicati',

    collectionEdit: 'Modifica collezione',
    familyEdit: 'Modifica famiglia',
    articleEdit: 'Modifica articolo',
    variantEdit: 'Modifica variante',
    officeEdit: 'Modifica sede',

    login: '/',
    app: '/App',
    daconsole: '/Console',
    employee: '/Impiegato',
    insertion: '/Inserimento',
    terminal: 'Dati produzione',
    finalBalance: '/Consuntivo',
    dashboard: 'Dashboard',
    roles: 'Ruoli e permessi',
    products: 'Prodotti',
    documents: 'Documenti',
    productEdit: 'Modifica Prodotto',
    documentEdit: 'Modifica Documento',
    categoryEdit: 'Modifica categoria',
    depositEdit: 'Modifica deposito',
    corpEdit: 'Modifica corpo',
    contractEdit: 'Modifica appalto',
    stock: 'Magazzino',
    stockEdit: 'Modifica giacenza nel magazzino',
    dataexport: 'Esportazione dati',
    settings: 'Impostazioni',
    turns: 'Turni di lavoro',
    help: 'Aiuto',
    about: 'Informazioni',
    tempJobs: 'Commesse da ricontrollare',
    pin: 'Pin',
    jobs: 'Commesse',
    job: 'Commessa',
    works: 'Lavori',
    employees: 'Impiegati',
    machines: 'Macchine',
    contracts: 'Appalti',
    corps: 'Tipologie',
    categories: 'Categorie',
    deposits: 'Depositi',
    warehouse: 'Magazzino',
    admin: 'Amministratori',
    jobEdit: 'Editor commessa',
    jobMerge: 'Unione commesse',
    saldatriceeditor: 'Invio dati a saldatrice',
    newEl: 'Nuovo',
    turnEdit: 'Editor turno',
    userEdit: 'Editor utente',
    workerEdit: 'Editor impiegato',
    machineEdit: 'Editor macchina',
    costEdit: 'Editor costi',
    costUpdate: 'Modifica costo',
    calendarView: '/Calendario',
    producers: 'Produttori',
    requests: 'Richieste',
    organizations: 'Rubrica',
    message: 'Messaggi',
    profile: 'Profilo',
    gt2000: 'C.B. Ferrari GT2000',
    monitor: 'C.B. Ferrari',
    saldatrice: 'Saldatrice SWA300',
    babypressa: 'Baby Pressa',

    workerPanel: 'Pannello Impiegato',
}

export const route_from = (...args) => {
    let toJoin = args.map((value) => {
        let name = routeNames[value]
        if (!name) {
            name = value
        }
        return name
    })
    return path.join(...toJoin)
}

export const is_in = (path, route) => {
    return path.includes(routeNames[route])
}

export const get_section = (path) => {
    for (let [key, value] of Object.entries(routeNames)) {
        if (path.includes(value)) return key
    }
    return Object.keys(routes)[0]
}
