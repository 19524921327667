import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class UserEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { user } = this.props.match.params

        if (!id) {
            id = user
        }

        this.state = {
            userId: id,
            email: '',
            fullname: '',
            password: '',
            rcode: '',
        }
    }

    componentDidMount() {
        let { userId } = this.state

        if (userId) {
            console.log('Getting user ', userId)
            this.state.network.getUser(userId)
        }
        this.state.network.getRoleList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getUser } = this.props
        if (getUser && getUser.fetching !== prevProps.getUser.fetching && !getUser.fetching) {
            if (getUser.status.success) {
                let user = getUser.data.data
                console.log('User editor setting state with', user)
                this.setState(user)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    save() {
        // TODO value checking (IMPORTANT ON THE EMAIL HERE)
        let { fullname, email, userId, password, rcode } = this.state

        if (email === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createUser(
            {
                fullname,
                email,
                password,
                rcode,
            },
            userId
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, fullname, email, password, rcode } = this.state

        let { roleOptions, fetchingRoles } = this.props

        console.log('UserEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || fetchingRoles}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-user-email"
                            control={Input}
                            label="E-mail"
                            name="email"
                            value={email}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="admin@artisan.it"
                        />
                        <Form.Field
                            id="form-input-control-user-name"
                            control={Input}
                            label="Nome e cognome"
                            placeholder="Marco Rossi"
                            name="fullname"
                            value={fullname}
                            onChange={(e) => this.handleInput(e)}
                        />
                        {!this.state.userId && (
                            <Form.Field
                                id="form-input-control-user-password"
                                control={Input}
                                label="Password"
                                placeholder=""
                                name="password"
                                value={password}
                                type="password"
                                onChange={(e) => this.handleInput(e)}
                            />
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id="form-input-control-job-type"
                            control={Select}
                            options={roleOptions}
                            label="Ruolo"
                            placeholder="Ruolo"
                            name="rcode"
                            value={rcode}
                            onChange={(e, data) => {
                                let id = data.value
                                this.setState({ rcode: id })
                            }}
                        />
                    </Form.Group>
                    <View>
                        <Form.Field
                            id="form-button-control-cancel"
                            control={Button}
                            content="Annulla"
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        />
                        <Form.Field
                            id="form-button-control-save"
                            control={Button}
                            content="Salva"
                            onClick={() => {
                                this.save()
                            }}
                        />
                    </View>
                    <Message success header="Form Completed" content="Lavoratore inserito con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu&#39; tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getRoleList: {
            data: { data: roles },
            fetching: fetchingRoles,
        },
        createUser: { data: createResult, fetching: creating, status: createStatus },
        getUser,
    } = state

    let options = []
    for (let i in roles) {
        let role = roles[i]
        options.push({
            key: role.rcode,
            text: role.name,
            value: role.rcode,
        })
    }

    return {
        fetchingRoles,
        createResult,
        creating,
        createStatus,
        getUser,
        roleOptions: options,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(UserEditor)))
