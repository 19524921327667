import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card } from 'semantic-ui-react'
import { Confirmation } from '../components'
import BlackboxService from './BlackboxService'

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class Blackbox extends Component {
    constructor(props) {
        super(props)

        let { blackbox } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...blackbox,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, blackbox } = this.props

        network.deleteBlackbox(blackbox)

        this.setState({ deleting: false })
    }

    render() {
        let { type, blackbox, onClick, selected } = this.props
        let { deleting, id_blackbox, id_machine, valid, services } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(blackbox)
            }
        }

        console.log('Blackbox state is:', blackbox)

        let col = parseInt(Math.random() * this.state.colors.length)

        switch (type) {
            default:
                return (
                    <View fullw column between>
                        <Card className={selected ? 'rowSelected' : ''} style={styles.blackboxCard} {...extraprops} color={this.state.colors[col]}>
                            <Confirmation
                                message="Vuoi davvero eliminare questa blackbox?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Card.Content style={styles.blackboxContent}>
                                <View fullw column>
                                    <View fullw column>
                                        <span style={styles.header}>{id_blackbox}</span>
                                        <Card.Description>Machine: {id_machine}</Card.Description>
                                        <Card.Description>Valid: {valid}</Card.Description>
                                    </View>
                                    <View fullw column>
                                        {services.map((value, index) => (
                                            <BlackboxService key={`service_${index}`} blackbox={blackbox} service={value} />
                                        ))}
                                    </View>
                                </View>
                            </Card.Content>
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    blackboxImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    blackboxContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    blackboxCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Blackbox)))
