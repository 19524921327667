export function getFamilyList(data) {
	let { getFamilyListRequest, getFamilyListError, getFamilyListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_family_list',
		this.createBundle(
			'get_family_list',
			{ search: data || '' },
			this.GET,
			getFamilyListRequest,
			getFamilyListResult,
			getFamilyListError
		)
	);
}
