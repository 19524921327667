export function changePassword(email, oldPassword, newPassword) {
    let { changePasswordRequest, changePasswordError, changePasswordResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'change_password',
        this.createBundle(
            'change_password',
            { id: email, oldPassword, newPassword },
            this.POST,
            changePasswordRequest,
            changePasswordResult,
            changePasswordError
        )
    )
}
