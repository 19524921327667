export function getStdArticle(data) {
	let { getStdArticleRequest, getStdArticleError, getStdArticleResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_article',
		this.createBundle(
			'get_article',
			{ stdarticle: data },
			this.GET,
			getStdArticleRequest,
			getStdArticleResult,
			getStdArticleError
		)
	);
}
