export function deleteLabel(data) {
	let { deleteLabelRequest, deleteLabelError, deleteLabelResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_label',
		this.createBundle(
			'delete_label',
			{ label: data },
			this.GET,
			deleteLabelRequest,
			deleteLabelResult,
			deleteLabelError
		)
	);
}
