export function getWorkListSpan(start, end, worker, isEmployee) {
    let { getWorkListSpanRequest, getWorkListSpanError, getWorkListSpanResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_work_list_span',
        this.createBundle(
            'get_work_list_span',
            { start, end, worker },
            this.GET,
            getWorkListSpanRequest,
            getWorkListSpanResult,
            getWorkListSpanError,
            isEmployee
        )
    )
}
