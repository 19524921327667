export function getDataImport(data) {
	let { getDataImportRequest, getDataImportError, getDataImportResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_dataimport',
		this.createBundle(
			'get_dataimport',
			{ dataimport: data },
			this.GET,
			getDataImportRequest,
			getDataImportResult,
			getDataImportError
		)
	);
}
