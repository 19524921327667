export function uploadImage(file) {
    let { uploadImageRequest, uploadImageError, uploadImageResult } = this.props

    console.log('Uploading file:', file)
    const formData = new FormData()
    formData.append('file', file)

    for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1])
    }

    let bundle = this.createBundle('upload', {}, this.POST, uploadImageRequest, uploadImageResult, uploadImageError)
    bundle.data = formData
    bundle.data.ACTION = 'upload'
    bundle.noStringify = true
    //bundle.noAuth = true;
    bundle.contenttype = 'multipart/form-data'

    this.doAction(this.WEB_REQUEST_URL + 'upload', bundle)
}
