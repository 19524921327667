import { combineReducers } from 'redux'
import { LOGIN_TYPES } from '../actions/login'

/* @reducers */
import getLabelList from './getLabelList'
import getLabel from './getLabel'
import createLabel from './createLabel'
import deleteLabel from './deleteLabel'
import filters from './filters'
import runReverseGeocoding from './runReverseGeocoding'
import clearParent from './clearParent'
import deleteArticleVariant from './deleteArticleVariant'
import mergeJob from './mergeJob'
import deleteMapping from './deleteMapping'
import createMapping from './createMapping'
import getDataImportList from './getDataImportList'
import getItemList from './getItemList'
import getMappingData from './getMappingData'
import getUrgentJobList from './getUrgentJobList'
import deleteOrganization from './deleteOrganization'
import createOrganization from './createOrganization'
import getOrganizationList from './getOrganizationList'
import getOrganization from './getOrganization'
import deleteContract from './deleteContract'
import createContract from './createContract'
import getContractList from './getContractList'
import getContract from './getContract'
import sendNotification from './sendNotification'
import doAssignDuplicates from './doAssignDuplicates'
import setArticleVariantOrdering from './setArticleVariantOrdering'
import doAssignVariants from './doAssignVariants'
import deleteOffice from './deleteOffice'
import createOffice from './createOffice'
import getOfficeList from './getOfficeList'
import getOffice from './getOffice'
import getArticleVariantImageList from './getArticleVariantImageList'
import getCollectionsFull from './getCollectionsFull'
import uploadMultipleFiles from './uploadMultipleFiles'
import deletePicture from './deletePicture'
import createPicture from './createPicture'
import getPictureList from './getPictureList'
import getPicture from './getPicture'
import deleteStdArticle from './deleteStdArticle'
import createStdArticle from './createStdArticle'
import getStdArticleList from './getStdArticleList'
import getStdArticle from './getStdArticle'
import deleteVariant from './deleteVariant'
import createVariant from './createVariant'
import getVariantList from './getVariantList'
import getVariant from './getVariant'
import deleteFamily from './deleteFamily'
import createFamily from './createFamily'
import getFamilyList from './getFamilyList'
import getFamily from './getFamily'
import deleteCollection from './deleteCollection'
import createCollection from './createCollection'
import getCollection from './getCollection'
import getCollectionList from './getCollectionList'
import getArticleRecap from './getArticleRecap'
import getProductRecap from './getProductRecap'
import getDocumentRecap from './getDocumentRecap'
import deleteDeposit from './deleteDeposit'
import createDeposit from './createDeposit'
import getDeposit from './getDeposit'
import getCategory from './getCategory'
import getCorp from './getCorp'
import deleteCorp from './deleteCorp'
import createCorp from './createCorp'
import deleteCategory from './deleteCategory'
import createCategory from './createCategory'
import deleteUser from './deleteUser'
import createProduct from './createProduct'
import getCorpList from './getCorpList'
import getCategoryList from './getCategoryList'
import getProduct from './getProduct'
import deleteProduct from './deleteProduct'
import getProductList from './getProductList'
import getDepositList from './getDepositList'
import getDocument from './getDocument'
import deleteDocument from './deleteDocument'
import createDocument from './createDocument'
import getDocumentList from './getDocumentList'
import getArticleList from './getArticleList'
import getArticle from './getArticle'
import deleteArticle from './deleteArticle'
import createArticle from './createArticle'
import getTurn from './getTurn'
import deleteTurn from './deleteTurn'
import createTurn from './createTurn'
import getTurnList from './getTurnList'
import getRoleList from './getRoleList'
import getUser from './getUser'
import changePassword from './changePassword'
import createUser from './createUser'
import getUserList from './getUserList'
import organization from './organization'
import hades from './hades'
import recap from './recap'
import getRecentJobList from './getRecentJobList'
import getRecentMachineList from './getRecentMachineList'
import saveService from './saveService'
import deleteBlackbox from './deleteBlackbox'
import getBlackboxList from './getBlackboxList'
import realtime from './realtime'
import messages from './messages'
import jobListState from './jobListState'
import deleteWorkList from './deleteWorkList'
import getCost from './getCost'
import getWorker from './getWorker'
import getMachine from './getMachine'
import getJob from './getJob'
import getWorkListForDate from './getWorkListForDate'
import workday from './workday'
import assignWork from './assignWork'
import uploadFile from './uploadFile'
import uploadImage from './uploadImage'
import getImageList from './getImageList'
import getJobTypeList from './getJobTypeList'
import search from './search'
import toolbar from './toolbar'
import getDetailedJobList from './getDetailedJobList'
import getPagedJobList from './getPagedJobList'
import getJobList from './getJobList'
import getWorkerList from './getWorkerList'
import getItemCosts from './getItemCosts'
import deleteWorker from './deleteWorker'
import updateWorker from './updateWorker'
import createWorker from './createWorker'
import getPagedCostList from './getPagedCostList'
import getCostList from './getCostList'
import deleteCost from './deleteCost'
import updateCost from './updateCost'
import createCost from './createCost'
import getMachineTypeList from './getMachineTypeList'
import deleteMachineType from './deleteMachineType'
import updateMachineType from './updateMachineType'
import createMachineType from './createMachineType'
import getMachineList from './getMachineList'
import deleteMachine from './deleteMachine'
import updateMachine from './updateMachine'
import createMachine from './createMachine'
import getWorkListFilter from './getWorkListFilter'
import getWorkListSpan from './getWorkListSpan'
import getWorkList from './getWorkList'
import getWork from './getWork'
import deleteWork from './deleteWork'
import updateWork from './updateWork'
import createWork from './createWork'
import deleteJob from './deleteJob'
import switchJob from './switchJob'
import updateJob from './updateJob'
import createJob from './createJob'
import loginEmployee from './loginEmployee'
import login from './login'

import { reducer as notifications } from 'react-notification-system-redux'

const AppReducer = combineReducers({
    /* @combine */
    getLabelList,
    getLabel,
    createLabel,
    deleteLabel,
    filters,
    runReverseGeocoding,
    clearParent,
    deleteArticleVariant,
    mergeJob,
    deleteMapping,
    createMapping,
    getDataImportList,
    getItemList,
    doAssignDuplicates,
    setArticleVariantOrdering,
    doAssignVariants,
    deleteOffice,
    createOffice,
    getOfficeList,
    getOffice,
    getArticleVariantImageList,
    getCollectionsFull,
    uploadMultipleFiles,
    deletePicture,
    createPicture,
    getPictureList,
    getPicture,
    deleteStdArticle,
    createStdArticle,
    getStdArticleList,
    getStdArticle,
    deleteVariant,
    createVariant,
    getVariantList,
    getVariant,
    deleteFamily,
    createFamily,
    getFamilyList,
    getFamily,
    deleteCollection,
    createCollection,
    getCollection,
    getCollectionList,
    messages,
    getMappingData,
    getUrgentJobList,
    deleteOrganization,
    createOrganization,
    getOrganizationList,
    getOrganization,
    deleteContract,
    createContract,
    getContractList,
    getContract,
    sendNotification,
    getArticleRecap,
    getProductRecap,
    getDocumentRecap,
    deleteDeposit,
    createDeposit,
    getDeposit,
    getCategory,
    getCorp,
    deleteCorp,
    createCorp,
    deleteCategory,
    createCategory,
    deleteUser,
    createProduct,
    getCorpList,
    getCategoryList,
    getProduct,
    deleteProduct,
    getProductList,
    getDepositList,
    getDocument,
    deleteDocument,
    createDocument,
    getDocumentList,
    getArticleList,
    getArticle,
    deleteArticle,
    createArticle,
    getTurn,
    deleteTurn,
    createTurn,
    getTurnList,
    getRoleList,
    getUser,
    changePassword,
    createUser,
    getUserList,
    organization,
    hades,
    recap,
    getRecentJobList,
    getRecentMachineList,
    saveService,
    deleteBlackbox,
    getBlackboxList,
    realtime,
    jobListState,
    deleteWorkList,
    getCost,
    getWorker,
    getMachine,
    getJob,
    getWorkListForDate,
    workday,
    assignWork,
    uploadFile,
    uploadImage,
    getImageList,
    getJobTypeList,
    search,
    toolbar,
    getJobList,
    getItemCosts,
    getWorkerList,
    deleteWorker,
    updateWorker,
    createWorker,
    getPagedCostList,
    getCostList,
    deleteCost,
    updateCost,
    createCost,
    getMachineTypeList,
    deleteMachineType,
    updateMachineType,
    createMachineType,
    getMachineList,
    deleteMachine,
    updateMachine,
    createMachine,
    getWorkListFilter,
    getWorkListSpan,
    getWorkList,
    getWork,
    deleteWork,
    updateWork,
    createWork,
    deleteJob,
    switchJob,
    updateJob,
    createJob,
    getDetailedJobList,
    getPagedJobList,
    loginEmployee,
    login,
    notifications,
})

console.log = () => null
const Reducer = (state, action) => {
    if (action.type === LOGIN_TYPES.logout || action.type === LOGIN_TYPES.request) state = undefined

    let newState = AppReducer(state, action)
    console.info(action)
    console.info(newState)
    return newState
}

export default Reducer
