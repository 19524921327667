import { SOCKET_HERCULES_TYPES } from '../actions/socketHercules'

const defaultState = {
    connected: false,
    data: {},
}
const Reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SOCKET_HERCULES_TYPES.newdata: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                data: result,
            }
        }
        case SOCKET_HERCULES_TYPES.connect: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                connected: true,
                data: result,
            }
        }
        case SOCKET_HERCULES_TYPES.disconnect: {
            let { result } = action
            if (result === undefined) result = {}

            return defaultState
        }
        default:
            return state
    }
}

export default Reducer
