import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Form, Placeholder, Select, Input } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { FILTERS_ACTIONS } from '../actions/filters'
import { Empty, Job } from '../components'
import ReactDatePicker from 'react-datepicker'
import { initializePage, baseViewDispatch, jobOverview } from '../lib/util'

const { app, jobs, jobEdit, newEl } = routes

class Jobs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            items: 10,
        }
    }

    componentDidMount() {
        let { page: currentPage, items } = this.state
        let { isEmployee, workerId, login, search, network, createdExternal } = this.props

        this.setToolbar()

        console.log('Updating with page', currentPage)
        if (isEmployee) {
            network.getRecentJobList(currentPage, items, search.data, workerId, !!isEmployee, createdExternal)
        } else {
            network.getDetailedJobList(currentPage, items, search.data, !!isEmployee, createdExternal)
        }
    }
    setToolbar() {
        let { page: currentPage, items } = this.state
        let { isEmployee, workerId, login, search, network, createdExternal } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let pageItems = [
            {
                name: `${parseInt(currentPage) + 1}`,
                position: 'none',
                type: 'text',
            },
        ]
        let extraTools = []
        if (user && !!user.manage_job) {
            extraTools.push({
                name: 'Crea nuova commessa',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, jobs, newEl, jobEdit))
                },
            })
        }
        const toolbar = [
            ...extraTools,
            {
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    this.prev()
                },
            },
            ...pageItems,
            {
                name: '',
                icon: 'arrow right',
                position: 'none',
                action: () => {
                    this.next()
                },
            },
        ]
        const filters = {}
        initializePage(this, toolbar, filters)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items } = this.state
        let {
            jobs,
            fetching,
            getFromDerived,
            hadesTokenOk,
            isEmployee,
            workerId,
            createdExternal,
            search,
            select,
            network,
            deleteJob,
            organization,
        } = this.props
        if (page !== prevState.page) {
            this.setToolbar()
        }
        if (
            !select &&
            (page !== prevState.page ||
                items !== prevState.items ||
                (deleteJob.fetching !== prevProps.deleteJob.fetching && !deleteJob.fetching) ||
                organization.data !== prevProps.organization.data)
        ) {
            console.log('Updating with page', page)
            if (isEmployee) {
                network.getRecentJobList(page, items, search.data, workerId, isEmployee, createdExternal)
            } else {
                network.getDetailedJobList(page, items, search.data, isEmployee, createdExternal)
            }
        } else if (!select && search !== prevProps.search) {
            this.setState({
                page: 0,
            })

            if (isEmployee) {
                network.getRecentJobList(0, 10, search.data, workerId, isEmployee, !!createdExternal)
            } else {
                network.getDetailedJobList(0, 10, search.data, isEmployee, createdExternal)
            }
        }

        // Job hades Data
        if (
            hadesTokenOk &&
            jobs.data &&
            Object.keys(jobs.data).length > 0 &&
            (prevProps.hadesTokenOk != hadesTokenOk || prevProps.fetching != fetching)
        ) {
            let reqs = []
            for (const [key, value] of Object.entries(jobs.data)) {
                let job = value[0]
                let { workOrders = [] } = job
                let id = job.jobCode
                // console.info('iterating', job)

                for (let order of workOrders) {
                    let sub_id = order.workCode
                    reqs.push({
                        destination: 'job',
                        id: `${id}`,
                        sub_id: `${sub_id}`,
                        limit: 1,
                    })
                }
            }
            network.getFromDerived(reqs)
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props
                // console.info('hades data', hadesData)
                let allHadesData = {}
                for (let hdata of hadesData) {
                    let { data = [] } = hdata || {}
                    let firstRow = data[0] || {}
                    let { id, sub_id } = firstRow
                    if (!allHadesData[`${id}`]) allHadesData[`${id}`] = []
                    allHadesData[`${id}`].push(firstRow)
                }

                this.setState({
                    allHadesData,
                })
            }
        }
    }

    next() {
        this.setState((prevState) => ({
            page: prevState.page + 1,
        }))
    }

    prev() {
        if (this.state.page === 0) return
        this.setState((prevState) => ({
            page: prevState.page - 1,
        }))
    }

    renderJob(job) {
        let { selected, opened, allHadesData = {} } = this.state
        let { noActions, selectable, onChange } = this.props
        let extraProps = {
            noActions: noActions,
        }
        if (selectable) {
            extraProps.onClick = (job) => {
                if (onChange) {
                    onChange(job)
                    this.setState({
                        selected: job,
                    })
                }
            }
        }

        if (jobs.length === 0) return null

        let { jobCode, workOrders } = job
        let overview = jobOverview(allHadesData[`${jobCode}`])
        let productQuantity = 0
        for (let w of workOrders) {
            productQuantity += parseInt(w.productQuantity) || 0
        }
        overview.productQuantity = productQuantity

        return (
            <Job
                {...extraProps}
                selected={onChange && selected && selected.jobCode === job.jobCode}
                type="full"
                key={jobCode}
                job={job}
                overview={overview}
            />
        )
    }

    // renderSelect() {
    //     let { jobOptions, selectedJob, selectOpen, selectSearch } = this.state
    //     let { onChange } = this.props

    //     return (
    //         <Form>
    //             <Form.Field
    //                 control={Select}
    //                 options={jobOptions}
    //                 label="Commessa"
    //                 placeholder="Commessa"
    //                 value={selectedJob}
    //                 search
    //                 searchQuery={selectSearch}
    //                 open={selectOpen}
    //                 onSearchChange={(e, data) => {
    //                     this.setState({
    //                         selectSearch: data.searchQuery,
    //                     })
    //                 }}
    //                 clearable
    //                 onClick={() => {
    //                     this.setState({ selectOpen: true })
    //                 }}
    //                 onFocus={() => {
    //                     this.setState({ selectOpen: true })
    //                 }}
    //                 onChange={(e, data) => {
    //                     if (onChange) {
    //                         onChange(data.value)
    //                     }
    //                     this.setState({
    //                         selectSearch: data.value.jobCode,
    //                         selectedJob: data.value,
    //                         selectOpen: false,
    //                         firstTime: true,
    //                     })
    //                 }}
    //                 onBlur={() => {
    //                     this.setState({ selectOpen: false })
    //                 }}
    //                 searchInput={{ id: 'form-select-control-job' }}
    //             />
    //         </Form>
    //     )
    // }

    render() {
        let { jobs, fetching, organization, direction, select, ...rest } = this.props
        let content = <div />
        // let dirobj = {
        //     [direction === 'row' ? 'overflowX' : 'overflowY']: 'scroll',
        //     [direction === 'row' ? 'overflowY' : 'overflowX']: 'hidden',
        // }

        // if (select) {
        //     content = this.renderSelect()
        // } else

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (!select && !!jobs.data && Object.keys(jobs.data).length > 0) {
            content = []
            for (const [key, value] of Object.entries(jobs.data)) {
                let job = value[0]
                let { urgencyLevel } = job
                if (urgencyLevel == 1) content.unshift(this.renderJob(job))
                else content.push(this.renderJob(job))
            }
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getRecentJobList,
        getDetailedJobList,
        deleteJob,
        search,
        login,
        organization,
        hades: {
            getFromDerived,
            hadesToken: {
                status: { success: hadesTokenOk },
            },
        },
    } = state
    const { isEmployee } = ownProps

    let jobList = getDetailedJobList
    if (isEmployee) {
        jobList = getRecentJobList
    }

    return {
        jobs: jobList.data,
        fetching: jobList.fetching,
        status: jobList.status,
        deleteJob,
        search,
        login,
        organization,
        getFromDerived,
        hadesTokenOk,
        pageCount: jobList.data.paging ? jobList.data.paging.pagecount : 0,
        currentPage: jobList.data.paging ? jobList.data.paging.page : 0,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Jobs)))
