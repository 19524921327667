export function deleteMachineType(data) {
    let { deleteMachineTypeRequest, deleteMachineTypeError, deleteMachineTypeResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'delete_machine_type',
        this.createBundle(
            'delete_machine_type',
            { machinetype: data },
            this.POST,
            deleteMachineTypeRequest,
            deleteMachineTypeResult,
            deleteMachineTypeError
        )
    )
}
