import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'

import { Card, Placeholder } from 'semantic-ui-react'
import { Empty, Machine } from '../components'
import { baseViewDispatch, overviewData } from '../lib/util'

import moment from 'moment-timezone'
const { app, machines, newEl, machineEdit } = routes

class Machines extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
        }
    }

    componentDidMount() {
        let { isEmployee, sub, workerId, login, search, network } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_machine) {
            extraTools.push({
                name: 'Crea nuova macchina',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, machines, newEl, machineEdit))
                },
            })
        }
        if (!sub) {
            this.props.toolbarResult([...extraTools])
        }

        if (isEmployee) {
            network.getRecentMachineList(search.data, workerId, isEmployee)
        } else {
            network.getMachineList(search.data, isEmployee)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items, derivedRequest } = this.state
        let { search, deleteMachine, getFromDerived, hadesTokenOk } = this.props
        let { isEmployee, workerId, organization, machines, fetching, network } = this.props
        if (
            page !== prevState.page ||
            items !== prevState.items ||
            (deleteMachine.fetching !== prevProps.deleteMachine.fetching && !deleteMachine.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            if (isEmployee) {
                network.getRecentMachineList(search.data, workerId, isEmployee)
            } else {
                network.getMachineList(search.data, isEmployee)
            }
        } else if (search !== prevProps.search) {
            if (isEmployee) {
                network.getRecentMachineList(search.data, workerId, isEmployee)
            } else {
                network.getMachineList(search.data, isEmployee)
            }
        }

        // Get hades data for machine list
        if (machines.data && machines.data.length > 0 && machines.data != prevProps.machines.data) {
            this.setState(
                (pstate) => ({
                    derivedRequest: 'machine_job',
                }),
                () => {
                    let reqs = []
                    for (let machine of machines.data) {
                        let id = machine.machineIdentifier.toString()
                        if (this.state[`${id}-overview`]) return
                        reqs.push({
                            destination: 'machine_job',
                            id,
                            sub_id: 'default',
                            limit: 1,
                        })
                    }
                    network.getFromDerived(reqs)
                }
            )
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props
                switch (derivedRequest) {
                    case 'machine_job':
                        let jobsWorks = []
                        for (let hdata of hadesData) {
                            let { data = [] } = hdata || {}
                            for (let item of data) {
                                let { job, work } = item
                                if (!jobsWorks.some((el) => el.job === job && el.work == work)) {
                                    jobsWorks.push({ job, work })
                                }
                            }
                        }
                        let reqs = []
                        for (let jw of jobsWorks) {
                            let { job, work } = jw

                            reqs.push({
                                destination: 'job',
                                id: `${job}`,
                                sub_id: `${work}`,
                                filters: [
                                    {
                                        key: 'timestamp',
                                        symbol: '>=',
                                        value: moment().subtract(7, 'days'),
                                    },
                                ],
                            })
                        }
                        this.setState({ derivedRequest: 'job' }, () => network.getFromDerived(reqs))
                        break
                    case 'job':
                        for (let hdata of hadesData) {
                            let { data = [] } = hdata || {}
                            let firstRow = data[0] || {}
                            let { id } = firstRow
                            this.setState({ [`${id}-overview`]: [...data] })
                        }
                        break
                }
            }
        }
    }

    next() {
        this.setState((prevState) => ({
            page: prevState.page + 1,
        }))
    }

    prev() {
        if (this.state.page === 0) return
        this.setState((prevState) => ({
            page: prevState.page - 1,
        }))
    }

    renderMachine(machine) {
        let { selected = {} } = this.state
        let { onChange, selectable, noActions, showNoMachine } = this.props
        let id = machine.machineIdentifier.toString()
        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (machine) => {
                if (onChange) {
                    onChange(machine)
                }
                this.setState({ selected: machine })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoMachine) {
            extraProps.showNoMachine = true
        }

        return (
            <Machine
                {...extraProps}
                selected={machine.machineId === selected.machineId}
                type="full"
                key={`machine_${machine.machineId}`}
                machine={machine}
                overview={overviewData(`${machine.machineIdentifier}`, 'terminal', this.state[`${id}-overview`])}
            />
        )
    }

    render() {
        let { machines, fetching } = this.props
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (machines.data && machines.data.length > 0) {
            content = machines.data.map((value) => {
                return this.renderMachine(value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        getRecentMachineList,
        deleteMachine,
        search,
        organization,
        login,
        hades: {
            hadesToken: {
                status: { success: hadesTokenOk },
            },
            getFromDerived,
        },
    } = state
    let { isEmployee } = ownProps

    let machineList = getMachineList
    if (isEmployee) {
        machineList = getRecentMachineList
    }
    return {
        machines: machineList.data,
        fetching: machineList.fetching,
        status: machineList.status,
        deleteMachine,
        search,
        login,
        organization,
        getFromDerived,
        hadesTokenOk,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Machines)))
