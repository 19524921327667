export function deleteFamily(data) {
	let { deleteFamilyRequest, deleteFamilyError, deleteFamilyResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_family',
		this.createBundle(
			'delete_family',
			{ family: data },
			this.POST,
			deleteFamilyRequest,
			deleteFamilyResult,
			deleteFamilyError
		)
	);
}
