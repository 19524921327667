import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'

import {} from 'semantic-ui-react'

import View from 'lib/View'
import { Deposit, Empty } from '../components'

import { Placeholder, Card } from 'semantic-ui-react'

import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SEARCH_ACTIONS } from '../actions/search'

import { route_from, routes } from 'lib/routes'
const { app, deposits, newEl, depositEdit } = routes

class Deposits extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteDeposit } = this.state
        let { isEmployee, workerId, organization } = this.props
        if (
            this.state.page != prevState.page ||
            this.state.items != this.state.items ||
            (deleteDeposit.fetching !== prevState.deleteDeposit.fetching && !deleteDeposit.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            if (isEmployee) {
                this.state.network.getRecentDepositList(this.state.search.data, workerId, isEmployee)
            } else {
                this.state.network.getDepositList(this.state.search.data, isEmployee)
            }
        } else if (this.state.search != prevState.search) {
            if (isEmployee) {
                this.state.network.getRecentDepositList(this.state.search.data, workerId, isEmployee)
            } else {
                this.state.network.getDepositList(this.state.search.data, isEmployee)
            }
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { isEmployee, sub, workerId } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_deposit) {
            extraTools.push({
                name: 'Crea nuovo deposito',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, deposits, newEl, depositEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                // Not paginated yet
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: 'none',
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }

        if (isEmployee) {
            this.state.network.getRecentDepositList(this.state.search.data, workerId, isEmployee)
        } else {
            this.state.network.getDepositList(this.state.search.data, isEmployee)
        }
    }

    renderDeposit(key, deposit) {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoDeposit } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (deposit) => {
                if (onChange) {
                    onChange(deposit)
                }
                this.setState({ selected: deposit })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoDeposit) {
            extraProps.showNoDeposit = true
        }

        return <Deposit {...extraProps} selected={deposit.id === selected.id} type="full" key={`deposit_${key}`} deposit={deposit} />
    }

    render() {
        let { deposits, fetching } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        console.log('Deposits:', deposits)

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 10; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (deposits.deposit && deposits.deposit.length > 0) {
            content = Object.keys(deposits.deposit).map((key) => {
                let value = deposits.deposit[key]
                return this.renderDeposit(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getDepositList, getRecentDepositList, deleteDeposit, search, organization, login } = state
    let { isEmployee } = ownProps

    let depositList = getDepositList
    if (isEmployee) {
        depositList = getRecentDepositList
    }
    return {
        deposits: depositList.data,
        fetching: depositList.fetching,
        deleteDeposit,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Deposits)))
