export function getPictureList(data) {
	let { getPictureListRequest, getPictureListError, getPictureListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_picture_list',
		this.createBundle(
			'get_picture_list',
			{ picture: data },
			this.GET,
			getPictureListRequest,
			getPictureListResult,
			getPictureListError
		)
	);
}
