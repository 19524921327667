import { Jobs, Machines } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Checkbox, Icon, Input, Step } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { SEARCH_ACTIONS } from '../actions/search'
import { Numpad } from '../components'

moment.locale('it')

class Steps extends Component {
    constructor(props) {
        super(props)

        let updateSearch = debounce(300, false, (search) => {
            this.props.searchResult(search)
        })

        this.state = {
            ...this.defaultState(),
            workDate: moment(),
            updateSearch,
        }
    }

    componentDidMount() {
        //this.state.network.getDetailedJobList(this.state.page, this.state.items);
    }

    prev() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.max(0, step - 1) })
    }

    next() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.min(2, step + 1) })
    }

    clearSearch() {
        this.setState({
            search: '',
        })
        this.props.searchResult('')
    }

    defaultState() {
        return {
            step: 0,
            search: '',
            job: {},
            machine: {
                machineId: 1,
                machineImage: '',
                machineName: 'Nessuna macchina',
                machineHourlyRate: '0.00',
                machineType: 1,
            },
            description: '',
            workerHours: 0,
            machineHours: 0,
            workerMinutes: 0,
            machineMinutes: 0,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { workday } = this.props
        if (this.state.workDate.format('YYYY-MM-DD') !== workday.data.date.format('YYYY-MM-DD')) {
            this.setState({
                workDate: moment(workday.data.date),
            })
        }

        if (this.props.creatingWork !== prevProps.creatingWork) {
            if (!this.props.creatingWork) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                        ...this.defaultState(),
                    })
                } else {
                    if (this.props.createStatus.error && this.props.createStatus.error.response.status === 409) {
                        this.setState({
                            status: 'error',
                            errorMessage: 'Dati inseriti errati',
                        })
                    } else {
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }
    }

    save() {
        let { network, worker } = this.props
        let { job, machine, description, workDate, workerHours, machineHours, workerMinutes, machineMinutes } = this.state

        let work = {
            workerId: worker,
            jobCode: job.jobCode,
            workDate: workDate.format('YYYY-MM-DD'),
            description: description, //TODO
            workerHours: workerHours,
            machineHours: machineHours,
            workerMinutes: workerMinutes,
            machineMinutes: machineMinutes,
            machineId: machine.machineId,
        }

        network.createWork(work, true)
    }

    render() {
        let { step, job, description, machine, workerHours, machineHours } = this.state
        let { worker } = this.props

        let content = <div />

        //if (this.state.getDetailedJobList.fetching) {
        //	content = <div>Loading</div>;
        //} else if (this.state.getDetailedJobList.data.data) {
        content = [
            <Step.Group key="stepgroup">
                <Step active={step === 0} completed={step > 0}>
                    <Icon name="book" />
                    <Step.Content>
                        <Step.Title>Commissione</Step.Title>
                        <Step.Description>{job.jobCode ? job.jobCode : 'Quale commessa?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                    <Icon name="hdd" />
                    <Step.Content>
                        <Step.Title>Macchina</Step.Title>
                        <Step.Description>{machine.machineId ? machine.machineName : 'Quale macchina?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2} completed={step > 2} disabled={step < 2}>
                    <Icon name="clock" />
                    <Step.Content>
                        <Step.Title>Ore</Step.Title>
                        <Step.Description>Quante ore lavorate?</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>,
            step < 2 ? (
                <Input
                    key="stepinput"
                    style={{ width: '100%' }}
                    icon={this.state.search.length > 0 ? <Icon name="delete" link onClick={() => this.clearSearch()} /> : <Icon name="search" />}
                    placeholder="Cerca..."
                    value={this.state.search}
                    onChange={(e, data) => {
                        let text = data.value
                        this.state.updateSearch(text)
                        this.setState({ search: text })
                    }}
                />
            ) : null,
            <View column fullw fullh style={{ overflow: 'hidden', marginTop: 0 }} key="stepcontent">
                {
                    {
                        0: (
                            <View column fullh style={{ height: '95%' }}>
                                <Jobs
                                    workerId={worker}
                                    noActions
                                    sub
                                    searchable
                                    selectable
                                    selected={job}
                                    style={{ height: '100%', overflowY: 'auto' }}
                                    onChange={(job) => {
                                        //console.log('Steps chose job', job);
                                        this.next()
                                        this.setState({ job })
                                    }}
                                    isEmployee
                                />
                            </View>
                        ),
                        1: (
                            <View column fullh style={{ height: '95%' }}>
                                <Machines
                                    workerId={worker}
                                    noActions
                                    isEmployee
                                    selectable
                                    showNoMachine
                                    sub
                                    selected={machine}
                                    style={{ height: '100%', overflowY: 'auto' }}
                                    onChange={(machine) => {
                                        this.next()
                                        this.setState({ machine })
                                    }}
                                />
                            </View>
                        ),
                        2: (
                            <View column fullh style={{ height: '95%' }}>
                                <View row between>
                                    <View column fullw around>
                                        <Numpad
                                            value={workerHours}
                                            type="number"
                                            control={Input}
                                            label="Ore operaio"
                                            placeholder="Ore operaio"
                                            onChange={(value) => {
                                                //console.log('Worker hours', value);
                                                this.setState({ workerHours: value })
                                            }}
                                        />
                                        <View around>
                                            <Checkbox
                                                toggle
                                                label="Mezz'ora"
                                                onChange={(e, data) => {
                                                    //console.log('Checkbox value', e, data.checked);
                                                    this.setState({ workerMinutes: data.checked ? 30 : 0 })
                                                }}
                                            />
                                        </View>
                                    </View>
                                    {machine.machineId !== 1 && (
                                        <View column around fullw>
                                            <Numpad
                                                value={machineHours}
                                                type="number"
                                                control={Input}
                                                label="Ore macchina"
                                                placeholder="Ore macchina"
                                                onChange={(value) => {
                                                    //console.log('Machine hours', value);
                                                    this.setState({ machineHours: value })
                                                }}
                                            />
                                            <View around>
                                                <Checkbox
                                                    toggle
                                                    label="Mezz'ora"
                                                    onChange={(e, data) => {
                                                        //console.log('Checkbox value', e, data.checked);
                                                        this.setState({ machineMinutes: data.checked ? 30 : 0 })
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    )}
                                </View>

                                <View row fullw>
                                    <Input
                                        id="form-input-control-work-description"
                                        label="Descrizione"
                                        placeholder="Fresature"
                                        name="description"
                                        style={{ width: '100%', marginTop: 40 }}
                                        value={description}
                                        onChange={(event) => {
                                            const target = event.target
                                            const value = target.type === 'checkbox' ? target.checked : target.value
                                            const name = target.name

                                            //console.log('Handling input', name, target, event);

                                            this.setState({
                                                [name]: value,
                                            })
                                        }}
                                    />
                                </View>
                            </View>
                        ),
                    }[step]
                }
                <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.prev()}>Indietro</Button>
                    {step < 2 && (
                        <Button
                            color="black"
                            onClick={() => this.next()}
                            disabled={(step === 0 && !job.jobCode) || (step === 1 && !machine.machineId)}
                        >
                            Avanti
                        </Button>
                    )}
                    {step === 2 && (
                        <Button color="green" onClick={() => this.save()}>
                            Ok
                        </Button>
                    )}
                </View>
            </View>,
        ]

        return (
            <View fullw fullh column style={{ padding: 16 }}>
                {content}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getDetailedJobList,
        createWork: { data: createResult, fetching: creatingWork, status: createStatus },
        workday,
    } = state

    return {
        getDetailedJobList,
        createResult,
        creatingWork,
        createStatus,
        workday,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Steps))
