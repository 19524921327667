import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { ImageDefault } from '../components'
import { Jobs, Labels } from '../containers'

class Labeling extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState()
        this.printRef = React.createRef()
    }

    defaultState() {
        return {
            job: {},
            label: {},
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getLabelList } = this.props
        if (!getLabelList.fetching && prevProps.getLabelList.fetching) {
        }
    }

    componentDidMount() {
        const { network } = this.props
        network.getLabelList(null, true)
    }

    renderLayoutElement(value, index) {
        const { layoutElementStyle: style } = value

        let layoutElementStyle = {}
        if (style) {
            layoutElementStyle = JSON.parse(style)
        }

        switch (value.layoutElementType) {
            default:
            case 1: // Text
                return (
                    <View key={index} style={{ left: value.layoutElementX, top: value.layoutElementY, position: 'absolute' }}>
                        <span style={{ ...layoutElementStyle }}>{value.layoutElementValue}</span>
                    </View>
                )
            case 2: // Image
                return (
                    <View key={index} style={{ left: value.layoutElementX, top: value.layoutElementY, position: 'absolute' }}>
                        <ImageDefault fromRoot src={value.layoutElementValue} ui={false} style={{ ...layoutElementStyle }} />
                    </View>
                )
        }
    }

    render() {
        const { job, label } = this.state
        const { worker } = this.props

        return (
            <View column fullw fullh>
                <View row fullw style={{ height: '50%' }}>
                    <View column fullw fullh>
                        <Jobs
                            workerId={worker}
                            noActions
                            sub
                            searchable
                            selectable
                            selected={job}
                            style={{ height: '100%', overflowY: 'auto' }}
                            onChange={(job) => {
                                this.setState({ job })
                            }}
                            isEmployee
                        />
                    </View>

                    <View column fullw fullh>
                        <Labels
                            selectable
                            selected={label}
                            onChange={(label) => {
                                this.setState({ label })
                            }}
                        />
                    </View>
                </View>
                {label &&
                    label.layout && [
                        <ReactToPrint
                            ref={(el) => (this.printButton = el)}
                            trigger={() => <button id="print-button-report">Stampa</button>}
                            content={() => {
                                //console.log('printing content', this.printRef);
                                return this.printRef.current
                            }}
                        />,
                        <View row fullw style={{ width: label.layout.layoutWidth, height: label.layout.layoutHeight }}>
                            <div style={{ position: 'relative' }} ref={this.printRef}>
                                {label.layout.elements.map((value, index) => {
                                    return this.renderLayoutElement(value, index)
                                })}
                            </div>
                        </View>,
                    ]}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getLabelList,
        loginEmployee: {
            data: { user: worker },
        },
    } = state

    return {
        getLabelList,
        worker,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Labeling)))
