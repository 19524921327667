export function getWorkListForDate(date, worker, isEmployee) {
    let { getWorkListForDateRequest, getWorkListForDateError, getWorkListForDateResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_work_list_for_date',
        this.createBundle(
            'get_work_list_for_date',
            { date, worker },
            this.GET,
            getWorkListForDateRequest,
            getWorkListForDateResult,
            getWorkListForDateError,
            isEmployee
        )
    )
}
