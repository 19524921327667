import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickImage } from '../components'

class DocumentEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { document } = this.props.match.params

        if (!id) {
            id = document
        }

        this.state = {
            documentId: id,
            documentName: '',
            documentType: 0,
            documentHourlyRate: 0.0,
            documentImage: '',
        }
    }

    componentDidMount() {
        let { documentId } = this.state

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])
        this.state.network.getDocumentTypeList()

        if (documentId) {
            this.state.network.getDocument(documentId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        console.log('JobEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getDocument } = this.props
        if (getDocument && getDocument.fetching !== prevProps.getDocument.fetching && !getDocument.fetching) {
            if (getDocument.status.success) {
                let document = getDocument.data.data
                this.setState(document)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { documentId, documentName, documentType, documentHourlyRate, documentImage } = this.state

        if (documentName === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createDocument({
            documentId,
            documentName,
            documentType,
            documentHourlyRate,
            documentImage,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { fetching, creating, options, documentName, documentType, documentHourlyRate, documentImage, getDocument } = this.state

        console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creating || getDocument.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-document-name"
                            control={Input}
                            label="Nome Macchina"
                            name="documentName"
                            value={documentName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-document-type"
                            control={Select}
                            options={options}
                            label="Tipo macchina"
                            placeholder="Tipo macchina"
                            name="documentType"
                            value={documentType}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Tipo selected', id)
                                this.setState({ documentType: id })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label="Immagine"
                            placeholder="Immagine"
                            name="documentImage"
                            value={documentImage}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ documentImage: image })
                            }}
                            value={this.state.documentImage}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-document-cost"
                            control={Input}
                            type="number"
                            label="Prezzo orario"
                            placeholder="0.0"
                            step="0.1"
                            name="documentHourlyRate"
                            value={documentHourlyRate}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getDocumentTypeList: {
            data: { data: types },
            fetching,
        },
        createDocument: { data: createResult, fetching: creating, status: createStatus },
        getDocument,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        options.push({
            key: type.documentType,
            text: type.typeDescription,
            value: type.documentType,
        })
    }

    return {
        options,
        types,
        fetching,
        createResult,
        creating,
        getDocument,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DocumentEditor)))
