import { REALTIME_TYPES } from '../actions/realtime'

const defaultState = {
    connected: false,
    data: {},
}
const Reducer = (state = defaultState, action) => {
    switch (action.type) {
        case REALTIME_TYPES.newdata: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                data: result,
            }
        }
        case REALTIME_TYPES.connect: {
            let { result } = action
            if (result === undefined) result = {}

            return defaultState
        }
        case REALTIME_TYPES.disconnect: {
            let { result } = action
            if (result === undefined) result = {}

            return defaultState
        }
        default:
            return state
    }
}

export default Reducer
