export function deleteVariant(data) {
	let { deleteVariantRequest, deleteVariantError, deleteVariantResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_variant',
		this.createBundle(
			'delete_variant',
			{ variant: data },
			this.POST,
			deleteVariantRequest,
			deleteVariantResult,
			deleteVariantError
		)
	);
}
