export function getCollection(data) {
	let { getCollectionRequest, getCollectionError, getCollectionResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_collection',
		this.createBundle(
			'get_collection',
			{ collection: data },
			this.GET,
			getCollectionRequest,
			getCollectionResult,
			getCollectionError
		)
	);
}
