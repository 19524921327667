import { connectNetwork } from 'lib/NetworkProvider'
import React from 'react'
import ReactExport from 'react-data-export'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Loader } from 'semantic-ui-react'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class StockRecap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stockDone: false,
        }
    }

    componentDidMount() {
        let { deposit, wait, network } = this.props
        console.log('StockRecapping deposit', deposit)
        if (!wait) {
            network.getArticleRecap(null, null, '', deposit)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getArticleRecap, deposit, wait, network, onDone } = this.props
        let { stockDone } = this.state

        if (!getArticleRecap.fetching && prevProps.getArticleRecap.fetching) {
            this.setState({ stockDone: true })
        }

        /*
		if (!prevProps.wait && wait) {
			this.setState({
				recapDone: false,
				workerDone: false,
				machineDone: false
			});
		}
		*/

        if (prevProps.wait && !wait) {
            console.log('StockRecapping deposit', deposit)

            network.getArticleRecap(null, null, '', deposit)
        }

        if (stockDone && !wait && (!prevState.stockDone || (!wait && prevProps.wait))) {
            let stock = getArticleRecap.data.data
            let excelfile = []
            for (let i in stock) {
                let article = stock[i]

                let row = {
                    ...article,
                }

                excelfile.push(row)
            }

            if (onDone) {
                onDone({
                    stock,
                    excelfile,
                })
            }

            this.setState({
                stock,
                excelfile,
            })
        }
    }

    render() {
        let { getArticleRecap, deposit, element } = this.props
        let { excelfile, stockDone } = this.state

        if (getArticleRecap.fetching) {
            return <Loader active />
        }
        if (!stockDone) {
            return null
        } else {
            let filename = `articoli_deposito_${deposit}`
            if (deposit === -1) {
                filename = `articoli`
            }

            return (
                <ExcelFile filename={filename} element={element ? element : <Button>Download</Button>}>
                    <ExcelSheet data={excelfile} name="Riepilogo">
                        <ExcelColumn label="Codice articolo" value="code" />
                        <ExcelColumn label="Descrizione" value="description" />
                        <ExcelColumn label="Deposito" value="name" />
                        <ExcelColumn label="Quantita&#39;" value="qty" />
                        <ExcelColumn label="Data ultimo aggiornamento" value="updated" />
                    </ExcelSheet>
                </ExcelFile>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    let { login, getArticleRecap } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getArticleRecap }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(StockRecap)))
