export function getDocumentList(search, deposit, start, end) {
    let { getDocumentListRequest, getDocumentListError, getDocumentListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'app/document/get_document_list',
        this.createBundle(
            'app/document/get_document_list',
            { search, deposit, start, end },
            this.POST,
            getDocumentListRequest,
            getDocumentListResult,
            getDocumentListError
        )
    )
}
