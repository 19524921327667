export function deletePicture(data) {
	let { deletePictureRequest, deletePictureError, deletePictureResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_picture',
		this.createBundle(
			'delete_picture',
			{ picture: data },
			this.POST,
			deletePictureRequest,
			deletePictureResult,
			deletePictureError
		)
	);
}
