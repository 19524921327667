import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Breadcrumb, Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article, Collection, Empty, Family, Variant } from '../components'
import { createAlert } from '../lib/util'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const grid = 8

const getListStyle = (isDraggingOver) => ({
    background: 'white',
    padding: 0,
})

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid / 2,
    margin: `0 0 ${grid / 2}px 0`,

    // change background colour if dragging
    background: isDragging ? '#FEDE00' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
})

class OrderingGrids extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumb: [],
            stack: [],
            currentLevel: [],
            collections: [],
            lastClicked: null,
        }
    }

    save = () => {
        const { network } = this.props
        const { currentLevel, lastClicked } = this.state
        console.log('Saving ordering for', currentLevel)

        if (currentLevel.length === 0) {
            return
        }

        let counter = 0
        let orderingField = null
        let idField = null
        let createRequest = null

        if (currentLevel[0].collectionName) {
            orderingField = 'collectionOrdering'
            idField = 'collectionId'
            createRequest = 'createCollection'
        } else if (currentLevel[0].familyName) {
            orderingField = 'familyOrdering'
            idField = 'familyId'
            createRequest = 'createFamily'
        } else if (currentLevel[0].articleName) {
            orderingField = 'articleOrderingFamily'
            idField = 'articleId'
            createRequest = 'createStdArticle'
        } else if (currentLevel[0].variantName) {
            orderingField = 'variantOrdering'
            idField = 'variantId'
            createRequest = 'setArticleVariantOrdering'

            if (idField) {
                for (let i in currentLevel) {
                    i = parseInt(i)
                    currentLevel[i][orderingField] = i
                    network[createRequest](
                        {
                            [idField]: currentLevel[i][idField],
                            articleId: lastClicked.articleId,
                            [orderingField]: currentLevel[i][orderingField],
                        },
                        () => {
                            counter++
                            if (counter >= currentLevel.length) {
                                this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                            }
                        }
                    )
                }
            }
            return
        }

        if (idField) {
            for (let i in currentLevel) {
                i = parseInt(i)
                currentLevel[i][orderingField] = i
                network[createRequest](
                    {
                        [idField]: currentLevel[i][idField],
                        [orderingField]: currentLevel[i][orderingField],
                    },
                    () => {
                        counter++
                        if (counter >= currentLevel.length) {
                            this.props.success(success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {})))
                        }
                    }
                )
            }
        }
    }

    popStack = () => {
        let { stack, breadcrumb } = this.state
        if (stack.length === 0) return
        let currentLevel = stack.pop()
        breadcrumb.pop()
        this.setState({
            stack,
            currentLevel,
            breadcrumb,
        })
    }

    componentDidMount() {
        let { sub, network } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Su di un livello',
                    icon: 'long arrow alternate up',
                    position: 'right',
                    action: this.popStack,
                },
                {
                    name: 'Salva ordinamento',
                    icon: 'check',
                    position: 'none',
                    action: this.save,
                },
            ])
        }

        network.getCollectionsFull()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetching && !this.props.fetching) {
            if (this.props.collections.data) {
                this.setState({
                    currentLevel: this.props.collections.data,
                })
            }
        }
    }

    generateLayout = () => {
        const { currentLevel } = this.state

        console.log('Current level:', currentLevel)
        if (!currentLevel) return

        const components = []
        for (let index in currentLevel) {
            index = parseInt(index)
            const element = currentLevel[index]
            if (element.collectionName) {
                const key = `element_${element.collectionId}`
                components.push(
                    <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                                <Collection
                                    onTraverse={(collection) => {
                                        let { stack, breadcrumb, currentLevel } = this.state
                                        stack.push(currentLevel)
                                        breadcrumb.push({ key: collection.collectionName, content: collection.collectionName, link: false })
                                        this.setState({
                                            currentLevel: collection.families ? collection.families : [],
                                            stack,
                                            breadcrumb,
                                            lastClicked: collection,
                                        })
                                    }}
                                    noActions
                                    traversable
                                    type="full"
                                    collection={element}
                                />
                            </div>
                        )}
                    </Draggable>
                )
            } else if (element.familyName) {
                const key = `element_${element.familyId}`
                components.push(
                    <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                                <Family
                                    onTraverse={(family) => {
                                        let { stack, breadcrumb, currentLevel } = this.state
                                        stack.push(currentLevel)
                                        breadcrumb.push({ key: family.familyName, content: family.familyName, link: false })
                                        this.setState({
                                            currentLevel: family.articles ? family.articles : [],
                                            stack,
                                            breadcrumb,
                                            lastClicked: family,
                                        })
                                    }}
                                    noActions
                                    traversable
                                    type="full"
                                    family={element}
                                />
                            </div>
                        )}
                    </Draggable>
                )
            } else if (element.articleName) {
                const key = `element_${element.articleId}`
                components.push(
                    <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                                <Article
                                    onTraverse={(article) => {
                                        let { stack, breadcrumb, currentLevel } = this.state
                                        stack.push(currentLevel)
                                        breadcrumb.push({ key: article.articleName, content: article.articleName, link: false })
                                        this.setState({
                                            currentLevel: article.variants ? article.variants : [],
                                            stack,
                                            breadcrumb,
                                            lastClicked: article,
                                        })
                                    }}
                                    noActions
                                    traversable
                                    type="full"
                                    article={element}
                                />
                            </div>
                        )}
                    </Draggable>
                )
            } else if (element.variantName) {
                const key = `element_${element.variantId}`
                components.push(
                    <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                                <Variant article={this.state.lastClicked} noActions type="full" variant={element} />
                            </div>
                        )}
                    </Draggable>
                )
            }
        }

        return components
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        let { currentLevel } = this.state

        currentLevel = reorder(currentLevel, result.source.index, result.destination.index)

        this.setState({
            currentLevel,
        })
    }

    render() {
        let { breadcrumb } = this.state
        let { fetching, collections, onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else {
            console.log('OrderingGrids: ', collections)
            const components = this.generateLayout()
            return (
                <>
                    <View
                        fullw
                        around
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                            height: 10,
                        }}
                    >
                        {breadcrumb.length > 0 && (
                            <Breadcrumb size="large">
                                {breadcrumb.map((value, index) => {
                                    let extraProps = {}
                                    // if (!value.skip) {
                                    // 	extraProps = {
                                    // 		link: true,
                                    // 		as: NavLink,
                                    // 		to: value.path,
                                    // 	}
                                    // }
                                    return (
                                        <span key={index}>
                                            {index !== 0 && <Breadcrumb.Divider />}
                                            <Breadcrumb.Section style={{ color: 'black' }} {...extraProps} active={index === breadcrumb.length - 1}>
                                                {value.content}
                                            </Breadcrumb.Section>
                                        </span>
                                    )
                                })}
                                <Breadcrumb.Divider icon="right angle" />
                            </Breadcrumb>
                        )}
                    </View>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                            <View fullw className="droppableContainer">
                                {components.length > 0 && (
                                    <Droppable style={{ height: '100%', width: '100%' }} droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                {components}
                                            </div>
                                        )}
                                    </Droppable>
                                )}
                                {!fetching && components.length === 0 && <Empty />}
                            </View>
                        </View>
                    </DragDropContext>
                </>
            )
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getCollectionsFull, deleteOrderingGrid, search, organization } = state

    let collectionlist = getCollectionsFull

    return {
        collections: collectionlist.data,
        fetching: collectionlist.fetching,
        deleteOrderingGrid,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(OrderingGrids)))
