export const REALTIME_TYPES = {
    newdata: `NEW_DATA`,
    connect: `CONNECT`,
    disconnect: `DISCONNECT`,
}

export const REALTIME_ACTIONS = {
    newdata: (result, dispatch) =>
        dispatch({
            type: REALTIME_TYPES.newdata,
            result,
        }),
    connect: (result, dispatch) =>
        dispatch({
            type: REALTIME_TYPES.connect,
            result,
        }),
    disconnect: (result, dispatch) =>
        dispatch({
            type: REALTIME_TYPES.disconnect,
            result,
        }),
}
