export function getLabelList(data, isEmployee) {
	let { getLabelListRequest, getLabelListError, getLabelListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_label_list',
		this.createBundle(
			'get_label_list',
			{ label: data },
			this.GET,
			getLabelListRequest,
			getLabelListResult,
			getLabelListError,
			isEmployee
		)
	);
}
