import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Calendar } from '../components'

class CalendarView extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {}

    componentDidMount() {}

    render() {
        let { worker } = this.props

        return (
            <View row fullw fullh>
                <Calendar worker={worker} isEmployee={this.props.isEmployee} />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        workday: {
            data: { date: workday },
        },
        loginEmployee: {
            data: { user: worker },
        },
    } = state

    return {
        workday,
        worker,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CalendarView)))


