export function doAssignDuplicates(article_one, article_two) {
    let { doAssignDuplicatesRequest, doAssignDuplicatesError, doAssignDuplicatesResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'do_assign_duplicates',
        this.createBundle(
            'do_assign_duplicates',
            { article_one, article_two },
            this.POST,
            doAssignDuplicatesRequest,
            doAssignDuplicatesResult,
            doAssignDuplicatesError
        )
    )
}
