import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { withRouter } from 'react-router'

import View from 'lib/View'
import { Card, Header, Statistic } from 'semantic-ui-react'
import { initializePage, baseViewDispatch } from '../lib/util'
import Chart from 'react-apexcharts'
import { Empty } from '../components'
import EllipsisText from 'react-ellipsis-text'

import _ from 'lodash/lang'
import moment from 'moment-timezone'
import Moment from 'react-moment'

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

const REASON_COLORS = ['yellow', 'green', 'red', 'orange', 'red']
const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    3: 'Pausa',
    99: 'Termine Lavori',
}

const baseChart = {
    series: [
        {
            name: 'Corretti',
            data: [],
        },
        {
            name: 'Errati',
            data: [],
        },
    ],
    options: {
        chart: {
            type: 'area',
            stacked: false,
            height: 300,
            zoom: {
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
            },
        },
        colors: ['green', 'yellow'],
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        title: {
            text: 'Andamento',
            align: 'left',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100],
            },
        },
        yaxis: {
            labels: {},
            title: {
                text: 'Pezzi',
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return moment(timestamp).format('DD/MM HH:mm:ss')
                },
            },
        },
        tooltip: {
            shared: false,
            y: {},
        },
    },
}
class Realtime extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allHadesData: {},
        }
    }

    componentDidMount() {
        let { network } = this.props

        network.getRecentJobList(0, 10, '', null, false, false)
        network.getMachineList('')
        network.getWorkerList('')

        initializePage(this, [], {})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let { network, hadesTokenOk, jobs, fetching, getFromDerived, realtimeData } = this.props

        // Job hades Data
        if (
            hadesTokenOk &&
            jobs.data &&
            Object.keys(jobs.data).length > 0 &&
            (prevProps.hadesTokenOk != hadesTokenOk || prevProps.fetching != fetching)
        ) {
            let reqs = []
            for (const [key, value] of Object.entries(jobs.data)) {
                let job = value[0]
                let { workOrders = [] } = job
                let id = job.jobCode

                for (let order of workOrders) {
                    let sub_id = order.workCode
                    reqs.push({
                        destination: 'job',
                        id: `${id}`,
                        sub_id: `${sub_id}`,
                        filters: [
                            {
                                key: 'timestamp',
                                symbol: '>=',
                                value: moment().subtract(1, 'days'),
                            },
                        ],
                    })
                }
            }
            network.getFromDerived(reqs)
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props

                let allHadesData = {}
                for (let hdata of hadesData) {
                    let { data = [] } = hdata || {}
                    let firstRow = data[0] || {}
                    let { id, sub_id } = firstRow
                    if (!id) continue

                    // fill chart
                    let gooddates = []
                    let baddates = []
                    for (let d of data) {
                        gooddates.push({
                            x: d.timestamp,
                            y: d.quantity,
                        })
                        baddates.push({
                            x: d.timestamp,
                            y: d.scrap,
                        })
                    }
                    let chart = _.cloneDeep(baseChart)
                    chart.series[0].data = [...gooddates]
                    chart.series[1].data = [...baddates]
                    chart.options.chart.id = `chart-${id}-${sub_id}`.replace(' ', '')

                    allHadesData[`${id}-${sub_id}`] = {
                        chart: _.cloneDeep(chart),
                        data: [...data],
                    }
                }

                this.setState({
                    allHadesData,
                })
            }
        }

        // On Realtime data
        if (realtimeData.data && realtimeData.data != prevProps.realtimeData.data) {
            let { allHadesData } = this.state
            let { data, destination } = realtimeData

            let firstRow = data[0] || {}
            let { id, sub_id } = firstRow
            if (!id || destination != 'job') return

            // fill chart
            let gooddates = []
            let baddates = []
            for (let d of data) {
                gooddates.push({
                    x: d.timestamp,
                    y: parseInt(d.quantity),
                })
                baddates.push({
                    x: d.timestamp,
                    y: parseInt(d.scrap),
                })
            }

            let chart = allHadesData[`${id}-${sub_id}`] ? _.cloneDeep(allHadesData[`${id}-${sub_id}`].chart) : _.cloneDeep(baseChart)
            chart.series[0].data = [...gooddates, ...chart.series[0].data]
            chart.series[1].data = [...baddates, ...chart.series[1].data]
            chart.options.chart.id = `chart-${id}-${sub_id}`.replace(' ', '')

            let allData = []
            if (allHadesData[`${id}-${sub_id}`]) {
                allData = [...allHadesData[`${id}-${sub_id}`].data]
            }

            allHadesData[`${id}-${sub_id}`] = {
                chart: _.cloneDeep(chart),
                data: [...data, ...allData],
            }

            this.setState({
                allHadesData,
            })
        }
    }

    render() {
        let { allHadesData } = this.state
        let { machineList = [], workerList = [] } = this.props

        if (Object.keys(allHadesData).length == 0) return <Empty />
        return (
            <View noflex column fullw>
                <Card.Group style={{ width: '100%', margin: 0 }}>
                    {Object.keys(allHadesData).map((key) => {
                        let { data = [], chart } = allHadesData[key]
                        let { id, sub_id, quantity, scrap, reason, terminal, operator } = data[0] || {}
                        let qr = quantity / (quantity + scrap) || 0

                        let worker = workerList.filter((x) => x.workerIdentifier == operator)[0]
                        let { workerName = '--' } = worker || {}
                        let machine = machineList.filter((x) => x.machineIdentifier == terminal)[0]
                        let { machineName = '--' } = machine || {}

                        return (
                            <Card key={`${id}-${sub_id}`} style={{ width: '98%' }}>
                                <View justify="space-between" style={{ padding: 20 }}>
                                    <View column>
                                        <Header as="h4">
                                            <EllipsisText text={`${id}`} length={18} />
                                        </Header>

                                        <EllipsisText text={`${sub_id}`} length={18} />
                                    </View>
                                    <View>
                                        <Statistic size="mini" color={REASON_COLORS[reason]}>
                                            <Statistic.Value>{REASON_LABELS[reason]}</Statistic.Value>
                                        </Statistic>
                                    </View>
                                    <View>
                                        <Statistic size="mini" color="green">
                                            <Statistic.Value>{quantity}</Statistic.Value>
                                            <Statistic.Label>Corretti</Statistic.Label>
                                        </Statistic>
                                    </View>

                                    <View>
                                        <Statistic size="mini" color="yellow">
                                            <Statistic.Value>{scrap}</Statistic.Value>
                                            <Statistic.Label>Errati</Statistic.Label>
                                        </Statistic>
                                    </View>
                                    <View>
                                        <Statistic size="mini">
                                            <Statistic.Value>macchina</Statistic.Value>
                                            <Statistic.Label>
                                                <EllipsisText text={machineName} length={18} />
                                            </Statistic.Label>
                                        </Statistic>
                                    </View>
                                    <View>
                                        <Statistic size="mini">
                                            <Statistic.Value>operatore</Statistic.Value>
                                            <Statistic.Label>
                                                <EllipsisText text={workerName} length={18} />
                                            </Statistic.Label>
                                        </Statistic>
                                    </View>
                                </View>

                                <View noflex style={{ width: '98%' }} id={`chart-${id}-${sub_id}`.replace(' ', '')}>
                                    <Chart options={chart.options} series={chart.series} type="area" height={300} />
                                </View>
                            </Card>
                        )
                    })}
                </Card.Group>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getRecentJobList,
        getMachineList: {
            data: { data: machineList },
        },
        getWorkerList: {
            data: { data: workerList },
        },
        hades: {
            getFromDerived,
            hadesToken: {
                status: { success: hadesTokenOk },
            },
        },
        realtime: { data: realtimeData },
    } = state

    return {
        jobs: getRecentJobList.data,
        fetching: getRecentJobList.fetching,
        status: getRecentJobList.status,
        machineList,
        workerList,
        hadesTokenOk,
        getFromDerived,
        realtimeData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Realtime)))
