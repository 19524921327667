import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class TurnEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { turn } = this.props.match.params

        if (!id) {
            id = turn
        }

        this.state = {
            turnId: id,
            turnName: '',
            start: 15,
            length: 4,
            range: [15, 19],
        }
    }

    componentDidMount() {
        let { turnId } = this.state

        if (turnId) {
            console.log('Getting turn ', turnId)
            this.state.network.getTurn(turnId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getTurn } = this.props
        if (getTurn && getTurn.fetching !== prevProps.getTurn.fetching && !getTurn.fetching) {
            if (getTurn.status.success) {
                let turn = getTurn.data.data
                console.log('Turn editor setting state with', turn)
                this.setState({
                    ...turn,
                    range: [turn.start, turn.start + turn.length],
                })
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { turnId, turnName, range } = this.state

        let start = range[0]
        let length = range[1] - range[0]

        if (turnId === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createTurn({
            turnId,
            turnName,
            start,
            length,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)
        let stateObj = {
            [name]: value,
        }

        /*
		if (name === 'turnName') {
			stateObj.turnId = value.toLowerCase().replace(/\s/g, '_')
		}
		*/

        this.setState(stateObj)
    }

    /*
	<Form.Field
		id='form-input-control-turn-code'
		control={Input}
		label='Codice turno'
		name='turnId'
		value={turnId}
		disabled={true}
		onChange={(e) => this.handleInput(e)}
		placeholder='turno_1'
	/>*/

    render() {
        let { creating, turnName, start, length, range } = this.state
        let { fetchingRoles } = this.props

        let marks = {}

        for (let i = 0; i <= 24; i += 1) {
            let hours = Math.floor(i)
            let minutes = i % 1
            if (minutes < 10) minutes = `0${minutes}`
            marks[i] = <span>{hours}</span>
        }

        console.log('TurnEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || fetchingRoles}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-turn-name"
                            control={Input}
                            label="Nome turno"
                            placeholder="Turno di mattina"
                            name="turnName"
                            value={turnName}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Range
                            onChange={(value) => {
                                console.log('Selected range', value)
                                this.setState({ range: value })
                            }}
                            style={{ height: 50, marginTop: 8 }}
                            value={range}
                            defaultValue={[start, start + length]}
                            min={0}
                            max={24}
                            step={0.5}
                            pushable={true}
                            dots={true}
                            marks={marks}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Lavoratore inserito con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu&#39; tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        createTurn: { data: createResult, fetching: creating, status: createStatus },
        getTurn,
    } = state

    return {
        createResult,
        creating,
        createStatus,
        getTurn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TurnEditor)))
