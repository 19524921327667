import { SEARCH_TYPES } from '../actions/search'
import reducer from './requests'
const Reducer = (
    state = {
        fetching: false,
        status: {
            success: false,
            error: undefined,
        },
        data: '',
    },
    action
) => {
    let requestReducer = reducer(SEARCH_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer
