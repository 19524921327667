export const SOCKET_HERCULES_TYPES = {
    newdata: `HERCULES_NEW_DATA`,
    connect: `HERCULES_CONNECT`,
    disconnect: `HERCULES_DISCONNECT`,
}

export const SOCKET_HERCULES_ACTIONS = {
    newdata: (result, dispatch) =>
        dispatch({
            type: SOCKET_HERCULES_TYPES.newdata,
            result,
        }),
    connect: (result, dispatch) =>
        dispatch({
            type: SOCKET_HERCULES_TYPES.connect,
            result,
        }),
    disconnect: (result, dispatch) =>
        dispatch({
            type: SOCKET_HERCULES_TYPES.disconnect,
            result,
        }),
}
