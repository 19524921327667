import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { withRouter } from 'react-router'

import View from 'lib/View'
import {
    Default,
    Mobile,
    formatMoney,
    displayTime,
    displayTimestamp,
    timeAndCosts,
    pureData,
    overviewData,
    timeAndCostsMultiData,
    pureDataMultiData,
    overviewMultiData,
    jobOverview,
} from '../lib/util'
import { Tab, Message, Icon, Dropdown, Card, Header, Statistic } from 'semantic-ui-react'
import { initializePage, baseViewDispatch } from '../lib/util'
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react'
import { SimpleTable } from '../components'

import _ from 'lodash/lang'
import moment from 'moment-timezone'
import Moment from 'react-moment'

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

const REASON_COLORS = ['yellow', 'green', 'red', 'orange', 'red']
const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    3: 'Pausa',
    99: 'Termine Lavori',
}

class Analytics extends Component {
    constructor(props) {
        super(props)

        this.state = {
            date: moment().hour(0).minute(0).second(0),
            visualizationType: 'day',
        }
    }

    componentDidMount() {
        let { network } = this.props

        network.getMachineList('')
        network.getWorkerList('')

        initializePage(this, [], {})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let { machineList, network, getFrom, getFromDerived } = this.props
        let { derivedRequest } = this.state

        // Machine list Data
        if (machineList != prevProps.machineList) {
            this.setState(
                (pstate) => ({
                    derivedRequest: 'machine_job',
                    from: pstate.date,
                    to: moment(pstate.date).add(24, 'hours'),
                }),
                () => {
                    let reqs = []
                    for (let machine of machineList) {
                        let { machineIdentifier } = machine
                        reqs.push({
                            destination: 'machine_job',
                            id: `${machineIdentifier}`,
                            sub_id: 'default',
                            filters: [
                                {
                                    key: 'timestamp',
                                    symbol: '>=',
                                    value: this.state.from.format(),
                                },
                                {
                                    key: 'timestamp',
                                    symbol: '<',
                                    value: this.state.to.format(),
                                },
                            ],
                        })
                    }
                    network.getFromDerived(reqs)
                }
            )
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props
                switch (derivedRequest) {
                    case 'machine_job':
                        let jobsWorks = []
                        for (let hdata of hadesData) {
                            let { data = [] } = hdata || {}
                            for (let item of data) {
                                let { job, work } = item
                                if (!jobsWorks.some((el) => el.job === job && el.work == work)) {
                                    jobsWorks.push({ job, work })
                                }
                            }
                        }
                        console.info('to require', jobsWorks)
                        let reqs = []
                        for (let jw of jobsWorks) {
                            let { job, work } = jw

                            let filters = [
                                {
                                    key: 'timestamp',
                                    symbol: '>=',
                                    value: this.state.from.format(),
                                },
                                {
                                    key: 'timestamp',
                                    symbol: '<',
                                    value: this.state.to.format(),
                                },
                            ]

                            reqs.push({
                                destination: 'job',
                                id: `${job}`,
                                sub_id: `${work}`,
                                filters,
                            })
                        }
                        this.setState({ derivedRequest: 'job' }, () => network.getFromDerived(reqs))
                        break
                    case 'job':
                        console.info('jobs hades data', hadesData)
                        this.setState({ hadesData }, () => this.computeTimeCosts())
                        break
                }
            }
        }
    }

    renderControls() {
        let { visualizationType } = this.state
        let { network, machineList } = this.props

        let options0 = [
            {
                key: 'day',
                value: 'day',
                text: 'Singola Giornata',
            },
            {
                key: 'range',
                value: 'range',
                text: 'Range Temporale',
            },
        ]

        return (
            <View fullw justify="space-between" style={{ paddingRight: 10 }}>
                <Header as="h4">
                    <Icon name="filter" />
                    <Header.Content>
                        Visualizza{' '}
                        <Dropdown
                            inline
                            header="Modalità"
                            options={options0}
                            value={visualizationType}
                            onChange={(e, { value }) => {
                                this.setState({ visualizationType: value })
                            }}
                        />
                    </Header.Content>
                </Header>
                {
                    {
                        day: (
                            <DateInput
                                name="date"
                                placeholder="Data"
                                value={this.state.date}
                                iconPosition="left"
                                popupPosition="bottom right"
                                onChange={(event, { name, value }) => {
                                    let from = moment(value, 'DD-MM-YYYY')
                                    let to = moment(from).add(24, 'hours')
                                    this.setState(
                                        {
                                            [name]: value,
                                            derivedRequest: 'machine_job',
                                            from,
                                            to,
                                        },
                                        () => {
                                            console.info('data', from, to)
                                            console.info('machines', machineList)
                                            let reqs = []
                                            for (let machine of machineList) {
                                                let { machineIdentifier } = machine
                                                reqs.push({
                                                    destination: 'machine_job',
                                                    id: `${machineIdentifier}`,
                                                    sub_id: 'default',
                                                    filters: [
                                                        {
                                                            key: 'timestamp',
                                                            symbol: '>=',
                                                            value: from.format(),
                                                        },
                                                        {
                                                            key: 'timestamp',
                                                            symbol: '<',
                                                            value: to.format(),
                                                        },
                                                    ],
                                                })
                                            }
                                            network.getFromDerived(reqs)
                                        }
                                    )
                                }}
                            />
                        ),
                        range: (
                            <DatesRangeInput
                                name="datesRange"
                                placeholder="Da - A"
                                value={this.state.datesRange}
                                iconPosition="left"
                                onChange={(event, { name, value }) => {
                                    let date = value.split(' - ')

                                    let from = moment(date[0], 'DD-MM-YYYY')
                                    let to = moment(date[1], 'DD-MM-YYYY').add(24, 'hours')
                                    this.setState(
                                        {
                                            [name]: value,
                                            derivedRequest: 'machine_job',
                                            from,
                                            to,
                                        },
                                        () => {
                                            if (!from.isValid() || !to.isValid()) return

                                            console.info('data', from, to)
                                            let reqs = []
                                            for (let machine of machineList) {
                                                let { machineIdentifier } = machine
                                                reqs.push({
                                                    destination: 'machine_job',
                                                    id: `${machineIdentifier}`,
                                                    sub_id: 'default',
                                                    filters: [
                                                        {
                                                            key: 'timestamp',
                                                            symbol: '>=',
                                                            value: from.format(),
                                                        },
                                                        {
                                                            key: 'timestamp',
                                                            symbol: '<',
                                                            value: to.format(),
                                                        },
                                                    ],
                                                })
                                            }
                                            network.getFromDerived(reqs)
                                        }
                                    )
                                }}
                            />
                        ),
                    }[visualizationType]
                }
            </View>
        )
    }

    computeTimeCosts() {
        let { machineList, workerList } = this.props
        let { hadesData } = this.state
        let timeCosts = []

        timeCosts = timeAndCostsMultiData(null, null, hadesData)

        let bodies = []
        let total = {
            elapsed: 0,
            quantity: 0,
            scrap: 0,
            cost: 0,
            terminalCost: 0,
            workerCost: 0,
        }
        for (let key in timeCosts) {
            let reason = REASON_LABELS[key] || 'Altro'
            // console.info('searching for costs', key, timeCosts[key])
            let { elapsed, quantity, scrap, costs } = timeCosts[key]
            let cost = 0
            let terminalCost = 0
            let workerCost = 0
            for (let c of costs) {
                let { operator, terminal, elapsed } = c
                let hours = elapsed / 1000 / 3600

                let worker = workerList.filter(({ workerId }) => workerId == operator)[0] || {}
                let machine = machineList.filter(({ machineId }) => machineId == terminal)[0] || {}

                let { workerHourlyRate = 0 } = worker
                let { machineHourlyRate = 0 } = machine

                cost += hours * workerHourlyRate + hours * machineHourlyRate
                terminalCost += hours * machineHourlyRate
                workerCost += hours * workerHourlyRate
            }

            let unitcost = cost / Math.max(1, quantity + scrap)
            total = {
                elapsed: total.elapsed + elapsed,
                quantity: total.quantity + quantity,
                scrap: total.scrap + scrap,
                cost: total.cost + cost,
                terminalCost: total.terminalCost + terminalCost,
                workerCost: total.workerCost + workerCost,
            }
            bodies.push([
                reason,
                displayTime(elapsed),
                quantity,
                scrap,
                `${formatMoney(cost)}€`,
                `${formatMoney(terminalCost)}€`,
                `${formatMoney(workerCost)}€`,
                `${formatMoney(unitcost)}€`,
            ])
        }
        let unitcost = total.cost / Math.max(1, total.quantity + total.scrap)
        let totalRow = [
            displayTime(total.elapsed),
            total.quantity,
            total.scrap,
            `${formatMoney(total.cost)}€`,
            `${formatMoney(total.terminalCost)}€`,
            `${formatMoney(total.workerCost)}€`,
            `${formatMoney(unitcost)}€`,
        ]

        this.setState({
            bodies,
            totalRow,
            total,
        })
    }
    renderPhaseTimeCosts() {
        let { bodies, totalRow } = this.state
        return (
            <SimpleTable
                label="Dati Tempi & Costi"
                labelColor="teal"
                color="teal"
                heads={['Causale', 'Tempo Trascorso', 'Corretti', 'Errati', 'Costo', 'C. Macchina', 'C. Impiegato', 'C. Unitario']}
                bodies={bodies}
                total={totalRow}
            />
        )
    }

    render() {
        return (
            <View column fullw>
                {this.renderControls()}
                <View height={20} />
                <Card fluid>
                    <Card.Content>
                        <View fullw column>
                            {this.renderPhaseTimeCosts()}
                        </View>
                    </Card.Content>
                </Card>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getDetailedJobList,
        getMachineList: {
            data: { data: machineList },
        },
        getWorkerList: {
            data: { data: workerList },
        },
        hades: {
            getFromDerived,
            hadesToken: {
                status: { success: hadesTokenOk },
            },
        },
    } = state

    return {
        jobs: getDetailedJobList.data,
        fetching: getDetailedJobList.fetching,
        status: getDetailedJobList.status,
        machineList,
        workerList,
        hadesTokenOk,
        getFromDerived,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Analytics)))
