export async function getFrom(data) {
    let { getFromRequest, getFromError, getFromResult, hades_tkn } = this.props
    getFromRequest(data)
    fetch(this.HADES + 'get_from', {
        method: 'post',
        body: JSON.stringify({
            ...data,
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + hades_tkn,
        },
    })
        .then((res) => res.json())
        .then((result) => getFromResult(result))
        .catch((error) => getFromError(error))
}

export async function getFromDerived(data) {
    let { getFromDerivedRequest, getFromDerivedError, getFromDerivedResult, hades_tkn } = this.props
    getFromDerivedRequest(data)
    let requests = []
    for (let d of data) {
        requests.push(
            fetch(this.HADES + 'get_from', {
                method: 'post',
                body: JSON.stringify({ ...d }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + hades_tkn,
                },
            }).then((res) => res.json())
        )
    }

    Promise.all(requests)
        .then((result) => getFromDerivedResult(result))
        .catch((error) => {
            console.error(error)
            getFromDerivedError(error)
        })
}

export function hadesToken(subscriber, subscriber_id) {
    let { hadesTokenRequest, hadesTokenError, hadesTokenResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'hades_token',
        this.createBundle('hades_token', { subscriber, subscriber_id }, this.GET, hadesTokenRequest, hadesTokenResult, hadesTokenError)
    )
}
