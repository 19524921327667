export function getBabypressaDataFilter(data) {
    let { getBabypressaDataFilterRequest, getBabypressaDataFilterError, getBabypressaDataFilterResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_babypressa_data_filter',
        this.createBundle(
            'get_babypressa_data_filter',
            { search: data },
            this.GET,
            getBabypressaDataFilterRequest,
            getBabypressaDataFilterResult,
            getBabypressaDataFilterError
        )
    )
}
