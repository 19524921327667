export function getVariant(data) {
	let { getVariantRequest, getVariantError, getVariantResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_variant',
		this.createBundle(
			'get_variant',
			{ variant: data },
			this.GET,
			getVariantRequest,
			getVariantResult,
			getVariantError
		)
	);
}
