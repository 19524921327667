import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Card, Icon, Input, Table } from 'semantic-ui-react'
import { floatTimeToString, timeString } from '../lib/util'
import { Confirmation, ImageDefault } from '../components'

const { app, employees, gt2000dataEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class GT2000Data extends Component {
    constructor(props) {
        super(props)
        let { onCostChange, gt2000data } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            ...gt2000data,
        }

        if (onCostChange && gt2000data) {
            onCostChange(this.totalCost(), gt2000data.idImpiegato)
        }
    }

    totalCost() {
        let { prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state
        let total = (oreOperaio + minutiOperaio / 60) * prezzoOrarioImpiegato
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange } = this.props
        let { idImpiegato, prezzoOrarioImpiegato } = this.state
        if (prezzoOrarioImpiegato !== prevState.prezzoOrarioImpiegato) {
            if (onCostChange) {
                onCostChange(this.totalCost(), idImpiegato)
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Impiegato</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { nomeImpiegato, prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state

        return (
            <Table.Row>
                <Table.Cell>{nomeImpiegato}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={prezzoOrarioImpiegato}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    prezzoOrarioImpiegato: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{prezzoOrarioImpiegato}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(oreOperaio, minutiOperaio)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    /*w
a300 data:  {
	timestamp
	line
	logid
}
*/
    renderTableHeader() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Log</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderForBalance()
        } else {
            let { line } = this.props.gt2000data
            return (
                <Table.Row>
                    <Table.Cell>{line}</Table.Cell>
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, gt2000data } = this.props

        network.deleteGT2000Data(gt2000data)

        this.setState({ deleting: false })
    }

    render() {
        let { type, role } = this.props
        let { deleting, idImpiegato, nomeImpiegato, pin, immagineImpiegato, prezzoOrarioImpiegato } = this.state
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Card style={styles.jobCard} {...extraprops} color={this.state.colors[col]}>
                            <Confirmation
                                message="Vuoi davvero eliminare questo impiegato?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Card.Content style={styles.jobContent}>
                                <View fullw row>
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={immagineImpiegato} ui={false} style={styles.jobImage} />
                                    </div>
                                    <View fullw column>
                                        <span style={styles.header}>{nomeImpiegato}</span>
                                        {(!role || role === 'admin') && (
                                            <Card.Description>Prezzo orario: {prezzoOrarioImpiegato}€/h</Card.Description>
                                        )}
                                        <Card.Meta style={{ textTransform: 'capitalize' }}>{pin}</Card.Meta>
                                    </View>
                                </View>
                            </Card.Content>
                            <Card.Content extra>
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, employees, gt2000dataEdit, `${idImpiegato}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            </Card.Content>
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login } = state
    let ruolo = null
    if (login && login.data && login.data.user) ruolo = login.data.user.ruolo

    return { role: ruolo }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(GT2000Data)))
