import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Card, Icon, Input, Table } from 'semantic-ui-react'
import { floatTimeToString, timeString } from '../lib/util'
import { Confirmation, ImageDefault } from '../components'

const { app, employees, babypressadataEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class BabypressaData extends Component {
    constructor(props) {
        super(props)
        let { onCostChange, babypressadata } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            ...babypressadata,
        }

        if (onCostChange && babypressadata) {
            onCostChange(this.totalCost(), babypressadata.idImpiegato)
        }
    }

    totalCost() {
        let { prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state
        let total = (oreOperaio + minutiOperaio / 60) * prezzoOrarioImpiegato
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange } = this.props
        let { idImpiegato, prezzoOrarioImpiegato } = this.state
        if (prezzoOrarioImpiegato !== prevState.prezzoOrarioImpiegato) {
            if (onCostChange) {
                onCostChange(this.totalCost(), idImpiegato)
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Impiegato</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { nomeImpiegato, prezzoOrarioImpiegato, oreOperaio, minutiOperaio } = this.state

        return (
            <Table.Row>
                <Table.Cell>{nomeImpiegato}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={prezzoOrarioImpiegato}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    prezzoOrarioImpiegato: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{prezzoOrarioImpiegato}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(oreOperaio, minutiOperaio)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    /*w
saving realtime babypressa data:  {
	qualitycmpvalues_nomeprogramma: 'C538.xml',
client_name: 'FB GROUP',
qualitycmpvalues_ciclo: 900,
qualitycmpvalues_ugello: 70,
qualitycmpvalues_totaleore: 756,
qualitycmpvalues_totalecicli: 88337,
qualitycmpvalues_temperaturaolio: 450,
qualitycmpvalues_tempocicloparziale: 0,
qualitycmpvalues_tempociclototale: 0,
actvalues_tempocicloparziale: 0,
actvalues_tempociclototale: 0,
num_ciclo_graph: 0,
conta_cicli_att: 88337,
n_cicli_iniziali_quota: 5,
n_cicli_spurgo: 6,
cycle_total: 15,
alarms_ciclo_off: 0,
alarms_ciclo_open: 0,
alarms_thermo: 0,
alarms_general: 0,
alarms_serious: 0,
program_change_cnt: 1,
state_alarms: 1,
state_terra_move: 0,
state_terra_cmd: 2048,
cmd_move: 0,
md_bordo: 256 }
*/
    renderTableHeader() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Ora</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Programma</Table.HeaderCell>
                        <Table.HeaderCell>Totale ore</Table.HeaderCell>
                        <Table.HeaderCell>Totale cicli</Table.HeaderCell>
                        <Table.HeaderCell>Allarme termico</Table.HeaderCell>
                        <Table.HeaderCell>Allarmi generali</Table.HeaderCell>
                        <Table.HeaderCell>Allarme grave</Table.HeaderCell>
                        <Table.HeaderCell>Allarmi di stato</Table.HeaderCell>
                        <Table.HeaderCell>Cont. cambio programma</Table.HeaderCell>
                        <Table.HeaderCell>Temp. Ugello</Table.HeaderCell>
                        <Table.HeaderCell>Temp. Olio</Table.HeaderCell>
                        <Table.HeaderCell>Tempo ciclo parz.</Table.HeaderCell>
                        <Table.HeaderCell>Tempo ciclo tot.</Table.HeaderCell>
                        <Table.HeaderCell>Conta cicli att.</Table.HeaderCell>
                        <Table.HeaderCell>Cicli iniziali quota</Table.HeaderCell>
                        <Table.HeaderCell>Cicli spurgo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderForBalance()
        } else {
            let {
                timestamp,
                qualitycmpvalues_nomeprogramma,
                client_name,
                qualitycmpvalues_ugello,
                qualitycmpvalues_totaleore,
                qualitycmpvalues_totalecicli,
                qualitycmpvalues_temperaturaolio,
                qualitycmpvalues_tempocicloparziale,
                qualitycmpvalues_tempociclototale,
                conta_cicli_att,
                n_cicli_iniziali_quota,
                n_cicli_spurgo,
                alarms_thermo,
                alarms_general,
                alarms_serious,
                program_change_cnt,
                state_alarms,
            } = this.props.babypressadata

            return (
                <Table.Row>
                    <Table.Cell>{moment(timestamp).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell>{moment(timestamp).format('HH:mm:ss')}</Table.Cell>
                    <Table.Cell>{client_name}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_nomeprogramma}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_totaleore}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_totalecicli}</Table.Cell>
                    <Table.Cell>{alarms_thermo}</Table.Cell>
                    <Table.Cell>{alarms_general}</Table.Cell>
                    <Table.Cell>{alarms_serious}</Table.Cell>
                    <Table.Cell>{state_alarms}</Table.Cell>
                    <Table.Cell>{program_change_cnt}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_ugello}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_temperaturaolio}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_tempocicloparziale}</Table.Cell>
                    <Table.Cell>{qualitycmpvalues_tempociclototale}</Table.Cell>
                    <Table.Cell>{conta_cicli_att}</Table.Cell>
                    <Table.Cell>{n_cicli_iniziali_quota}</Table.Cell>
                    <Table.Cell>{n_cicli_spurgo}</Table.Cell>
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, babypressadata } = this.props

        network.deleteBabypressaData(babypressadata)

        this.setState({ deleting: false })
    }

    render() {
        let { type, role } = this.props
        let { deleting, idImpiegato, nomeImpiegato, pin, immagineImpiegato, prezzoOrarioImpiegato } = this.state
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Card style={styles.jobCard} {...extraprops} color={this.state.colors[col]}>
                            <Confirmation
                                message="Vuoi davvero eliminare questo impiegato?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Card.Content style={styles.jobContent}>
                                <View fullw row>
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={immagineImpiegato} ui={false} style={styles.jobImage} />
                                    </div>
                                    <View fullw column>
                                        <span style={styles.header}>{nomeImpiegato}</span>
                                        {(!role || role === 'admin') && (
                                            <Card.Description>Prezzo orario: {prezzoOrarioImpiegato}€/h</Card.Description>
                                        )}
                                        <Card.Meta style={{ textTransform: 'capitalize' }}>{pin}</Card.Meta>
                                    </View>
                                </View>
                            </Card.Content>
                            <Card.Content extra>
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, employees, babypressadataEdit, `${idImpiegato}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            </Card.Content>
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login } = state
    let ruolo = null
    if (login && login.data && login.data.user) ruolo = login.data.user.ruolo

    return { role: ruolo }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(BabypressaData)))
