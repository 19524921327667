import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Blackboxes } from '../containers'

class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {}

    componentDidMount() {
        let { sub } = this.props

        if (!sub) {
            this.props.toolbarResult([])
        }
    }

    render() {
        return (
            <View row fullw fullh>
                <View fullw fullh>
                    <Blackboxes />
                </View>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state, ownProps) => {
    let { realtime } = state

    let data = null
    if (realtime.connected) {
        ;({ data } = realtime)
    }

    return {
        data,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Settings)))