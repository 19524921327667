import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Card, Header, Icon, Placeholder, Segment, Step } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article } from '../components'
import { Articles, Variants } from '../containers'
import { createAlert } from '../lib/util'

class AssignVariants extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
            article: {},
            variants: {},
        }
    }

    componentDidMount() {
        let { sub, network } = this.props

        if (!sub) {
            this.props.toolbarResult([])
        }

        network.getStdArticleList()
        network.getVariantList()
    }

    prev() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.max(0, step - 1) })
    }

    next() {
        let { step } = this.state

        this.clearSearch()
        this.setState({ step: Math.min(2, step + 1) })
    }

    clearSearch() {
        this.props.searchResult('')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { success, error } = this.props

        if (this.props.assigningVariants !== prevProps.assigningVariants) {
            if (!this.props.assigningVariants) {
                if (this.props.assignStatus.success) {
                    // Navigate back, success!
                    success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!'))
                    this.setState({
                        status: 'success',
                        step: 0,
                        article: {},
                        variants: {},
                    })
                } else {
                    error(createAlert('Errore del server', 'Riprova piú tardi'))
                }
            }
        }
    }

    doAssign() {
        let { article, variants } = this.state
        let { network } = this.props

        for (const i in variants) {
            const variant = variants[i]
            console.log('Assigning variant', variant, 'to article', article)
        }

        network.doAssignVariants(article, variants)
    }

    render() {
        let { step, article, variants } = this.state
        let { fetchingArticles } = this.props
        let content = <div />

        let variantCount = variants.length

        let placeholders = []
        for (let i = 0; i < 3; i++) {
            placeholders.push(
                <Card key={i} style={{ width: '100%', margin: 8 }}>
                    <Card.Content>
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Card.Content>
                </Card>
            )
        }

        content = [
            <Step.Group key="stepgroup">
                <Step active={step === 0} completed={step > 0}>
                    <Icon name="images" />
                    <Step.Content>
                        <Step.Title>Articolo</Step.Title>
                        <Step.Description>{article.articleId ? article.articleName : 'Quale articolo?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1} completed={step > 1} disabled={step < 1}>
                    <Icon name="file image" />
                    <Step.Content>
                        <Step.Title>Varianti da selezionare</Step.Title>
                        <Step.Description>{variantCount > 0 ? `Selezionate ${variantCount} varianti` : 'Quali varianti?'}</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2} completed={step > 2} disabled={step < 2}>
                    <Icon name="gem" />
                    <Step.Content>
                        <Step.Title>Immagini</Step.Title>
                        <Step.Description>Immagini specifiche per l'articolo con le varianti selezionate</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>,
            <View column fullw fullh style={{ overflow: 'hidden', marginTop: 0 }} key="stepcontent">
                {
                    {
                        0: (
                            <View column fullw fullh style={{ height: '95%' }}>
                                <Articles
                                    noActions
                                    sub
                                    searchable
                                    selectable
                                    noPagination
                                    selected={article}
                                    style={{ height: '100%', overflowY: 'scroll' }}
                                    onChange={(article) => {
                                        console.log('Steps chose article', article)
                                        this.next()
                                        const variantDict = {}
                                        for (let i in article.variants) {
                                            variantDict[article.variants[i].variantId] = article.variants[i]
                                        }
                                        this.setState({ article, variants: variantDict })
                                    }}
                                />
                            </View>
                        ),
                        1: (
                            <View column fullw fullh style={{ height: '95%' }}>
                                <Variants
                                    sub
                                    searchable
                                    selectable
                                    multiple
                                    selected={variants}
                                    noPagination
                                    noActions
                                    style={{ height: '100%', overflowY: 'scroll' }}
                                    onChange={(variants) => {
                                        console.log('Steps chose variants', variants)
                                        this.setState({ variants })
                                    }}
                                />
                            </View>
                        ),
                        2: (
                            <View column fullw fullh style={{ overflowY: 'scroll' }}>
                                <Segment.Group>
                                    <Segment>
                                        <Header>Assegnando</Header>
                                        <Variants
                                            pickArticleVariantImage
                                            article={article}
                                            sub
                                            noVirtual
                                            noPagination
                                            noActions
                                            fixedVariants={variants}
                                        />
                                    </Segment>
                                    <Segment>
                                        <Header>A questo articolo</Header>
                                        <Article type="full" noActions article={article} />
                                    </Segment>
                                </Segment.Group>
                            </View>
                        ),
                    }[step]
                }
                <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.prev()}>Indietro</Button>
                    {step < 2 && (
                        <Button
                            color="black"
                            onClick={() => this.next()}
                            disabled={(step === 0 && !article.articleId) || (step === 0 && variantCount === 0)}
                        >
                            Avanti
                        </Button>
                    )}
                    {step === 2 && (
                        <Button color="green" onClick={() => this.doAssign()}>
                            Conferma
                        </Button>
                    )}
                </View>
            </View>,
        ]


        console.log('AssignVariants render', fetchingArticles)

        return (
            <View fullw fullh column style={{ padding: 16 }}>
                {content}
            </View>
            // <View fullw fullh column style={{ padding: 16, ...(!fetchingArticles ? hiddenStyle : {}) }}>
            //     {placeholderContent}
            // </View>
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }
}

const mapStateToProps = (state) => {
    let { doAssignVariants, getStdArticleList, getVariantList, search, organization } = state

    return {
        assigningVariants: doAssignVariants.fetching,
        assignStatus: doAssignVariants.status,
        fetchingArticles: getStdArticleList.fetching,
        fetchingVariants: getVariantList.fetching,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(AssignVariants)))
