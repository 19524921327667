import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Message, TextArea } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickImage } from '../components'

class ContractEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { contract } = this.props.match.params

        if (!id) {
            id = contract
        }

        this.state = {
            contractId: id,
            contractName: '',
            contractImage: '',
        }
    }

    componentDidMount() {
        let { contractId } = this.state

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])
        // this.state.network.getContractTypeList()

        if (contractId) {
            this.state.network.getContract(contractId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        console.log('ContractEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getContract } = this.props
        if (getContract && getContract.fetching !== prevProps.getContract.fetching && !getContract.fetching) {
            if (getContract.status.success) {
                let contract = getContract.data.data
                this.setState(contract)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { contractId, contractName, contractImage } = this.state

        if (contractName === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createContract({
            contractId,
            contractName,
            contractImage,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    handleDataInput(event, contractdata) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        contractdata[name] = value
        this.forceUpdate()
    }

    renderContractData(contractdata) {
        let {
            contractDataType: { contractDataTypeKey },
            textContent,
            dateContent,
            fileContent,
        } = contractdata

        switch (contractDataTypeKey) {
            case 'text':
            default:
                return (
                    <Form.Field
                        id="form-input-control-contract-name"
                        control={TextArea}
                        label="Paragrafo di testo"
                        name="textContent"
                        value={textContent}
                        onChange={(e) => this.handleDataInput(e, contractdata)}
                        placeholder="Inserisci testo qui"
                    />
                )
            case 'milestone':
            case 'deadline':
            case 'date': // DD/MM/YYYY HH:mm:ss
                return (
                    <Form.Field
                        id="form-input-control-contract-name"
                        control={Input}
                        label="Data"
                        name="dateContent"
                        value={dateContent}
                        onChange={(e) => this.handleDataInput(e, contractdata)}
                        placeholder="Data"
                    />
                )
            case 'image':
                return [
                    <Form.Field
                        id="form-textarea-control-image"
                        control={Input}
                        label="Immagine"
                        placeholder="Immagine"
                        name="textContent"
                        value={textContent}
                        disabled
                        onChange={(e) => this.handleDataInput(e, contractdata)}
                    />,
                    <PickImage
                        onChange={(image) => {
                            contractdata['textContent'] = image
                            this.forceUpdate()
                        }}
                        value={this.state.contractImage}
                    />,
                ]
            case 'pdf':
                return (
                    <Button
                        color="red"
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'csv':
                return (
                    <Button
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'file':
                return (
                    <Button
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'excel':
                return (
                    <Button
                        color="green"
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'newline':
                return <View fullw />
        }
    }

    render() {
        let {
            // fetching,
            creating,
            contractName,
            contractImage,
            getContract,
            contractData,
        } = this.state

        console.log('ContractEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getContract.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-contract-name"
                            control={Input}
                            label="Nome Appalto"
                            name="contractName"
                            value={contractName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        {/* <Form.Field
							id="form-input-control-contract-type"
							control={Select}
							options={options}
							label="Tipo appalto"
							placeholder="Tipo appalto"
							name="contractType"
							value={contractType}
							onChange={(e, data) => {
								let id = data.value
								console.log('Tipo selected', id)
								this.setState({ contractType: id })
							}}
						/> */}
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label="Immagine"
                            placeholder="Immagine"
                            name="contractImage"
                            value={contractImage}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ contractImage: image })
                            }}
                            value={this.state.contractImage}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        {/* <Form.Field
							id="form-input-control-contract-cost"
							control={Input}
							type="number"
							label="Prezzo orario"
							placeholder="0.0"
							step="0.1"
							name="contractHourlyRate"
							value={contractHourlyRate}
							onChange={(e) => this.handleInput(e)}
						/> */}
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />

                    {contractData
                        ? contractData.map((value, index) => {
                              return this.renderContractData(value)
                          })
                        : null}

                    <Message success header="Form Completed" content="Appalto inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        // getContractTypeList: { data: { data: types }, fetching },
        createContract: { data: createResult, fetching: creating, status: createStatus },
        getContract,
    } = state

    // let options = []
    // for (let i in types) {
    // 	let type = types[i]
    // 	if (type.contractType === 1) continue // SKIP SPECIAL TYPE "NO_MACHINE"
    // 	options.push({
    // 		key: type.contractType,
    // 		text: type.typeDescription,
    // 		value: type.contractType
    // 	})
    // }

    return {
        // options,
        // types,
        // fetching,
        createResult,
        creating,
        getContract,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ContractEditor)))
