import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class StockEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let dep = props.deposit
        let { stock, deposit } = this.props.match.params
        console.log('StockEditor params match', this.props.match)
        if (!id) {
            id = stock
        }

        if (!dep) {
            dep = deposit
        }

        this.state = {
            id,
            code: id,
            deposit: dep,
        }
    }

    componentDidMount() {
        let { code, deposit } = this.state
        console.log('StockEditor code is', code, 'deposit:', deposit)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (code) {
            // TODO also needs deposit from params...
            this.state.network.getArticle(code, deposit)
        }
        this.state.network.getProductList(null, null, '')
        this.state.network.getDepositList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        console.log('JobEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getArticle } = this.props
        if (getArticle && getArticle.fetching !== prevProps.getArticle.fetching && !getArticle.fetching) {
            if (getArticle.status.success) {
                let article = getArticle.data.data
                this.setState(article)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { id, deposit, code, qty } = this.state

        if (code === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createArticle({
            id,
            id_deposit: deposit,
            code,
            qty,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, options, depOptions, deposit, code, qty, getArticle } = this.state

        console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getArticle.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        {/* <Form.Field
							id="form-input-control-article-name"
							control={Input}
							label="Codice articolo"
							name="code"
							value={code}
							onChange={(e) => this.handleInput(e)}
							placeholder=""
						/> */}
                        <Form.Field
                            id="form-input-control-article-type"
                            control={Select}
                            options={options}
                            label="Codice prodotto"
                            placeholder="Codice prodotto"
                            name="code"
                            value={code}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Tipo selected', id)
                                this.setState({ code: id })
                            }}
                        />
                        <Form.Field
                            id="form-input-control-article-type"
                            control={Select}
                            options={depOptions}
                            label="Deposito"
                            placeholder="Deposito"
                            name="deposit"
                            value={deposit}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Deposit selected', id)
                                this.setState({ deposit: id })
                            }}
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="articleImage"
							value={articleImage}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ articleImage: image });
							}}
							value={this.state.articleImage}
						/>
					</Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-article-cost"
                            control={Input}
                            type="number"
                            label="Quantita'"
                            placeholder="0"
                            step="1"
                            name="qty"
                            value={qty}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getDepositList: {
            data: { deposit: deposits },
            fetching: fetchDeposit,
        },
        getProductList: {
            data: { product: products },
            fetching: fetchProduct,
        },
        createArticle: { data: createResult, fetching: creating, status: createStatus },
        getArticle,
    } = state

    console.log('Deposits are', deposits)

    let options = []
    for (let i in products) {
        let type = products[i]
        options.push({
            key: type.code,
            text: type.description,
            value: type.code,
        })
    }

    let depOptions = []
    for (let i in deposits) {
        let type = deposits[i]
        depOptions.push({
            key: type.id,
            text: type.name,
            value: type.id,
        })
    }

    return {
        depOptions,
        options,
        fetchDeposit,
        fetchProduct,
        createResult,
        creating,
        getArticle,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(StockEditor)))
