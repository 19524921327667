export function getGT2000DataFilter(data) {
    let { getGT2000DataFilterRequest, getGT2000DataFilterError, getGT2000DataFilterResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_gt2000_data_filter',
        this.createBundle(
            'get_gt2000_data_filter',
            { search: data },
            this.GET,
            getGT2000DataFilterRequest,
            getGT2000DataFilterResult,
            getGT2000DataFilterError
        )
    )
}
