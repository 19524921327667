export function deleteOrganization(data) {
    let { deleteOrganizationRequest, deleteOrganizationError, deleteOrganizationResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'delete_organization',
        this.createBundle(
            'delete_organization',
            { organization: data },
            this.GET,
            deleteOrganizationRequest,
            deleteOrganizationResult,
            deleteOrganizationError
        )
    )
}
