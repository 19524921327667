import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Placeholder } from 'semantic-ui-react'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { BabypressaData, CustomTable } from '../components'

moment.locale('it')

class BabyPressa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            start: moment().subtract(1, 'day'),
            end: moment(),
        }
    }

    prev() {
        // todo moment subtract
    }

    next() {
        // todo moment add
    }

    componentDidMount() {
        let { sub, network } = this.props
        if (!sub) {
            this.props.toolbarResult([])
        }

        let start = this.state.start.format('YYYY-MM-DD HH:mm:ss')
        let end = this.state.end.format('YYYY-MM-DD HH:mm:ss')

        console.log('Start and end are:', start, end)

        network.getLastBabypressaData()
        network.getBabypressaData(start, end)
    }

    renderData(key, babypressadata) {
        return (
            <View fullw type="full" key={`babypressadata_${key}`}>
                {babypressadata.timestamp}
            </View>
        )
    }

    render() {
        let { fetching, babypressadatalist, getLastBabypressaData, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (babypressadatalist.data) {
            console.log('babypressadatalist is', babypressadatalist.data)

            content = <CustomTable name="BabypressaData" shorthand="babypressadata" values={babypressadatalist.data} child={BabypressaData} />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getLastBabypressaData, getBabypressaData } = state
    return {
        getLastBabypressaData,
        fetching: getBabypressaData.fetching,
        babypressadatalist: getBabypressaData.data,
        getBabypressaData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(BabyPressa)))
