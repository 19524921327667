export function deleteStdArticle(data) {
	let { deleteStdArticleRequest, deleteStdArticleError, deleteStdArticleResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_article',
		this.createBundle(
			'delete_article',
			{ stdarticle: data },
			this.POST,
			deleteStdArticleRequest,
			deleteStdArticleResult,
			deleteStdArticleError
		)
	);
}
