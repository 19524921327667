export function getArticleVariantImageList(articleId, variantId) {
	let { getArticleVariantImageListRequest, getArticleVariantImageListError, getArticleVariantImageListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_article_variant_image_list',
		this.createBundle(
			'get_article_variant_image_list',
			{ articleId, variantId },
			this.GET,
			getArticleVariantImageListRequest,
			getArticleVariantImageListResult,
			getArticleVariantImageListError
		)
	);
}
