import {} from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'moment-with-locales-es6'
import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Accordion, Button, Card, Icon, Image, Input, Loader, Table, Transition } from 'semantic-ui-react'
import { Default, Mobile, timeString } from '../lib/util'
import { Confirmation } from '../components'

const { app, contracts, contractEdit } = routes

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

const accordionInfo = [
    { key: 'panel-1a', title: 'Dimensions', content: 'details on dimension' },
    { key: 'panel-ba', title: 'Minimum', content: 'minimum distance' },
]

const accordion = [
    {
        key: 'example',
        title: {
            content: 'type of request',
            icon: 'expand',
        },
        content: {
            content: (
                <div>
                    Some additional info
                    <Accordion.Accordion panels={accordionInfo} />
                </div>
            ),
        },
    },
]

class Contract extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, contract } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...contract,
        }

        if (onCostChange && contract) {
            onCostChange(this.totalCost(), contract.contractId)
        }
    }

    totalCost() {
        let { contractHourlyRate, contractHours, contractMinutes } = this.state
        let total = (contractHours + contractMinutes / 60) * parseFloat(contractHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { contract, contractId, network } = this.props

        if ((!contract || !contract.contractId) && contractId) {
            network.getContract(contractId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getContract, login } = this.props
        let { contractId, contractHourlyRate } = this.state
        if (contractHourlyRate !== prevState.contractHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), contractId)
            }
        }

        console.log('Contract getContract fetching', getContract.fetching)
        if (getContract && getContract.fetching !== prevProps.getContract.fetching && !getContract.fetching) {
            console.log('Done calling getContract')
            if (getContract.status.success) {
                console.log(getContract.data)
                let contract = getContract.data.data

                this.setState({ ...contract })
                console.log('Calling Get contract', login, contract)
                return
            } else {
                console.log('calling No contract found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Cell 1</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Cell 2:
                </Table.Cell>
                <Table.Cell width="4">Cell 3</Table.Cell>
                <Table.Cell width="1">Cell 4:</Table.Cell>
                <Table.Cell width="2">Cell 5</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Field 1</Table.HeaderCell>
                    <Table.HeaderCell width="4">Field 2</Table.HeaderCell>
                    <Table.HeaderCell width="4">Field 3</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { contractName, contractHourlyRate, contractHours, contractMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{contractName}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={contractHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    contractHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{contractHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(contractHours, contractMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideContract, hideContractHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideContract && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideContractHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideContract, hideContractHours, isBalance } = this.props
        let { contractName, contractHours, contractMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideContract && <Table.Cell>{contractName}</Table.Cell>}
                    {!hideContractHours && <Table.Cell>{timeString(contractHours, contractMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, contract } = this.props

        network.deleteContract(contract)

        this.setState({ deleting: false })
    }

    renderContractData(contractdata, index) {
        let {
            contractDataType: { contractDataTypeKey },
            textContent,
            dateContent,
            fileContent,
        } = contractdata

        switch (contractDataTypeKey) {
            case 'text':
            default:
                let paragraphs = [textContent]
                if (textContent) {
                    paragraphs = textContent.split('\n')
                }
                return (
                    <>
                        {paragraphs.map((text, index) => {
                            return (
                                <View fullw>
                                    <Card.Description key={index}>{text}</Card.Description>
                                </View>
                            )
                        })}
                    </>
                )
            case 'milestone':
            case 'deadline':
            case 'date': // DD/MM/YYYY HH:mm:ss
                return (
                    <View fullw>
                        <Card.Description key={index}>
                            {textContent}: {moment(dateContent).format('LLLL')}
                        </Card.Description>
                    </View>
                )
            case 'image':
                return (
                    <View fullw around>
                        {/* <ImageDefault
						fromRoot
						src={fileContent}
						ui={false}
						style={styles.contractImage}
					/>, */}
                        <Image floated="left" size="mini" src="https://react.semantic-ui.com/images/avatar/large/steve.jpg" />,
                        <Card.Description key={index}>{textContent}</Card.Description>
                    </View>
                )
            case 'pdf':
                return (
                    <Button
                        color="red"
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'csv':
                return (
                    <Button
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'file':
                return (
                    <Button
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'excel':
                return (
                    <Button
                        color="green"
                        onClick={() => {
                            window.location(fileContent)
                        }}
                    >
                        <Icon name="download" />
                        {textContent}
                    </Button>
                )
            case 'newline':
                return <View fullw />
        }
    }

    renderCardContent(direction) {
        let { type } = this.props

        let { contractDescription, contractOwner } = this.state

        let organizationName = ''
        if (contractOwner) {
            organizationName = contractOwner.organizationName
        }

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        console.log('Extra view props:', direction, extraViewProps)

        return (
            <View fullw {...extraViewProps}>
                <Card.Content style={{ width: '100%' }}>
                    {/* <ImageDefault
				// fromRoot
				// src={contractImage}
				ui={false}
				// style={styles.contractImage}
				floated='right'
				size='mini'
				src='https://react.semantic-ui.com/images/avatar/large/steve.jpg'
			/> */}
                    <Image floated="right" size="mini" src="https://react.semantic-ui.com/images/avatar/large/steve.jpg" />
                    <Card.Header>Azienda {organizationName}</Card.Header>
                    <Card.Meta>Produzione materassini</Card.Meta>
                    <Card.Description>{contractDescription}</Card.Description>
                    <Accordion panels={accordion} />
                </Card.Content>
            </View>
        )

        // return (<View fullw {...extraViewProps}>
        // 	<div style={styles.imageContainer}>
        // 		<ImageDefault
        // 			fromRoot
        // 			src={contractImage}
        // 			ui={false}
        // 			style={styles.contractImage}
        // 		/>
        // 	</div>
        // 	<View fullw column>
        // 		<span style={styles.header}>{contractName}</span>
        // 		{!noActions &&
        // 			(!role || role === 'admin') && (
        // 				<Card.Description>
        // 					{contractDescription}
        // 				</Card.Description>
        // 			)}
        // 	</View>
        // </View>)
    }

    render() {
        let { login, type, contract, onClick, selected, noActions, onOpened, forcedOpen } = this.props

        let { deleting, contractId, contractName, contractImage, contractData, contractStatus } = this.state

        console.log('Contract status is', contractStatus)

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(contract)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Card className={selected ? 'rowSelected' : ''} style={styles.contractCard} {...extraprops} color={this.state.colors[col]}>
                            <Confirmation
                                message="Vuoi davvero eliminare questo appalto?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Card.Content style={styles.contractContent}>
                                <Default>{this.renderCardContent()}</Default>
                                <Mobile>{this.renderCardContent('column')}</Mobile>
                            </Card.Content>
                            {!forcedOpen && !noActions && (
                                <Card.Content extra>
                                    {!forcedOpen && (
                                        <Button
                                            onClick={() => {
                                                if (onOpened) {
                                                    onOpened({ contractId, contractName, contractImage }, true, () => {
                                                        this.props.history.push(route_from(app, contracts, `${contractId}`))
                                                    })
                                                } else {
                                                    this.props.history.push(route_from(app, contracts, `${contractId}`))
                                                }
                                            }}
                                        >
                                            <Icon name="folder open" />
                                            <Default>Apri</Default>
                                        </Button>
                                    )}
                                    {contractStatus === 'received' && (
                                        <Button basic floated="right" color="green">
                                            Approve
                                        </Button>
                                    )}
                                    {contractStatus === 'received' && (
                                        <Button basic floated="right" color="red">
                                            Decline
                                        </Button>
                                    )}
                                    {user && !!user.manage_contract && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, contracts, contractEdit, `${contractId}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            <Default>Modifica</Default>
                                        </Button>
                                    )}

                                    {user && !!user.manage_contract && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            <Default>Elimina</Default>
                                        </Button>
                                    )}
                                    {contractStatus === 'sent' && (
                                        <Loader active inline size="small" style={{ float: 'right' }}>
                                            {' '}
                                            In attesa di approvazione
                                        </Loader>
                                    )}
                                </Card.Content>
                            )}
                            {type === 'full' && forcedOpen && (
                                <Transition visible={forcedOpen ? true : this.state.showDetails} animation="slide down" duration={200}>
                                    <Card.Content>
                                        <View fullw column>
                                            {/* {this.renderControls()} */}
                                            {/* {this.renderCharts()} */}
                                            {/* {this.renderDataTable()} */}
                                            <View fullw row around wrap>
                                                {contractData
                                                    ? contractData.map((value, index) => {
                                                          return this.renderContractData(value, index)
                                                      })
                                                    : null}
                                            </View>
                                        </View>
                                    </Card.Content>
                                </Transition>
                            )}
                        </Card>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 200,
        height: 150,
        marginRight: 28,
    },
    contractImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    contractContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contractCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getContract } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getContract, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Contract)))
