export function doAssignVariants(article, variants) {
	let { doAssignVariantsRequest, doAssignVariantsError, doAssignVariantsResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'do_assign_variants',
		this.createBundle(
			'do_assign_variants',
			{ article, variants },
			this.POST,
			doAssignVariantsRequest,
			doAssignVariantsResult,
			doAssignVariantsError
		)
	);
}
