export function deleteArticleVariant(articleId, variantId) {
	let { deleteArticleVariantRequest, deleteArticleVariantError, deleteArticleVariantResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_article_variant',
		this.createBundle(
			'delete_article_variant',
			{ articleId, variantId },
			this.POST,
			deleteArticleVariantRequest,
			deleteArticleVariantResult,
			deleteArticleVariantError
		)
	);
}
