import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Header, Icon, Input, Message, Segment, Select, TextArea } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article, PickMultipleImage, Variant } from '../components'
import { createAlert } from '../lib/util'

class ArticleEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { article } = this.props.match.params

        if (!id) {
            id = article
        }

        console.log('ArticleEditor params match', this.props.match, id)

        this.state = {
            id,
            articleImages: [],
            articleVariantImages: [],
            variants: [],
            articleVariantToRemove: {},
            childToRemove: {},
            removeParent: false
        }
    }

    componentDidMount() {
        let { id } = this.state
        console.log('ArticleEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getStdArticle(id)
        }
        this.props.network.getVariantList('')
        this.props.network.getFamilyList('')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('ArticleEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.props.error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getStdArticle } = this.props
        if (getStdArticle && getStdArticle.fetching !== prevProps.getStdArticle.fetching && !getStdArticle.fetching) {
            if (getStdArticle.status.success) {
                let article = getStdArticle.data.data
                console.log('Setting state with article:', article)
                let { id, ...rest } = article

                const articleVariantToRemove = {}
                for (const key in article.variants) {
                    articleVariantToRemove[key] = false
                }

                const childToRemove = {}
                for (const key in article.children) {
                    childToRemove[key] = false
                }

                this.setState({ ...rest, articleVariantToRemove, childToRemove })
            }
        }
    }

    save() {
        // TODO value checking
        const {
            articleId,
            articleName,
            articleCode,
            articleSize,
            articleState,
            articleDescription,
            articleDescriptionEng,
            articleOrdering,
            articleImageIndexToPick,
            articleImages,
            showDropdown,
            dropdownName,
            dropdownNameEng,
            familyId,
            variants,
            articleVariantToRemove,
            childToRemove,
            parentArticleId,
            removeParent,
            children,
        } = this.state
        const { variantMap, variantOptions, network } = this.props

        for (const key in articleVariantToRemove) {
            if (articleVariantToRemove[key]) {
                const toRemove = variants[key].variantId
                network.deleteArticleVariant(this.state.articleId, variants[key].variantId)
            }
        }

        for (const key in childToRemove) {
            if (childToRemove[key]) {
                const toRemove = children[key]
                network.clearParent(children[key])
            }
        }

        network.createStdArticle({
            articleId,
            articleName,
            articleCode,
            articleSize,
            articleState,
            articleDescription,
            articleDescriptionEng,
            articleOrdering,
            articleImageIndexToPick,
            articleImages,
            showDropdown,
            dropdownName,
            dropdownNameEng,
            parentArticleId: removeParent || !parentArticleId ? null : parentArticleId,
            // variants,
            familyId,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    renderVariant(key, variant, style) {
        if (!variant) return null

        return (
            <div style={style}>
                <Variant noActions type="full" key={`variant_${key}`} variant={variant} />
            </div>
        )
    }

    renderArticle(key, article, style) {
        if (!article) return null

        return (
            <div style={style}>
                <Article noActions type="full" key={`article_${key}`} article={article} />
            </div>
        )
    }

    render() {
        let {
            creating,
            articleId,
            articleCode,
            familyId,
            articleName,
            articleSize,
            articleState,
            articleDescription,
            articleDescriptionEng,
            articleImages,
            articleOrdering,
            articleImageIndexToPick,
            showDropdown,
            dropdownName,
            dropdownNameEng,
            variants,
            children,
            articleVariantImages,
            parentArticleId,
            parent,
        } = this.state

        let { network, variantList, getStdArticle, collectionOptions, familyOptions, variantOptions, variantMap, login } = this.props

        console.log('ArticleEditor variants:', variants)
        console.log('ArticleEditor variant options:', variantOptions)
        console.log('ArticleEditor variant map:', variantMap)

        let variantContent = []
        let childrenContent = []

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        if (variants) {
            const variantsKeyArray = Object.keys(variants)
            variantContent = variantsKeyArray.map((value, index) => {
                return (
                    <View column fullw fullh style={{ marginBottom: 16 }}>
                        {this.renderVariant(index, variants[value], {})}
                        <View row fullw>
                            {user &&
                                !!user.manage_variant && [
                                    <View fullw />,
                                    this.state.articleVariantToRemove[value] ? (
                                        <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                            La variante {variants[value].variantName} verrà disassociata
                                        </Header>
                                    ) : (
                                        <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                            La variante {variants[value].variantName} resterà associata
                                        </Header>
                                    ),
                                    <Button
                                        style={{
                                            position: 'relative',
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 3,
                                        }}
                                        color="red"
                                        onClick={(e) => {
                                            const { articleVariantToRemove } = this.state
                                            articleVariantToRemove[value] = !articleVariantToRemove[value]
                                            this.setState({ articleVariantToRemove })
                                        }}
                                    >
                                        <Icon name="trash" />
                                    </Button>,
                                ]}
                        </View>
                    </View>
                )
            })
        }

        if (children) {
            const childrenKeyArray = Object.keys(children)
            childrenContent = childrenKeyArray.map((value, index) => {
                return (
                    <View column fullw fullh style={{ marginBottom: 16 }}>
                        {this.renderArticle(index, children[value], {})}
                        <View row fullw>
                            {user &&
                                !!user.manage_article && [
                                    <View fullw />,
                                    this.state.childToRemove[value] ? (
                                        <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                            L'articolo {children[value].variantName} verrà disassociato
                                        </Header>
                                    ) : (
                                        <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                            L'articolo {children[value].variantName} resterà associato
                                        </Header>
                                    ),
                                    <Button
                                        style={{
                                            position: 'relative',
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 3,
                                        }}
                                        color="red"
                                        onClick={(e) => {
                                            const { childToRemove } = this.state
                                            childToRemove[value] = !childToRemove[value]
                                            this.setState({ childToRemove })
                                        }}
                                    >
                                        <Icon name="trash" />
                                        Elimina
                                    </Button>,
                                ]}
                        </View>
                    </View>
                )
            })
        }

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getStdArticle.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Segment>
                        <Header as="h1">Anagrafica articolo {parentArticleId ? `(Duplicato di ${parentArticleId})` : ''}</Header>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-name"
                                control={Input}
                                label="Nome articolo"
                                name="articleName"
                                value={articleName}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-code"
                                control={Input}
                                label="Codice articolo"
                                name="articleCode"
                                value={articleCode}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-size"
                                control={Input}
                                label="Dimensione articolo"
                                name="articleSize"
                                value={articleSize}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-name"
                                control={TextArea}
                                label="Descrizione articolo"
                                name="articleDescription"
                                value={articleDescription}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-name"
                                control={TextArea}
                                label="Descrizione articolo inglese"
                                name="articleDescriptionEng"
                                value={articleDescriptionEng}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-type"
                                control={Select}
                                options={familyOptions}
                                label="Famiglia di cui fa parte l'articolo"
                                placeholder="Famiglia di cui fa parte l'articolo"
                                name="familyId"
                                value={familyId}
                                onChange={(e, data) => {
                                    let id = data.value
                                    console.log('Family selected', id)
                                    this.setState({ familyId: id })
                                }}
                            />
                        </Form.Group>
                        <View fullw around>
                            <View fullw around>
                                <Checkbox
                                    style={{ marginTop: 10 }}
                                    toggle
                                    label="Nuovo"
                                    checked={articleState === 1}
                                    onChange={(e, data) => {
                                        this.setState({ articleState: data.checked ? 1 : 0 })
                                    }}
                                />
                            </View>
                            <View fullw around style={{ minHeight: 52 }}>
                                <Checkbox
                                    style={{ marginTop: 10 }}
                                    toggle
                                    label="Mostra menù a tendina su scheda articolo"
                                    checked={showDropdown === 1}
                                    onChange={(e, data) => {
                                        this.setState({ showDropdown: data.checked ? 1 : 0 })
                                    }}
                                />
                            </View>
                        </View>
                        {showDropdown === 1 && (
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-article-dropdown-name"
                                    control={Input}
                                    label="Titolo menù a tendina"
                                    name="dropdownName"
                                    value={dropdownName}
                                    onChange={(e) => this.handleInput(e)}
                                    placeholder=""
                                />
                                <Form.Field
                                    id="form-input-control-article-dropdown-name"
                                    control={Input}
                                    label="Titolo menù a tendina inglese"
                                    name="dropdownNameEng"
                                    value={dropdownNameEng}
                                    onChange={(e) => this.handleInput(e)}
                                    placeholder=""
                                />
                            </Form.Group>
                        )}
                    </Segment>
                    <Segment>
                        <Header as="h1">Immagine articolo (solo una sarà visualizzata nella galleria)</Header>
                        <PickMultipleImage
                            onImageClick={(image, index) => {
                                this.setState({ articleImageIndexToPick: index })
                            }}
                            selectedIndex={this.state.articleImageIndexToPick}
                            onChange={(images) => {
                                this.setState({ articleImages: images, articleImageIndexToPick: 0 })
                            }}
                            value={articleImages}
                        />
                    </Segment>

                    {parent && (
                        <Segment>
                            <Header as="h1">Articolo duplicato di {parent.articleCode}</Header>
                            <View column fullw fullh style={{ marginBottom: 16 }}>
                                {this.renderArticle('parent_article', parent, {})}
                                <View row fullw>
                                    {user &&
                                        !!user.manage_article && [
                                            <View fullw />,
                                            this.state.removeParent ? (
                                                <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                                    L'articolo {parent.variantName} verrà disassociato
                                                </Header>
                                            ) : (
                                                <Header as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                                    L'articolo {parent.variantName} resterà associato
                                                </Header>
                                            ),
                                            <Button
                                                style={{
                                                    position: 'relative',
                                                    right: 0,
                                                    bottom: 0,
                                                    zIndex: 3,
                                                }}
                                                color="red"
                                                onClick={(e) => {
                                                    let { removeParent } = this.state
                                                    removeParent = !removeParent
                                                    this.setState({ removeParent })
                                                }}
                                            >
                                                <Icon name="trash" />
                                                Elimina
                                            </Button>,
                                        ]}
                                </View>
                            </View>
                        </Segment>
                    )}

                    {childrenContent && childrenContent.length > 0 && (
                        <Segment>
                            <Header as="h1">Articoli duplicati associati a questo articolo</Header>
                            {childrenContent}
                        </Segment>
                    )}

                    {variantContent && variantContent.length > 0 && (
                        <Segment>
                            <Header as="h1">Varianti associate a questo articolo</Header>
                            {variantContent}
                        </Segment>
                    )}

                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        login,
        getVariantList: {
            data: { data: variants },
            fetching: fetchVariant,
        },
        getFamilyList: {
            data: { data: families },
            fetching: fetchFamily,
        },
        createStdArticle: { data: createResult, fetching: creating, status: createStatus },
        getStdArticle,
    } = state

    let variantMap = {}
    let variantOptions = []
    for (let i in variants) {
        let type = variants[i]
        variantMap[variants[i].variantId] = type
        variantOptions.push({
            key: type.variantId,
            text: type.variantName,
            value: type,
        })
    }

    let familyOptions = []
    for (let i in families) {
        let type = families[i]
        familyOptions.push({
            key: type.familyId,
            text: type.collectionName + ': ' + type.familyName,
            value: type.familyId,
        })
    }

    return {
        login,
        variantMap,
        variantList: variants,
        variantOptions,
        familyOptions,
        fetchFamily,
        fetchVariant,
        createResult,
        creating,
        getStdArticle,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ArticleEditor)))
