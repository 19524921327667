export function uploadFile(file, type) {
    let { uploadFileRequest, uploadFileError, uploadFileResult } = this.props

    console.log('Uploading file:', file)
    const formData = new FormData()
    formData.append('file', file)

    for (var key of formData.entries()) {
        console.log(key[0] + ', ' + key[1])
	}
	
	let route = 'upload'

	if(type === 'file') {
		route = 'upload_file'
	} else if(type === 'archive') {
		route = 'upload_archive'
	}

    let bundle = this.createBundle(route, {}, this.POST, uploadFileRequest, uploadFileResult, uploadFileError)
    bundle.data = formData
    bundle.data.ACTION = route
    bundle.noStringify = true
    //bundle.noAuth = true;
    bundle.contenttype = 'multipart/form-data'

    this.doAction(this.WEB_REQUEST_URL + route, bundle)
}

/*
export function uploadFile(file, callback) {
	console.log('Uploading file:', file);
	const formData = new FormData();
	formData.append('file', file); //event.target.files[0] data going here
	let { uploadFileRequest, uploadFileError, uploadFileResult } = this.props;

	if (formData.entries) {
		for (var key of formData.entries()) {
			console.dir(key[0]);
			console.dir(key[1]);
		}
	}
	
	fetch(this.WEB_REQUEST_URL + 'upload', {
		// Your POST endpoint
		headers: {
			'Content-Type': "multipart/form-data"
		},
		method: 'POST',
		body: formData // This is your file object
	})
		.then(
			(response) => response.json() // if the response is a JSON object
		)
		.then(
			(success) => uploadFileResult(success) // Handle the success response object
		)
		.catch(
			(error) => console.log(error) // Handle the error response object
		);
}*/
