import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withCookies } from 'react-cookie'

import * as requests from './requests'

/* @actions */
import { GETLABELLIST_ACTIONS } from '../actions/getLabelList'
import { GETLABEL_ACTIONS } from '../actions/getLabel'
import { CREATELABEL_ACTIONS } from '../actions/createLabel'
import { DELETELABEL_ACTIONS } from '../actions/deleteLabel'
import { FILTERS_ACTIONS } from '../actions/filters'
import { RUNREVERSEGEOCODING_ACTIONS } from '../actions/runReverseGeocoding'
import { CLEARPARENT_ACTIONS } from '../actions/clearParent'
import { DELETEARTICLEVARIANT_ACTIONS } from '../actions/deleteArticleVariant'
import { GETDATAIMPORTLIST_ACTIONS } from '../actions/getDataImportList'
import { DOASSIGNDUPLICATES_ACTIONS } from '../actions/doAssignDuplicates'
import { SETARTICLEVARIANTORDERING_ACTIONS } from '../actions/setArticleVariantOrdering'
import { DOASSIGNVARIANTS_ACTIONS } from '../actions/doAssignVariants'
import { DELETEOFFICE_ACTIONS } from '../actions/deleteOffice'
import { CREATEOFFICE_ACTIONS } from '../actions/createOffice'
import { GETOFFICELIST_ACTIONS } from '../actions/getOfficeList'
import { GETOFFICE_ACTIONS } from '../actions/getOffice'
import { GETARTICLEVARIANTIMAGELIST_ACTIONS } from '../actions/getArticleVariantImageList'
import { GETCOLLECTIONSFULL_ACTIONS } from '../actions/getCollectionsFull'
import { UPLOADMULTIPLEFILES_ACTIONS } from '../actions/uploadMultipleFiles'
import { DELETEPICTURE_ACTIONS } from '../actions/deletePicture'
import { CREATEPICTURE_ACTIONS } from '../actions/createPicture'
import { GETPICTURELIST_ACTIONS } from '../actions/getPictureList'
import { GETPICTURE_ACTIONS } from '../actions/getPicture'
import { DELETESTDARTICLE_ACTIONS } from '../actions/deleteStdArticle'
import { CREATESTDARTICLE_ACTIONS } from '../actions/createStdArticle'
import { GETSTDARTICLELIST_ACTIONS } from '../actions/getStdArticleList'
import { GETSTDARTICLE_ACTIONS } from '../actions/getStdArticle'
import { DELETEVARIANT_ACTIONS } from '../actions/deleteVariant'
import { CREATEVARIANT_ACTIONS } from '../actions/createVariant'
import { GETVARIANTLIST_ACTIONS } from '../actions/getVariantList'
import { GETVARIANT_ACTIONS } from '../actions/getVariant'
import { DELETEFAMILY_ACTIONS } from '../actions/deleteFamily'
import { CREATEFAMILY_ACTIONS } from '../actions/createFamily'
import { GETFAMILYLIST_ACTIONS } from '../actions/getFamilyList'
import { GETFAMILY_ACTIONS } from '../actions/getFamily'
import { DELETECOLLECTION_ACTIONS } from '../actions/deleteCollection'
import { CREATECOLLECTION_ACTIONS } from '../actions/createCollection'
import { GETCOLLECTION_ACTIONS } from '../actions/getCollection'
import { GETCOLLECTIONLIST_ACTIONS } from '../actions/getCollectionList'
import { GETURGENTJOBLIST_ACTIONS } from '../actions/getUrgentJobList'
import { DELETEORGANIZATION_ACTIONS } from '../actions/deleteOrganization'
import { CREATEORGANIZATION_ACTIONS } from '../actions/createOrganization'
import { GETORGANIZATIONLIST_ACTIONS } from '../actions/getOrganizationList'
import { GETORGANIZATION_ACTIONS } from '../actions/getOrganization'
import { DELETECONTRACT_ACTIONS } from '../actions/deleteContract'
import { CREATECONTRACT_ACTIONS } from '../actions/createContract'
import { GETCONTRACTLIST_ACTIONS } from '../actions/getContractList'
import { GETCONTRACT_ACTIONS } from '../actions/getContract'
import { SENDNOTIFICATION_ACTIONS } from '../actions/sendNotification'
import { GETARTICLERECAP_ACTIONS } from '../actions/getArticleRecap'
import { GETPRODUCTRECAP_ACTIONS } from '../actions/getProductRecap'
import { GETDOCUMENTRECAP_ACTIONS } from '../actions/getDocumentRecap'
import { DELETEDEPOSIT_ACTIONS } from '../actions/deleteDeposit'
import { CREATEDEPOSIT_ACTIONS } from '../actions/createDeposit'
import { GETDEPOSIT_ACTIONS } from '../actions/getDeposit'
import { GETCATEGORY_ACTIONS } from '../actions/getCategory'
import { GETCORP_ACTIONS } from '../actions/getCorp'
import { DELETECORP_ACTIONS } from '../actions/deleteCorp'
import { CREATECORP_ACTIONS } from '../actions/createCorp'
import { DELETECATEGORY_ACTIONS } from '../actions/deleteCategory'
import { CREATECATEGORY_ACTIONS } from '../actions/createCategory'
import { DELETEUSER_ACTIONS } from '../actions/deleteUser'
import { CREATEPRODUCT_ACTIONS } from '../actions/createProduct'
import { GETCORPLIST_ACTIONS } from '../actions/getCorpList'
import { GETCATEGORYLIST_ACTIONS } from '../actions/getCategoryList'
import { GETPRODUCT_ACTIONS } from '../actions/getProduct'
import { DELETEPRODUCT_ACTIONS } from '../actions/deleteProduct'
import { GETPRODUCTLIST_ACTIONS } from '../actions/getProductList'
import { GETDEPOSITLIST_ACTIONS } from '../actions/getDepositList'
import { GETDOCUMENT_ACTIONS } from '../actions/getDocument'
import { DELETEDOCUMENT_ACTIONS } from '../actions/deleteDocument'
import { CREATEDOCUMENT_ACTIONS } from '../actions/createDocument'
import { GETDOCUMENTLIST_ACTIONS } from '../actions/getDocumentList'
import { GETARTICLELIST_ACTIONS } from '../actions/getArticleList'
import { GETARTICLE_ACTIONS } from '../actions/getArticle'
import { DELETEARTICLE_ACTIONS } from '../actions/deleteArticle'
import { CREATEARTICLE_ACTIONS } from '../actions/createArticle'
import { GETTURN_ACTIONS } from '../actions/getTurn'
import { DELETETURN_ACTIONS } from '../actions/deleteTurn'
import { CREATETURN_ACTIONS } from '../actions/createTurn'
import { GETTURNLIST_ACTIONS } from '../actions/getTurnList'
import { GETROLELIST_ACTIONS } from '../actions/getRoleList'
import { GETUSER_ACTIONS } from '../actions/getUser'
import { CHANGEPASSWORD_ACTIONS } from '../actions/changePassword'
import { CREATEUSER_ACTIONS } from '../actions/createUser'
import { GETUSERLIST_ACTIONS } from '../actions/getUserList'
import { ORGANIZATION_ACTIONS } from '../actions/organization'
import { GETFROM_ACTIONS, GETFROM_DERIVED_ACTIONS, HADESTOKEN_ACTIONS } from '../actions/hades'
import { RECAP_ACTIONS } from '../actions/recap'
import { GETRECENTJOBLIST_ACTIONS } from '../actions/getRecentJobList'
import { GETRECENTMACHINELIST_ACTIONS } from '../actions/getRecentMachineList'
import { DELETEWORKLIST_ACTIONS } from '../actions/deleteWorkList'
import { SAVESERVICE_ACTIONS } from '../actions/saveService'
import { DELETEBLACKBOX_ACTIONS } from '../actions/deleteBlackbox'
import { GETBLACKBOXLIST_ACTIONS } from '../actions/getBlackboxList'
import { REALTIME_ACTIONS } from '../actions/realtime'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'
import { DELETEMAPPING_ACTIONS } from '../actions/deleteMapping'
import { CREATEMAPPING_ACTIONS } from '../actions/createMapping'
import { GETMAPPINGDATA_ACTIONS } from '../actions/getMappingData'
import { GETBABYPRESSADATAFILTER_ACTIONS } from '../actions/getBabypressaDataFilter'
import { GETGT2000DATAFILTER_ACTIONS } from '../actions/getGT2000DataFilter'
import { GETLASTGT2000DATA_ACTIONS } from '../actions/getLastGT2000Data'
import { GETGT2000DATA_ACTIONS } from '../actions/getGT2000Data'
import { GETLASTSWA300DATA_ACTIONS } from '../actions/getLastSWA300Data'
import { GETLASTBABYPRESSADATA_ACTIONS } from '../actions/getLastBabypressaData'
import { GETBABYPRESSADATA_ACTIONS } from '../actions/getBabypressaData'
import { GETSWA300DATA_ACTIONS } from '../actions/getSWA300Data'
import { WRITESWA300CONFIG_ACTIONS } from '../actions/writeSWA300Config'
import { UPLOADIMAGE_ACTIONS } from '../actions/uploadImage'
import { CREATEITEM_ACTIONS } from '../actions/createItem'
import { DELETEITEM_ACTIONS } from '../actions/deleteItem'
import { GETITEMLIST_ACTIONS } from '../actions/getItemList'
import { MERGEJOB_ACTIONS } from '../actions/mergeJob'
import { GETCOST_ACTIONS } from '../actions/getCost'
import { GETWORKER_ACTIONS } from '../actions/getWorker'
import { GETMACHINE_ACTIONS } from '../actions/getMachine'
import { GETJOB_ACTIONS } from '../actions/getJob'
import { GETWORKLISTFORDATE_ACTIONS } from '../actions/getWorkListForDate'
import { GETWORKLISTFORDAE_ACTIONS } from '../actions/getWorkListForDae'
import { GETWORKLISTFORDAY_ACTIONS } from '../actions/getWorkListForDay'
import { WORKDAY_ACTIONS } from '../actions/workday'
import { ASSIGNWORK_ACTIONS } from '../actions/assignWork'
import { UPLOADFILE_ACTIONS } from '../actions/uploadFile'
import { GETIMAGELIST_ACTIONS } from '../actions/getImageList'
import { GETJOBTYPELIST_ACTIONS } from '../actions/getJobTypeList'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { GETDETAILEDJOBLIST_ACTIONS } from '../actions/getDetailedJobList'
import { GETPAGEDJOBLIST_ACTIONS } from '../actions/getPagedJobList'
import { GETJOBLIST_ACTIONS } from '../actions/getJobList'
import { GETWORKERLIST_ACTIONS } from '../actions/getWorkerList'
import { GETITEMCOSTS_ACTIONS } from '../actions/getItemCosts'
import { DELETEWORKER_ACTIONS } from '../actions/deleteWorker'
import { UPDATEWORKER_ACTIONS } from '../actions/updateWorker'
import { CREATEWORKER_ACTIONS } from '../actions/createWorker'
import { GETPAGEDCOSTLIST_ACTIONS } from '../actions/getPagedCostList'
import { GETCOSTLIST_ACTIONS } from '../actions/getCostList'
import { DELETECOST_ACTIONS } from '../actions/deleteCost'
import { UPDATECOST_ACTIONS } from '../actions/updateCost'
import { CREATECOST_ACTIONS } from '../actions/createCost'
import { GETMACHINETYPELIST_ACTIONS } from '../actions/getMachineTypeList'
import { DELETEMACHINETYPE_ACTIONS } from '../actions/deleteMachineType'
import { UPDATEMACHINETYPE_ACTIONS } from '../actions/updateMachineType'
import { CREATEMACHINETYPE_ACTIONS } from '../actions/createMachineType'
import { GETMACHINELIST_ACTIONS } from '../actions/getMachineList'
import { DELETEMACHINE_ACTIONS } from '../actions/deleteMachine'
import { UPDATEMACHINE_ACTIONS } from '../actions/updateMachine'
import { CREATEMACHINE_ACTIONS } from '../actions/createMachine'
import { GETWORKLISTFILTER_ACTIONS } from '../actions/getWorkListFilter'
import { GETWORKLISTSPAN_ACTIONS } from '../actions/getWorkListSpan'
import { GETWORKLIST_ACTIONS } from '../actions/getWorkList'
import { GETWORK_ACTIONS } from '../actions/getWork'
import { DELETEWORK_ACTIONS } from '../actions/deleteWork'
import { UPDATEWORK_ACTIONS } from '../actions/updateWork'
import { CREATEWORK_ACTIONS } from '../actions/createWork'
import { DELETEJOB_ACTIONS } from '../actions/deleteJob'
import { SWITCHJOB_ACTIONS } from '../actions/switchJob'
import { UPDATEJOB_ACTIONS } from '../actions/updateJob'
import { CREATEJOB_ACTIONS } from '../actions/createJob'
import { LOGINEMPLOYEE_ACTIONS } from '../actions/loginEmployee'
import { LOGIN_ACTIONS } from '../actions/login'

import io from 'socket.io-client'

import moment from 'moment-with-locales-es6'
moment.locale('it')

var requestCounter = 0

const abortExclude = ['create_collection', 'create_family', 'create_article', 'create_variant']

class NetworkProvider extends Component {
    get WEB_REQUEST_URL() {
        return process.env.REACT_APP_HERCULES
    }

    get HADES() {
        return process.env.REACT_APP_HADES
    }

    get GET() {
        return 'GET'
    }

    get POST() {
        return 'POST'
    }

    static childContextTypes = {
        network: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            activeRequests: {},
            socket: null,
        }
    }

    socket = () => this.state.socket
    connectSocket() {
        let { login, hades_tkn } = this.props
        let subscriber = login.authenticated ? 'client' : 'machine'
        let socket
        console.info('Connetting', `${process.env.REACT_APP_HADES}${subscriber}`)
        if (process.env.REACT_APP_HADES) {
            socket = io(`${process.env.REACT_APP_HADES}${subscriber}`, {
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            authorization: hades_tkn,
                        },
                    },
                },
            })
        }

        if (socket) {
            socket.on('connect', () => {
                this.props.realtimeConnect()
                this.setState({ socket })
                console.info('NetworkProvider socketio: connected')
            })

            socket.on('connect_error', (err) => {
                console.error('NetworkProvider socketio: Connect error on socketio connection:', err)
            })

            socket.on('error', (err) => {
                console.error('NetworkProvider socketio: Error on socketio connection:', err)
            })

            socket.on('reconnect', (err) => {
                console.error('NetworkProvider socketio: Reconnect on socketio connection:', err)
            })

            socket.on('disconnect', () => {
                this.props.realtimeDisconnect()
                console.log('NetworkProvider socketio: disconnected')
            })

            socket.on('realtime', (data) => {
                this.props.realtimeNewdata(data)
            })
        }
    }

    disconnectSocket() {
        let { socket } = this.state
        if (!socket) return

        socket.disconnect()
        this.setState({ socket: null })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { login, loginEmployee, hades_tkn } = this.props
        if (prevProps.hades_tkn != hades_tkn) {
            this.connectSocket()
        }

        if (!prevProps.login.authenticated && login.authenticated) {
            requests.hadesToken.bind(this)('client', 'zeus')
        } else if (!prevProps.loginEmployee.authenticated && loginEmployee.authenticated) {
            requests.hadesToken.bind(this)('machine', 'zeus-synoptic')
        } else if (
            (prevProps.login.authenticated && !login.authenticated) ||
            (prevProps.loginEmployee.authenticated && !loginEmployee.authenticated)
        ) {
            this.disconnectSocket()
        }
    }

    componentDidMount() {
        let cookieuser = this.props.cookies.get('user')
        if (cookieuser) {
            this.props.loginResult({ user: cookieuser })
        }
    }

    setCookie = (name, value) => this.props.cookies.set(name, value, { path: '/' })
    getCookie = (name) => this.props.cookies.get(name)
    removeCookie = (name) => this.props.cookies.remove(name, { path: '/' })

    /**
     * doAction
     * service: URI
     * bundle: {
     *      data: {... action: one of the exported constants},
     *      headers: [],
     *      *** REDUX functions ***
     *      request: ()=>{},
     *      receive: ()=>{},
     *      failure: ()=>{},
     *      ***********************
     *      method: 'get'|'post'
     *  }
     */
    doAction = (service, bundle) => {
        let { activeRequests } = this.state
        let currentAction = bundle.data.ACTION ? bundle.data.ACTION : service

        console.log('doAction', currentAction, 'actives:', activeRequests)

        if (activeRequests[currentAction]) {
            let ignore = false
            for (const excluded of abortExclude) {
                if (excluded === currentAction) {
                    ignore = true
                }
            }
            if (!ignore) {
                activeRequests[currentAction].abort()
                delete activeRequests[currentAction]
            }
        }

        let currentRequestController = new AbortController()
        let signal = currentRequestController.signal
        signal.addEventListener('abort', () => {})
        activeRequests[currentAction] = currentRequestController

        this.setState({
            activeRequests,
        })

        if (bundle.request) {
            bundle.request(bundle.data)
        }

        if (!bundle.loginType) {
            bundle.loginType = 'admin'
        }

        let tokenobj = {}
        let { login, loginEmployee } = this.props
        let loginobj = login

        switch (bundle.loginType) {
            case 'admin':
                loginobj = login
                break
            case 'employee':
                loginobj = loginEmployee
                break
            default:
                break
        }

        if (!bundle.noAuth && loginobj.data.user && loginobj.data.user.token) {
            tokenobj['Authorization'] = `Bearer ${loginobj.data.user.token}`
        }

        console.log('Action', bundle, 'Adding organization id', this.props.organization.data)
        bundle.data.organizationIndexes = this.props.organization.data ? this.props.organization.data : [0]

        let website = service
        if (bundle.method === 'GET') {
            website = new URL(service)
            Object.keys(bundle.data).forEach((key) => website.searchParams.append(key, bundle.data[key]))
        }

        let body = {}
        if (bundle.noStringify) {
            body = bundle.data
        } else {
            body = JSON.stringify(bundle.data)
        }

        let formData = body
        if (formData.entries) {
            for (var key of formData.entries()) {
                console.dir(key[0])
                console.dir(key[1])
            }
        }

        let headers = {
            ...bundle.headers,
            ...tokenobj,
        }

        if (bundle.contenttype !== 'multipart/form-data') {
            headers['Content-Type'] = bundle.contenttype ? bundle.contenttype : 'application/json'
        }

        headers['cache-control'] = 'no-cache'

        let reqObj = {
            signal: currentRequestController.signal,
            method: bundle.method ? bundle.method : 'POST',
            headers,
            body: bundle.method === 'POST' ? body : undefined,
        }

        fetch(website, reqObj)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response
                } else {
                    var error = new Error(response.statusText)
                    error.response = response
                    if (bundle.failure) {
                        bundle.failure(error)
                    }
                    throw error
                }
            })
            .then((response) => {
                let json = response.json()
                return json
            })
            .then((json) => {
                if (bundle.receive) {
                    console.log('Request SUCCESS:', bundle)
                    bundle.receive(json)
                }
            })
            .catch((ex) => {
                if (ex.name === 'AbortError') {
                    console.warn('Request ABORTED:', bundle)
                    return
                }

                if (bundle.failure) {
                    console.error('Request FAILED:', ex, bundle)
                    bundle.failure(ex)
                }
            })
    }

    createBundle = (action, data, method, request, receive, failure, isEmployee) => {
        let loginType = isEmployee ? 'employee' : 'admin'
        requestCounter += 1

        return {
            data: {
                ACTION: action,
                request_count: requestCounter,
                ...data,
            },
            method,
            request,
            receive,
            failure,
            loginType,
        }
    }

    getChildContext() {
        const { getCookie, setCookie, removeCookie, socket, createBundle, doAction, WEB_REQUEST_URL, HADES, GET, POST } = this

        let obj = {}
        for (let [key, value] of Object.entries(requests)) {
            obj[key] = (...args) => value.bind(this)(...args)
        }

        return {
            network: {
                ...obj,
                getUrl: () => this.WEB_REQUEST_URL,
                getCookie,
                setCookie,
                removeCookie,
                socket,
                createBundle,
                doAction,
                WEB_REQUEST_URL,
                HADES,
                GET,
                POST,
            },
        }
    }

    render() {
        return Children.only(this.props.children)
    }
}

const mapStateToProps = (state) => {
    let {
        login,
        loginEmployee,
        organization,
        hades: {
            hadesToken: {
                data: { data: hades_tkn },
            },
        },
    } = state

    return {
        login,
        loginEmployee,
        organization,
        hades_tkn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        /* @dispatch */
        hadesTokenRequest: (result) => HADESTOKEN_ACTIONS.request(result, dispatch),
        hadesTokenResult: (result) => HADESTOKEN_ACTIONS.result(result, dispatch),
        hadesTokenError: (result) => HADESTOKEN_ACTIONS.error(result, dispatch),

        filtersRequest: (result) => FILTERS_ACTIONS.request(result, dispatch),
        filtersResult: (result) => FILTERS_ACTIONS.result(result, dispatch),
        filtersError: (result) => FILTERS_ACTIONS.error(result, dispatch),

        getLabelListRequest: (result) => GETLABELLIST_ACTIONS.request(result, dispatch),
        getLabelListResult: (result) => GETLABELLIST_ACTIONS.result(result, dispatch),
        getLabelListError: (result) => GETLABELLIST_ACTIONS.error(result, dispatch),

        getLabelRequest: (result) => GETLABEL_ACTIONS.request(result, dispatch),
        getLabelResult: (result) => GETLABEL_ACTIONS.result(result, dispatch),
        getLabelError: (result) => GETLABEL_ACTIONS.error(result, dispatch),

        createLabelRequest: (result) => CREATELABEL_ACTIONS.request(result, dispatch),
        createLabelResult: (result) => CREATELABEL_ACTIONS.result(result, dispatch),
        createLabelError: (result) => CREATELABEL_ACTIONS.error(result, dispatch),

        deleteLabelRequest: (result) => DELETELABEL_ACTIONS.request(result, dispatch),
        deleteLabelRequest: (result) => DELETELABEL_ACTIONS.result(result, dispatch),
        deleteLabelRequest: (result) => DELETELABEL_ACTIONS.error(result, dispatch),

        getDataImportListRequest: (result) => GETDATAIMPORTLIST_ACTIONS.request(result, dispatch),
        getDataImportListResult: (result) => GETDATAIMPORTLIST_ACTIONS.result(result, dispatch),
        getDataImportListError: (result) => GETDATAIMPORTLIST_ACTIONS.error(result, dispatch),

        runReverseGeocodingRequest: (result) => RUNREVERSEGEOCODING_ACTIONS.request(result, dispatch),
        runReverseGeocodingResult: (result) => RUNREVERSEGEOCODING_ACTIONS.result(result, dispatch),
        runReverseGeocodingError: (result) => RUNREVERSEGEOCODING_ACTIONS.error(result, dispatch),

        clearParentRequest: (result) => CLEARPARENT_ACTIONS.request(result, dispatch),
        clearParentResult: (result) => CLEARPARENT_ACTIONS.result(result, dispatch),
        clearParentError: (result) => CLEARPARENT_ACTIONS.error(result, dispatch),

        deleteArticleVariantRequest: (result) => DELETEARTICLEVARIANT_ACTIONS.request(result, dispatch),
        deleteArticleVariantResult: (result) => DELETEARTICLEVARIANT_ACTIONS.result(result, dispatch),
        deleteArticleVariantError: (result) => DELETEARTICLEVARIANT_ACTIONS.error(result, dispatch),

        getDataImportListRequest: (result) => GETDATAIMPORTLIST_ACTIONS.request(result, dispatch),
        getDataImportListResult: (result) => GETDATAIMPORTLIST_ACTIONS.result(result, dispatch),
        getDataImportListError: (result) => GETDATAIMPORTLIST_ACTIONS.error(result, dispatch),

        doAssignDuplicatesRequest: (result) => DOASSIGNDUPLICATES_ACTIONS.request(result, dispatch),
        doAssignDuplicatesResult: (result) => DOASSIGNDUPLICATES_ACTIONS.result(result, dispatch),
        doAssignDuplicatesError: (result) => DOASSIGNDUPLICATES_ACTIONS.error(result, dispatch),

        setArticleVariantOrderingRequest: (result) => SETARTICLEVARIANTORDERING_ACTIONS.request(result, dispatch),
        setArticleVariantOrderingResult: (result) => SETARTICLEVARIANTORDERING_ACTIONS.result(result, dispatch),
        setArticleVariantOrderingError: (result) => SETARTICLEVARIANTORDERING_ACTIONS.error(result, dispatch),
        deleteOrganizationRequest: (result) => DELETEORGANIZATION_ACTIONS.request(result, dispatch),
        deleteOrganizationResult: (result) => DELETEORGANIZATION_ACTIONS.result(result, dispatch),
        deleteOrganizationError: (result) => DELETEORGANIZATION_ACTIONS.error(result, dispatch),

        doAssignVariantsRequest: (result) => DOASSIGNVARIANTS_ACTIONS.request(result, dispatch),
        doAssignVariantsResult: (result) => DOASSIGNVARIANTS_ACTIONS.result(result, dispatch),
        doAssignVariantsError: (result) => DOASSIGNVARIANTS_ACTIONS.error(result, dispatch),

        createOrganizationRequest: (result) => CREATEORGANIZATION_ACTIONS.request(result, dispatch),
        createOrganizationResult: (result) => CREATEORGANIZATION_ACTIONS.result(result, dispatch),
        createOrganizationError: (result) => CREATEORGANIZATION_ACTIONS.error(result, dispatch),

        deleteOfficeRequest: (result) => DELETEOFFICE_ACTIONS.request(result, dispatch),
        deleteOfficeResult: (result) => DELETEOFFICE_ACTIONS.result(result, dispatch),
        deleteOfficeError: (result) => DELETEOFFICE_ACTIONS.error(result, dispatch),

        createOfficeRequest: (result) => CREATEOFFICE_ACTIONS.request(result, dispatch),
        createOfficeResult: (result) => CREATEOFFICE_ACTIONS.result(result, dispatch),
        createOfficeError: (result) => CREATEOFFICE_ACTIONS.error(result, dispatch),

        getOrganizationListRequest: (result) => GETORGANIZATIONLIST_ACTIONS.request(result, dispatch),
        getOrganizationListResult: (result) => GETORGANIZATIONLIST_ACTIONS.result(result, dispatch),
        getOrganizationListError: (result) => GETORGANIZATIONLIST_ACTIONS.error(result, dispatch),

        getOrganizationRequest: (result) => GETORGANIZATION_ACTIONS.request(result, dispatch),
        getOrganizationResult: (result) => GETORGANIZATION_ACTIONS.result(result, dispatch),
        getOrganizationError: (result) => GETORGANIZATION_ACTIONS.error(result, dispatch),

        getOfficeListRequest: (result) => GETOFFICELIST_ACTIONS.request(result, dispatch),
        getOfficeListResult: (result) => GETOFFICELIST_ACTIONS.result(result, dispatch),
        getOfficeListError: (result) => GETOFFICELIST_ACTIONS.error(result, dispatch),

        getOfficeRequest: (result) => GETOFFICE_ACTIONS.request(result, dispatch),
        getOfficeResult: (result) => GETOFFICE_ACTIONS.result(result, dispatch),
        getOfficeError: (result) => GETOFFICE_ACTIONS.error(result, dispatch),

        deleteContractRequest: (result) => DELETECONTRACT_ACTIONS.request(result, dispatch),
        deleteContractResult: (result) => DELETECONTRACT_ACTIONS.result(result, dispatch),
        deleteContractError: (result) => DELETECONTRACT_ACTIONS.error(result, dispatch),

        getArticleVariantImageListRequest: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.request(result, dispatch),
        getArticleVariantImageListResult: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.result(result, dispatch),
        getArticleVariantImageListError: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.error(result, dispatch),

        createContractRequest: (result) => CREATECONTRACT_ACTIONS.request(result, dispatch),
        createContractResult: (result) => CREATECONTRACT_ACTIONS.result(result, dispatch),
        createContractError: (result) => CREATECONTRACT_ACTIONS.error(result, dispatch),

        getCollectionsFullRequest: (result) => GETCOLLECTIONSFULL_ACTIONS.request(result, dispatch),
        getCollectionsFullResult: (result) => GETCOLLECTIONSFULL_ACTIONS.result(result, dispatch),
        getCollectionsFullError: (result) => GETCOLLECTIONSFULL_ACTIONS.error(result, dispatch),

        getContractListRequest: (result) => GETCONTRACTLIST_ACTIONS.request(result, dispatch),
        getContractListResult: (result) => GETCONTRACTLIST_ACTIONS.result(result, dispatch),
        getContractListError: (result) => GETCONTRACTLIST_ACTIONS.error(result, dispatch),

        uploadMultipleFilesRequest: (result) => UPLOADMULTIPLEFILES_ACTIONS.request(result, dispatch),
        uploadMultipleFilesResult: (result) => UPLOADMULTIPLEFILES_ACTIONS.result(result, dispatch),
        uploadMultipleFilesError: (result) => UPLOADMULTIPLEFILES_ACTIONS.error(result, dispatch),

        getContractRequest: (result) => GETCONTRACT_ACTIONS.request(result, dispatch),
        getContractResult: (result) => GETCONTRACT_ACTIONS.result(result, dispatch),
        getContractError: (result) => GETCONTRACT_ACTIONS.error(result, dispatch),

        deletePictureRequest: (result) => DELETEPICTURE_ACTIONS.request(result, dispatch),
        deletePictureResult: (result) => DELETEPICTURE_ACTIONS.result(result, dispatch),
        deletePictureError: (result) => DELETEPICTURE_ACTIONS.error(result, dispatch),

        sendNotificationRequest: (result) => SENDNOTIFICATION_ACTIONS.request(result, dispatch),
        sendNotificationResult: (result) => SENDNOTIFICATION_ACTIONS.result(result, dispatch),
        sendNotificationError: (result) => SENDNOTIFICATION_ACTIONS.error(result, dispatch),

        createPictureRequest: (result) => CREATEPICTURE_ACTIONS.request(result, dispatch),
        createPictureResult: (result) => CREATEPICTURE_ACTIONS.result(result, dispatch),
        createPictureError: (result) => CREATEPICTURE_ACTIONS.error(result, dispatch),

        createProductRequest: (result) => CREATEPRODUCT_ACTIONS.request(result, dispatch),
        createProductResult: (result) => CREATEPRODUCT_ACTIONS.result(result, dispatch),
        createProductError: (result) => CREATEPRODUCT_ACTIONS.error(result, dispatch),

        deleteDepositRequest: (result) => DELETEDEPOSIT_ACTIONS.request(result, dispatch),
        deleteDepositResult: (result) => DELETEDEPOSIT_ACTIONS.result(result, dispatch),
        deleteDepositError: (result) => DELETEDEPOSIT_ACTIONS.error(result, dispatch),

        getArticleRecapRequest: (result) => GETARTICLERECAP_ACTIONS.request(result, dispatch),
        getArticleRecapResult: (result) => GETARTICLERECAP_ACTIONS.result(result, dispatch),
        getArticleRecapError: (result) => GETARTICLERECAP_ACTIONS.error(result, dispatch),

        getPictureListRequest: (result) => GETPICTURELIST_ACTIONS.request(result, dispatch),
        getPictureListResult: (result) => GETPICTURELIST_ACTIONS.result(result, dispatch),
        getPictureListError: (result) => GETPICTURELIST_ACTIONS.error(result, dispatch),

        getCorpListRequest: (result) => GETCORPLIST_ACTIONS.request(result, dispatch),
        getCorpListResult: (result) => GETCORPLIST_ACTIONS.result(result, dispatch),
        getCorpListError: (result) => GETCORPLIST_ACTIONS.error(result, dispatch),

        createDepositRequest: (result) => CREATEDEPOSIT_ACTIONS.request(result, dispatch),
        createDepositResult: (result) => CREATEDEPOSIT_ACTIONS.result(result, dispatch),
        createDepositError: (result) => CREATEDEPOSIT_ACTIONS.error(result, dispatch),

        getProductRecapRequest: (result) => GETPRODUCTRECAP_ACTIONS.request(result, dispatch),
        getProductRecapResult: (result) => GETPRODUCTRECAP_ACTIONS.result(result, dispatch),
        getProductRecapError: (result) => GETPRODUCTRECAP_ACTIONS.error(result, dispatch),

        getPictureRequest: (result) => GETPICTURE_ACTIONS.request(result, dispatch),
        getPictureResult: (result) => GETPICTURE_ACTIONS.result(result, dispatch),
        getPictureError: (result) => GETPICTURE_ACTIONS.error(result, dispatch),

        getCategoryListRequest: (result) => GETCATEGORYLIST_ACTIONS.request(result, dispatch),
        getCategoryListResult: (result) => GETCATEGORYLIST_ACTIONS.result(result, dispatch),
        getCategoryListError: (result) => GETCATEGORYLIST_ACTIONS.error(result, dispatch),

        getDepositRequest: (result) => GETDEPOSIT_ACTIONS.request(result, dispatch),
        getDepositResult: (result) => GETDEPOSIT_ACTIONS.result(result, dispatch),
        getDepositError: (result) => GETDEPOSIT_ACTIONS.error(result, dispatch),

        getDocumentRecapRequest: (result) => GETDOCUMENTRECAP_ACTIONS.request(result, dispatch),
        getDocumentRecapResult: (result) => GETDOCUMENTRECAP_ACTIONS.result(result, dispatch),
        getDocumentRecapError: (result) => GETDOCUMENTRECAP_ACTIONS.error(result, dispatch),

        deleteStdArticleRequest: (result) => DELETESTDARTICLE_ACTIONS.request(result, dispatch),
        deleteStdArticleResult: (result) => DELETESTDARTICLE_ACTIONS.result(result, dispatch),
        deleteStdArticleError: (result) => DELETESTDARTICLE_ACTIONS.error(result, dispatch),

        getCategoryRequest: (result) => GETCATEGORY_ACTIONS.request(result, dispatch),
        getCategoryResult: (result) => GETCATEGORY_ACTIONS.result(result, dispatch),
        getCategoryError: (result) => GETCATEGORY_ACTIONS.error(result, dispatch),

        createStdArticleRequest: (result) => CREATESTDARTICLE_ACTIONS.request(result, dispatch),
        createStdArticleResult: (result) => CREATESTDARTICLE_ACTIONS.result(result, dispatch),
        createStdArticleError: (result) => CREATESTDARTICLE_ACTIONS.error(result, dispatch),

        getCorpRequest: (result) => GETCORP_ACTIONS.request(result, dispatch),
        getCorpResult: (result) => GETCORP_ACTIONS.result(result, dispatch),
        getCorpError: (result) => GETCORP_ACTIONS.error(result, dispatch),

        getStdArticleListRequest: (result) => GETSTDARTICLELIST_ACTIONS.request(result, dispatch),
        getStdArticleListResult: (result) => GETSTDARTICLELIST_ACTIONS.result(result, dispatch),
        getStdArticleListError: (result) => GETSTDARTICLELIST_ACTIONS.error(result, dispatch),

        deleteCorpRequest: (result) => DELETECORP_ACTIONS.request(result, dispatch),
        deleteCorpResult: (result) => DELETECORP_ACTIONS.result(result, dispatch),
        deleteCorpError: (result) => DELETECORP_ACTIONS.error(result, dispatch),

        getStdArticleRequest: (result) => GETSTDARTICLE_ACTIONS.request(result, dispatch),
        getStdArticleResult: (result) => GETSTDARTICLE_ACTIONS.result(result, dispatch),
        getStdArticleError: (result) => GETSTDARTICLE_ACTIONS.error(result, dispatch),

        createCorpRequest: (result) => CREATECORP_ACTIONS.request(result, dispatch),
        createCorpResult: (result) => CREATECORP_ACTIONS.result(result, dispatch),
        createCorpError: (result) => CREATECORP_ACTIONS.error(result, dispatch),

        deleteVariantRequest: (result) => DELETEVARIANT_ACTIONS.request(result, dispatch),
        deleteVariantResult: (result) => DELETEVARIANT_ACTIONS.result(result, dispatch),
        deleteVariantError: (result) => DELETEVARIANT_ACTIONS.error(result, dispatch),

        deleteCategoryRequest: (result) => DELETECATEGORY_ACTIONS.request(result, dispatch),
        deleteCategoryResult: (result) => DELETECATEGORY_ACTIONS.result(result, dispatch),
        deleteCategoryError: (result) => DELETECATEGORY_ACTIONS.error(result, dispatch),

        createVariantRequest: (result) => CREATEVARIANT_ACTIONS.request(result, dispatch),
        createVariantResult: (result) => CREATEVARIANT_ACTIONS.result(result, dispatch),
        createVariantError: (result) => CREATEVARIANT_ACTIONS.error(result, dispatch),

        createCategoryRequest: (result) => CREATECATEGORY_ACTIONS.request(result, dispatch),
        createCategoryResult: (result) => CREATECATEGORY_ACTIONS.result(result, dispatch),
        createCategoryError: (result) => CREATECATEGORY_ACTIONS.error(result, dispatch),

        getVariantListRequest: (result) => GETVARIANTLIST_ACTIONS.request(result, dispatch),
        getVariantListResult: (result) => GETVARIANTLIST_ACTIONS.result(result, dispatch),
        getVariantListError: (result) => GETVARIANTLIST_ACTIONS.error(result, dispatch),

        deleteUserRequest: (result) => DELETEUSER_ACTIONS.request(result, dispatch),
        deleteUserResult: (result) => DELETEUSER_ACTIONS.result(result, dispatch),
        deleteUserError: (result) => DELETEUSER_ACTIONS.error(result, dispatch),

        getVariantRequest: (result) => GETVARIANT_ACTIONS.request(result, dispatch),
        getVariantResult: (result) => GETVARIANT_ACTIONS.result(result, dispatch),
        getVariantError: (result) => GETVARIANT_ACTIONS.error(result, dispatch),

        getProductRequest: (result) => GETPRODUCT_ACTIONS.request(result, dispatch),
        getProductResult: (result) => GETPRODUCT_ACTIONS.result(result, dispatch),
        getProductError: (result) => GETPRODUCT_ACTIONS.error(result, dispatch),

        deleteFamilyRequest: (result) => DELETEFAMILY_ACTIONS.request(result, dispatch),
        deleteFamilyResult: (result) => DELETEFAMILY_ACTIONS.result(result, dispatch),
        deleteFamilyError: (result) => DELETEFAMILY_ACTIONS.error(result, dispatch),

        deleteProductRequest: (result) => DELETEPRODUCT_ACTIONS.request(result, dispatch),
        deleteProductResult: (result) => DELETEPRODUCT_ACTIONS.result(result, dispatch),
        deleteProductError: (result) => DELETEPRODUCT_ACTIONS.error(result, dispatch),

        createFamilyRequest: (result) => CREATEFAMILY_ACTIONS.request(result, dispatch),
        createFamilyResult: (result) => CREATEFAMILY_ACTIONS.result(result, dispatch),
        createFamilyError: (result) => CREATEFAMILY_ACTIONS.error(result, dispatch),

        getProductListRequest: (result) => GETPRODUCTLIST_ACTIONS.request(result, dispatch),
        getProductListResult: (result) => GETPRODUCTLIST_ACTIONS.result(result, dispatch),
        getProductListError: (result) => GETPRODUCTLIST_ACTIONS.error(result, dispatch),

        getFamilyListRequest: (result) => GETFAMILYLIST_ACTIONS.request(result, dispatch),
        getFamilyListResult: (result) => GETFAMILYLIST_ACTIONS.result(result, dispatch),
        getFamilyListError: (result) => GETFAMILYLIST_ACTIONS.error(result, dispatch),

        getDepositListRequest: (result) => GETDEPOSITLIST_ACTIONS.request(result, dispatch),
        getDepositListResult: (result) => GETDEPOSITLIST_ACTIONS.result(result, dispatch),
        getDepositListError: (result) => GETDEPOSITLIST_ACTIONS.error(result, dispatch),

        getFamilyRequest: (result) => GETFAMILY_ACTIONS.request(result, dispatch),
        getFamilyResult: (result) => GETFAMILY_ACTIONS.result(result, dispatch),
        getFamilyError: (result) => GETFAMILY_ACTIONS.error(result, dispatch),

        getDocumentRequest: (result) => GETDOCUMENT_ACTIONS.request(result, dispatch),
        getDocumentResult: (result) => GETDOCUMENT_ACTIONS.result(result, dispatch),
        getDocumentError: (result) => GETDOCUMENT_ACTIONS.error(result, dispatch),

        deleteCollectionRequest: (result) => DELETECOLLECTION_ACTIONS.request(result, dispatch),
        deleteCollectionResult: (result) => DELETECOLLECTION_ACTIONS.result(result, dispatch),
        deleteCollectionError: (result) => DELETECOLLECTION_ACTIONS.error(result, dispatch),

        deleteDocumentRequest: (result) => DELETEDOCUMENT_ACTIONS.request(result, dispatch),
        deleteDocumentResult: (result) => DELETEDOCUMENT_ACTIONS.result(result, dispatch),
        deleteDocumentError: (result) => DELETEDOCUMENT_ACTIONS.error(result, dispatch),

        createCollectionRequest: (result) => CREATECOLLECTION_ACTIONS.request(result, dispatch),
        createCollectionResult: (result) => CREATECOLLECTION_ACTIONS.result(result, dispatch),
        createCollectionError: (result) => CREATECOLLECTION_ACTIONS.error(result, dispatch),

        createDocumentRequest: (result) => CREATEDOCUMENT_ACTIONS.request(result, dispatch),
        createDocumentResult: (result) => CREATEDOCUMENT_ACTIONS.result(result, dispatch),
        createDocumentError: (result) => CREATEDOCUMENT_ACTIONS.error(result, dispatch),

        getCollectionRequest: (result) => GETCOLLECTION_ACTIONS.request(result, dispatch),
        getCollectionResult: (result) => GETCOLLECTION_ACTIONS.result(result, dispatch),
        getCollectionError: (result) => GETCOLLECTION_ACTIONS.error(result, dispatch),

        getDocumentListRequest: (result) => GETDOCUMENTLIST_ACTIONS.request(result, dispatch),
        getDocumentListResult: (result) => GETDOCUMENTLIST_ACTIONS.result(result, dispatch),
        getDocumentListError: (result) => GETDOCUMENTLIST_ACTIONS.error(result, dispatch),

        getCollectionListRequest: (result) => GETCOLLECTIONLIST_ACTIONS.request(result, dispatch),
        getCollectionListResult: (result) => GETCOLLECTIONLIST_ACTIONS.result(result, dispatch),
        getCollectionListError: (result) => GETCOLLECTIONLIST_ACTIONS.error(result, dispatch),

        getArticleListRequest: (result) => GETARTICLELIST_ACTIONS.request(result, dispatch),
        getArticleListResult: (result) => GETARTICLELIST_ACTIONS.result(result, dispatch),
        getArticleListError: (result) => GETARTICLELIST_ACTIONS.error(result, dispatch),

        getUrgentJobListRequest: (result) => GETURGENTJOBLIST_ACTIONS.request(result, dispatch),
        getUrgentJobListResult: (result) => GETURGENTJOBLIST_ACTIONS.result(result, dispatch),
        getUrgentJobListError: (result) => GETURGENTJOBLIST_ACTIONS.error(result, dispatch),

        getArticleRequest: (result) => GETARTICLE_ACTIONS.request(result, dispatch),
        getArticleResult: (result) => GETARTICLE_ACTIONS.result(result, dispatch),
        getArticleError: (result) => GETARTICLE_ACTIONS.error(result, dispatch),

        deleteArticleRequest: (result) => DELETEARTICLE_ACTIONS.request(result, dispatch),
        deleteArticleResult: (result) => DELETEARTICLE_ACTIONS.result(result, dispatch),
        deleteArticleError: (result) => DELETEARTICLE_ACTIONS.error(result, dispatch),

        createArticleRequest: (result) => CREATEARTICLE_ACTIONS.request(result, dispatch),
        createArticleResult: (result) => CREATEARTICLE_ACTIONS.result(result, dispatch),
        createArticleError: (result) => CREATEARTICLE_ACTIONS.error(result, dispatch),

        getTurnRequest: (result) => GETTURN_ACTIONS.request(result, dispatch),
        getTurnResult: (result) => GETTURN_ACTIONS.result(result, dispatch),
        getTurnError: (result) => GETTURN_ACTIONS.error(result, dispatch),

        deleteTurnRequest: (result) => DELETETURN_ACTIONS.request(result, dispatch),
        deleteTurnResult: (result) => DELETETURN_ACTIONS.result(result, dispatch),
        deleteTurnError: (result) => DELETETURN_ACTIONS.error(result, dispatch),

        createTurnRequest: (result) => CREATETURN_ACTIONS.request(result, dispatch),
        createTurnResult: (result) => CREATETURN_ACTIONS.result(result, dispatch),
        createTurnError: (result) => CREATETURN_ACTIONS.error(result, dispatch),

        getTurnListRequest: (result) => GETTURNLIST_ACTIONS.request(result, dispatch),
        getTurnListResult: (result) => GETTURNLIST_ACTIONS.result(result, dispatch),
        getTurnListError: (result) => GETTURNLIST_ACTIONS.error(result, dispatch),

        getRoleListRequest: (result) => GETROLELIST_ACTIONS.request(result, dispatch),
        getRoleListResult: (result) => GETROLELIST_ACTIONS.result(result, dispatch),
        getRoleListError: (result) => GETROLELIST_ACTIONS.error(result, dispatch),

        getUserRequest: (result) => GETUSER_ACTIONS.request(result, dispatch),
        getUserResult: (result) => GETUSER_ACTIONS.result(result, dispatch),
        getUserError: (result) => GETUSER_ACTIONS.error(result, dispatch),

        changePasswordRequest: (result) => CHANGEPASSWORD_ACTIONS.request(result, dispatch),
        changePasswordResult: (result) => CHANGEPASSWORD_ACTIONS.result(result, dispatch),
        changePasswordError: (result) => CHANGEPASSWORD_ACTIONS.error(result, dispatch),

        createUserRequest: (result) => CREATEUSER_ACTIONS.request(result, dispatch),
        createUserResult: (result) => CREATEUSER_ACTIONS.result(result, dispatch),
        createUserError: (result) => CREATEUSER_ACTIONS.error(result, dispatch),

        getUserListRequest: (result) => GETUSERLIST_ACTIONS.request(result, dispatch),
        getUserListResult: (result) => GETUSERLIST_ACTIONS.result(result, dispatch),
        getUserListError: (result) => GETUSERLIST_ACTIONS.error(result, dispatch),

        organizationRequest: (result) => ORGANIZATION_ACTIONS.request(result, dispatch),
        organizationResult: (result) => ORGANIZATION_ACTIONS.result(result, dispatch),
        organizationError: (result) => ORGANIZATION_ACTIONS.error(result, dispatch),

        getFromRequest: (result) => GETFROM_ACTIONS.request(result, dispatch),
        getFromResult: (result) => GETFROM_ACTIONS.result(result, dispatch),
        getFromError: (result) => GETFROM_ACTIONS.error(result, dispatch),

        getFromDerivedRequest: (result) => GETFROM_DERIVED_ACTIONS.request(result, dispatch),
        getFromDerivedResult: (result) => GETFROM_DERIVED_ACTIONS.result(result, dispatch),
        getFromDerivedError: (result) => GETFROM_DERIVED_ACTIONS.error(result, dispatch),

        recapRequest: (result) => RECAP_ACTIONS.request(result, dispatch),
        recapResult: (result) => RECAP_ACTIONS.result(result, dispatch),
        recapError: (result) => RECAP_ACTIONS.error(result, dispatch),

        saveServiceRequest: (result) => SAVESERVICE_ACTIONS.request(result, dispatch),
        saveServiceResult: (result) => SAVESERVICE_ACTIONS.result(result, dispatch),
        saveServiceError: (result) => SAVESERVICE_ACTIONS.error(result, dispatch),

        getRecentJobListRequest: (result) => GETRECENTJOBLIST_ACTIONS.request(result, dispatch),
        getRecentJobListResult: (result) => GETRECENTJOBLIST_ACTIONS.result(result, dispatch),
        getRecentJobListError: (result) => GETRECENTJOBLIST_ACTIONS.error(result, dispatch),

        deleteBlackboxRequest: (result) => DELETEBLACKBOX_ACTIONS.request(result, dispatch),
        deleteBlackboxResult: (result) => DELETEBLACKBOX_ACTIONS.result(result, dispatch),
        deleteBlackboxError: (result) => DELETEBLACKBOX_ACTIONS.error(result, dispatch),

        jobListStateRequest: (result) => JOBLISTSTATE_ACTIONS.request(result, dispatch),
        jobListStateResult: (result) => JOBLISTSTATE_ACTIONS.result(result, dispatch),
        jobListStateError: (result) => JOBLISTSTATE_ACTIONS.error(result, dispatch),

        deleteWorkListRequest: (result) => DELETEWORKLIST_ACTIONS.request(result, dispatch),
        deleteWorkListResult: (result) => DELETEWORKLIST_ACTIONS.result(result, dispatch),
        deleteWorkListError: (result) => DELETEWORKLIST_ACTIONS.error(result, dispatch),

        getBlackboxListRequest: (result) => GETBLACKBOXLIST_ACTIONS.request(result, dispatch),
        getBlackboxListResult: (result) => GETBLACKBOXLIST_ACTIONS.result(result, dispatch),
        getBlackboxListError: (result) => GETBLACKBOXLIST_ACTIONS.error(result, dispatch),

        realtimeNewdata: (result) => REALTIME_ACTIONS.newdata(result, dispatch),
        realtimeConnect: (result) => REALTIME_ACTIONS.connect(result, dispatch),
        realtimeDisconnect: (result) => REALTIME_ACTIONS.disconnect(result, dispatch),

        getCostRequest: (result) => GETCOST_ACTIONS.request(result, dispatch),
        getCostResult: (result) => GETCOST_ACTIONS.result(result, dispatch),
        getCostError: (result) => GETCOST_ACTIONS.error(result, dispatch),

        getMachineRequest: (result) => GETMACHINE_ACTIONS.request(result, dispatch),
        getMachineResult: (result) => GETMACHINE_ACTIONS.result(result, dispatch),
        getMachineError: (result) => GETMACHINE_ACTIONS.error(result, dispatch),

        getWorkListForDateRequest: (result) => GETWORKLISTFORDATE_ACTIONS.request(result, dispatch),
        getWorkListForDateResult: (result) => GETWORKLISTFORDATE_ACTIONS.result(result, dispatch),
        getWorkListForDateError: (result) => GETWORKLISTFORDATE_ACTIONS.error(result, dispatch),

        getWorkListForDaeRequest: (result) => GETWORKLISTFORDAE_ACTIONS.request(result, dispatch),
        getWorkListForDaeResult: (result) => GETWORKLISTFORDAE_ACTIONS.result(result, dispatch),
        getWorkListForDaeError: (result) => GETWORKLISTFORDAE_ACTIONS.error(result, dispatch),

        getWorkListForDayRequest: (result) => GETWORKLISTFORDAY_ACTIONS.request(result, dispatch),
        getWorkListForDayResult: (result) => GETWORKLISTFORDAY_ACTIONS.result(result, dispatch),
        getWorkListForDayError: (result) => GETWORKLISTFORDAY_ACTIONS.error(result, dispatch),

        workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
        workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
        workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch),

        assignWorkRequest: (result) => ASSIGNWORK_ACTIONS.request(result, dispatch),
        assignWorkResult: (result) => ASSIGNWORK_ACTIONS.result(result, dispatch),
        assignWorkError: (result) => ASSIGNWORK_ACTIONS.error(result, dispatch),

        uploadFileRequest: (result) => UPLOADFILE_ACTIONS.request(result, dispatch),
        uploadFileResult: (result) => UPLOADFILE_ACTIONS.result(result, dispatch),
        uploadFileError: (result) => UPLOADFILE_ACTIONS.error(result, dispatch),

        getImageListRequest: (result) => GETIMAGELIST_ACTIONS.request(result, dispatch),
        getImageListResult: (result) => GETIMAGELIST_ACTIONS.result(result, dispatch),
        getImageListError: (result) => GETIMAGELIST_ACTIONS.error(result, dispatch),

        getJobTypeListRequest: (result) => GETJOBTYPELIST_ACTIONS.request(result, dispatch),
        getJobTypeListResult: (result) => GETJOBTYPELIST_ACTIONS.result(result, dispatch),
        getJobTypeListError: (result) => GETJOBTYPELIST_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        getDetailedJobListRequest: (result) => GETDETAILEDJOBLIST_ACTIONS.request(result, dispatch),
        getDetailedJobListResult: (result) => GETDETAILEDJOBLIST_ACTIONS.result(result, dispatch),
        getDetailedJobListError: (result) => GETDETAILEDJOBLIST_ACTIONS.error(result, dispatch),

        deleteMappingRequest: (result) => DELETEMAPPING_ACTIONS.request(result, dispatch),
        deleteMappingResult: (result) => DELETEMAPPING_ACTIONS.result(result, dispatch),
        deleteMappingError: (result) => DELETEMAPPING_ACTIONS.error(result, dispatch),

        createMappingRequest: (result) => CREATEMAPPING_ACTIONS.request(result, dispatch),
        createMappingResult: (result) => CREATEMAPPING_ACTIONS.result(result, dispatch),
        createMappingError: (result) => CREATEMAPPING_ACTIONS.error(result, dispatch),

        getMappingDataRequest: (result) => GETMAPPINGDATA_ACTIONS.request(result, dispatch),
        getMappingDataResult: (result) => GETMAPPINGDATA_ACTIONS.result(result, dispatch),
        getMappingDataError: (result) => GETMAPPINGDATA_ACTIONS.error(result, dispatch),

        getBabypressaDataFilterRequest: (result) => GETBABYPRESSADATAFILTER_ACTIONS.request(result, dispatch),
        getBabypressaDataFilterResult: (result) => GETBABYPRESSADATAFILTER_ACTIONS.result(result, dispatch),
        getBabypressaDataFilterError: (result) => GETBABYPRESSADATAFILTER_ACTIONS.error(result, dispatch),

        getGT2000DataFilterRequest: (result) => GETGT2000DATAFILTER_ACTIONS.request(result, dispatch),
        getGT2000DataFilterResult: (result) => GETGT2000DATAFILTER_ACTIONS.result(result, dispatch),
        getGT2000DataFilterError: (result) => GETGT2000DATAFILTER_ACTIONS.error(result, dispatch),

        getLastGT2000DataRequest: (result) => GETLASTGT2000DATA_ACTIONS.request(result, dispatch),
        getLastGT2000DataResult: (result) => GETLASTGT2000DATA_ACTIONS.result(result, dispatch),
        getLastGT2000DataError: (result) => GETLASTGT2000DATA_ACTIONS.error(result, dispatch),

        getGT2000DataRequest: (result) => GETGT2000DATA_ACTIONS.request(result, dispatch),
        getGT2000DataResult: (result) => GETGT2000DATA_ACTIONS.result(result, dispatch),
        getGT2000DataError: (result) => GETGT2000DATA_ACTIONS.error(result, dispatch),

        getLastSWA300DataRequest: (result) => GETLASTSWA300DATA_ACTIONS.request(result, dispatch),
        getLastSWA300DataResult: (result) => GETLASTSWA300DATA_ACTIONS.result(result, dispatch),
        getLastSWA300DataError: (result) => GETLASTSWA300DATA_ACTIONS.error(result, dispatch),

        getLastBabypressaDataRequest: (result) => GETLASTBABYPRESSADATA_ACTIONS.request(result, dispatch),
        getLastBabypressaDataResult: (result) => GETLASTBABYPRESSADATA_ACTIONS.result(result, dispatch),
        getLastBabypressaDataError: (result) => GETLASTBABYPRESSADATA_ACTIONS.error(result, dispatch),

        getBabypressaDataRequest: (result) => GETBABYPRESSADATA_ACTIONS.request(result, dispatch),
        getBabypressaDataResult: (result) => GETBABYPRESSADATA_ACTIONS.result(result, dispatch),
        getBabypressaDataError: (result) => GETBABYPRESSADATA_ACTIONS.error(result, dispatch),

        getSWA300DataRequest: (result) => GETSWA300DATA_ACTIONS.request(result, dispatch),
        getSWA300DataResult: (result) => GETSWA300DATA_ACTIONS.result(result, dispatch),
        getSWA300DataError: (result) => GETSWA300DATA_ACTIONS.error(result, dispatch),

        writeSWA300ConfigRequest: (result) => WRITESWA300CONFIG_ACTIONS.request(result, dispatch),
        writeSWA300ConfigResult: (result) => WRITESWA300CONFIG_ACTIONS.result(result, dispatch),
        writeSWA300ConfigError: (result) => WRITESWA300CONFIG_ACTIONS.error(result, dispatch),

        uploadImageRequest: (result) => UPLOADIMAGE_ACTIONS.request(result, dispatch),
        uploadImageResult: (result) => UPLOADIMAGE_ACTIONS.result(result, dispatch),
        uploadImageError: (result) => UPLOADIMAGE_ACTIONS.error(result, dispatch),

        createItemRequest: (result) => CREATEITEM_ACTIONS.request(result, dispatch),
        createItemResult: (result) => CREATEITEM_ACTIONS.result(result, dispatch),
        createItemError: (result) => CREATEITEM_ACTIONS.error(result, dispatch),

        deleteItemRequest: (result) => DELETEITEM_ACTIONS.request(result, dispatch),
        deleteItemResult: (result) => DELETEITEM_ACTIONS.result(result, dispatch),
        deleteItemError: (result) => DELETEITEM_ACTIONS.error(result, dispatch),

        getItemListRequest: (result) => GETITEMLIST_ACTIONS.request(result, dispatch),
        getItemListResult: (result) => GETITEMLIST_ACTIONS.result(result, dispatch),
        getItemListError: (result) => GETITEMLIST_ACTIONS.error(result, dispatch),

        mergeJobRequest: (result) => MERGEJOB_ACTIONS.request(result, dispatch),
        mergeJobResult: (result) => MERGEJOB_ACTIONS.result(result, dispatch),
        mergeJobError: (result) => MERGEJOB_ACTIONS.error(result, dispatch),

        getRecentMachineListRequest: (result) => GETRECENTMACHINELIST_ACTIONS.request(result, dispatch),
        getRecentMachineListResult: (result) => GETRECENTMACHINELIST_ACTIONS.result(result, dispatch),
        getRecentMachineListError: (result) => GETRECENTMACHINELIST_ACTIONS.error(result, dispatch),

        getWorkerRequest: (result) => GETWORKER_ACTIONS.request(result, dispatch),
        getWorkerResult: (result) => GETWORKER_ACTIONS.result(result, dispatch),
        getWorkerError: (result) => GETWORKER_ACTIONS.error(result, dispatch),

        getJobRequest: (result) => GETJOB_ACTIONS.request(result, dispatch),
        getJobResult: (result) => GETJOB_ACTIONS.result(result, dispatch),
        getJobError: (result) => GETJOB_ACTIONS.error(result, dispatch),

        getPagedJobListRequest: (result) => GETPAGEDJOBLIST_ACTIONS.request(result, dispatch),
        getPagedJobListResult: (result) => GETPAGEDJOBLIST_ACTIONS.result(result, dispatch),
        getPagedJobListError: (result) => GETPAGEDJOBLIST_ACTIONS.error(result, dispatch),

        getJobListRequest: (result) => GETJOBLIST_ACTIONS.request(result, dispatch),
        getJobListResult: (result) => GETJOBLIST_ACTIONS.result(result, dispatch),
        getJobListError: (result) => GETJOBLIST_ACTIONS.error(result, dispatch),

        getWorkerListRequest: (result) => GETWORKERLIST_ACTIONS.request(result, dispatch),
        getWorkerListResult: (result) => GETWORKERLIST_ACTIONS.result(result, dispatch),
        getWorkerListError: (result) => GETWORKERLIST_ACTIONS.error(result, dispatch),

        getItemCostsRequest: (result) => GETITEMCOSTS_ACTIONS.request(result, dispatch),
        getItemCostsResult: (result) => GETITEMCOSTS_ACTIONS.result(result, dispatch),
        getItemCostsError: (result) => GETITEMCOSTS_ACTIONS.error(result, dispatch),

        deleteWorkerRequest: (result) => DELETEWORKER_ACTIONS.request(result, dispatch),
        deleteWorkerResult: (result) => DELETEWORKER_ACTIONS.result(result, dispatch),
        deleteWorkerError: (result) => DELETEWORKER_ACTIONS.error(result, dispatch),

        updateWorkerRequest: (result) => UPDATEWORKER_ACTIONS.request(result, dispatch),
        updateWorkerResult: (result) => UPDATEWORKER_ACTIONS.result(result, dispatch),
        updateWorkerError: (result) => UPDATEWORKER_ACTIONS.error(result, dispatch),

        createWorkerRequest: (result) => CREATEWORKER_ACTIONS.request(result, dispatch),
        createWorkerResult: (result) => CREATEWORKER_ACTIONS.result(result, dispatch),
        createWorkerError: (result) => CREATEWORKER_ACTIONS.error(result, dispatch),

        getPagedCostListRequest: (result) => GETPAGEDCOSTLIST_ACTIONS.request(result, dispatch),
        getPagedCostListResult: (result) => GETPAGEDCOSTLIST_ACTIONS.result(result, dispatch),
        getPagedCostListError: (result) => GETPAGEDCOSTLIST_ACTIONS.error(result, dispatch),

        getCostListRequest: (result) => GETCOSTLIST_ACTIONS.request(result, dispatch),
        getCostListResult: (result) => GETCOSTLIST_ACTIONS.result(result, dispatch),
        getCostListError: (result) => GETCOSTLIST_ACTIONS.error(result, dispatch),

        deleteCostRequest: (result) => DELETECOST_ACTIONS.request(result, dispatch),
        deleteCostResult: (result) => DELETECOST_ACTIONS.result(result, dispatch),
        deleteCostError: (result) => DELETECOST_ACTIONS.error(result, dispatch),

        updateCostRequest: (result) => UPDATECOST_ACTIONS.request(result, dispatch),
        updateCostResult: (result) => UPDATECOST_ACTIONS.result(result, dispatch),
        updateCostError: (result) => UPDATECOST_ACTIONS.error(result, dispatch),

        createCostRequest: (result) => CREATECOST_ACTIONS.request(result, dispatch),
        createCostResult: (result) => CREATECOST_ACTIONS.result(result, dispatch),
        createCostError: (result) => CREATECOST_ACTIONS.error(result, dispatch),

        getMachineTypeListRequest: (result) => GETMACHINETYPELIST_ACTIONS.request(result, dispatch),
        getMachineTypeListResult: (result) => GETMACHINETYPELIST_ACTIONS.result(result, dispatch),
        getMachineTypeListError: (result) => GETMACHINETYPELIST_ACTIONS.error(result, dispatch),

        deleteMachineTypeRequest: (result) => DELETEMACHINETYPE_ACTIONS.request(result, dispatch),
        deleteMachineTypeResult: (result) => DELETEMACHINETYPE_ACTIONS.result(result, dispatch),
        deleteMachineTypeError: (result) => DELETEMACHINETYPE_ACTIONS.error(result, dispatch),

        updateMachineTypeRequest: (result) => UPDATEMACHINETYPE_ACTIONS.request(result, dispatch),
        updateMachineTypeResult: (result) => UPDATEMACHINETYPE_ACTIONS.result(result, dispatch),
        updateMachineTypeError: (result) => UPDATEMACHINETYPE_ACTIONS.error(result, dispatch),

        createMachineTypeRequest: (result) => CREATEMACHINETYPE_ACTIONS.request(result, dispatch),
        createMachineTypeResult: (result) => CREATEMACHINETYPE_ACTIONS.result(result, dispatch),
        createMachineTypeError: (result) => CREATEMACHINETYPE_ACTIONS.error(result, dispatch),

        getMachineListRequest: (result) => GETMACHINELIST_ACTIONS.request(result, dispatch),
        getMachineListResult: (result) => GETMACHINELIST_ACTIONS.result(result, dispatch),
        getMachineListError: (result) => GETMACHINELIST_ACTIONS.error(result, dispatch),

        deleteMachineRequest: (result) => DELETEMACHINE_ACTIONS.request(result, dispatch),
        deleteMachineResult: (result) => DELETEMACHINE_ACTIONS.result(result, dispatch),
        deleteMachineError: (result) => DELETEMACHINE_ACTIONS.error(result, dispatch),

        updateMachineRequest: (result) => UPDATEMACHINE_ACTIONS.request(result, dispatch),
        updateMachineResult: (result) => UPDATEMACHINE_ACTIONS.result(result, dispatch),
        updateMachineError: (result) => UPDATEMACHINE_ACTIONS.error(result, dispatch),

        createMachineRequest: (result) => CREATEMACHINE_ACTIONS.request(result, dispatch),
        createMachineResult: (result) => CREATEMACHINE_ACTIONS.result(result, dispatch),
        createMachineError: (result) => CREATEMACHINE_ACTIONS.error(result, dispatch),

        getWorkListFilterRequest: (result) => GETWORKLISTFILTER_ACTIONS.request(result, dispatch),
        getWorkListFilterResult: (result) => GETWORKLISTFILTER_ACTIONS.result(result, dispatch),
        getWorkListFilterError: (result) => GETWORKLISTFILTER_ACTIONS.error(result, dispatch),

        getWorkListSpanRequest: (result) => GETWORKLISTSPAN_ACTIONS.request(result, dispatch),
        getWorkListSpanResult: (result) => GETWORKLISTSPAN_ACTIONS.result(result, dispatch),
        getWorkListSpanError: (result) => GETWORKLISTSPAN_ACTIONS.error(result, dispatch),

        getWorkListRequest: (result) => GETWORKLIST_ACTIONS.request(result, dispatch),
        getWorkListResult: (result) => GETWORKLIST_ACTIONS.result(result, dispatch),
        getWorkListError: (result) => GETWORKLIST_ACTIONS.error(result, dispatch),

        getWorkRequest: (result) => GETWORK_ACTIONS.request(result, dispatch),
        getWorkResult: (result) => GETWORK_ACTIONS.result(result, dispatch),
        getWorkError: (result) => GETWORK_ACTIONS.error(result, dispatch),

        deleteWorkRequest: (result) => DELETEWORK_ACTIONS.request(result, dispatch),
        deleteWorkResult: (result) => DELETEWORK_ACTIONS.result(result, dispatch),
        deleteWorkError: (result) => DELETEWORK_ACTIONS.error(result, dispatch),

        updateWorkRequest: (result) => UPDATEWORK_ACTIONS.request(result, dispatch),
        updateWorkResult: (result) => UPDATEWORK_ACTIONS.result(result, dispatch),
        updateWorkError: (result) => UPDATEWORK_ACTIONS.error(result, dispatch),

        createWorkRequest: (result) => CREATEWORK_ACTIONS.request(result, dispatch),
        createWorkResult: (result) => CREATEWORK_ACTIONS.result(result, dispatch),
        createWorkError: (result) => CREATEWORK_ACTIONS.error(result, dispatch),

        deleteJobRequest: (result) => DELETEJOB_ACTIONS.request(result, dispatch),
        deleteJobResult: (result) => DELETEJOB_ACTIONS.result(result, dispatch),
        deleteJobError: (result) => DELETEJOB_ACTIONS.error(result, dispatch),

        switchJobRequest: (result) => SWITCHJOB_ACTIONS.request(result, dispatch),
        switchJobResult: (result) => SWITCHJOB_ACTIONS.result(result, dispatch),
        switchJobError: (result) => SWITCHJOB_ACTIONS.error(result, dispatch),

        updateJobRequest: (result) => UPDATEJOB_ACTIONS.request(result, dispatch),
        updateJobResult: (result) => UPDATEJOB_ACTIONS.result(result, dispatch),
        updateJobError: (result) => UPDATEJOB_ACTIONS.error(result, dispatch),

        createJobRequest: (result) => CREATEJOB_ACTIONS.request(result, dispatch),
        createJobResult: (result) => CREATEJOB_ACTIONS.result(result, dispatch),
        createJobError: (result) => CREATEJOB_ACTIONS.error(result, dispatch),

        loginEmployeeRequest: (result) => LOGINEMPLOYEE_ACTIONS.request(result, dispatch),
        loginEmployeeResult: (result) => LOGINEMPLOYEE_ACTIONS.result(result, dispatch),
        loginEmployeeError: (result) => LOGINEMPLOYEE_ACTIONS.error(result, dispatch),

        logoutEmployee: (result) => LOGINEMPLOYEE_ACTIONS.logout(result, dispatch),

        loginRequest: (result) => LOGIN_ACTIONS.request(result, dispatch),
        loginResult: (result) => LOGIN_ACTIONS.result(result, dispatch),
        loginError: (result) => LOGIN_ACTIONS.error(result, dispatch),
        logout: (result) => LOGIN_ACTIONS.logout(result, dispatch),
    }
}

export const connectNetwork = (ComponentToWrap) => {
    return class NetworkComponent extends Component {
        static contextTypes = {
            network: PropTypes.object.isRequired,
        }

        render() {
            const { network } = this.context

            return <ComponentToWrap {...this.props} network={network} />
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(NetworkProvider))
