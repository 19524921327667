import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Turn } from '../components'

const { app, turns, turnEdit } = routes

class Turns extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteWorker } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteWorker.fetching !== prevState.deleteWorker.fetching && !deleteWorker.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getTurnList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getTurnList(this.state.search.data)
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        this.props.toolbarResult([
            {
                name: 'Crea nuovo turno',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, turns, turnEdit))
                },
            },
        ])

        this.state.network.getTurnList(this.state.search.data)
    }

    renderTurn(key, turn) {
        return <Turn type="full" key={`turn_${turn.turnId}`} turn={turn} />
    }

    render() {
        let content = <div />

        if (this.state.fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card style={{ width: '100%' }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (this.state.turns.data) {
            content = this.state.turns.data.map((value, index) => {
                return this.renderTurn(index, value)
            })
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getTurnList, search, deleteWorker, organization } = state

    return {
        turns: getTurnList.data,
        fetching: getTurnList.fetching,
        deleteWorker,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Turns)))
