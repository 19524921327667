import { Numpad } from '../components'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Message } from 'semantic-ui-react'

const bg_image = require('assets/login.png')
const bg_pin_image = require('assets/login.png')

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            pin: '',
        }
    }

    componentDidUpdate() {
        let { error } = this.state
        let { network } = this.props

        if (this.state.pin.length === 4) {
            this.doWorkerLogin()
        }
        let { login, loginEmployee, isEmployee } = this.props

        let { fetching, status } = isEmployee ? loginEmployee : login

        if (!fetching && status.success) {
        }
        if (fetching) console.log('fetching')
        if (!fetching && status.error && error === undefined) {
            console.log('error')
            this.setState({
                error: {
                    title: 'Errore di Autenticazione',
                    content: isEmployee ? 'Pin errato' : 'Email o Password errati',
                },
            })
        }
    }

    doWorkerLogin() {
        let { pin } = this.state
        let { network } = this.props

        network.loginEmployee(pin)
        this.setState({
            pin: '',
        })
    }

    render() {
        let { login, loginEmployee, network, isEmployee } = this.props
        let { email, password, error } = this.state

        let { fetching } = isEmployee ? loginEmployee : login
        return (
            <View fullh fullw>
                <View style={isEmployee ? styles.bgEmployee : styles.bg} />
                <Form style={{ height: '100%', width: '100%' }}>
                    <View column fullh fullw>
                        {error && (
                            <Message negative style={{ textAlign: 'center' }}>
                                <Message.Header>{error.title}</Message.Header>
                                <p>{error.content}</p>
                            </Message>
                        )}
                        {isEmployee ? (
                            <View fullw fullh column around>
                                <View
                                    style={{
                                        width: '60%',
                                        margin: '0px auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Form.Input
                                        style={{ fontSize: '-webkit-xxx-large' }}
                                        label="Pin"
                                        type="password"
                                        value={this.state.pin}
                                        onChange={(e, { value }) => this.setState({ pin: value })}
                                    />
                                </View>
                                <View fullw style={{ height: '20%' }}>
                                    <Numpad
                                        isString
                                        numpadOnly
                                        label="Inserisci pin"
                                        value={this.state.pin}
                                        onChange={(value) => {
                                            this.setState({ pin: value })
                                        }}
                                    />
                                </View>
                            </View>
                        ) : (
                            <div style={{ position: 'absolute', top: '20%', right: '10%' }}>
                                <Form.Input
                                    label="Utente"
                                    name="email"
                                    data-testid="email"
                                    placeholder="Inserire il nome utente"
                                    onChange={(e, { value }) => this.setState({ email: value })}
                                />
                                <Form.Input
                                    label="Password"
                                    type="password"
                                    data-testid="password"
                                    onChange={(e, { value }) => this.setState({ password: value })}
                                />
                                <Button loading={fetching} color="black" floated="right" onClick={() => network.login(email, password)}>
                                    Accedi
                                </Button>
                                {process.env.REACT_APP_NETWORK && (
                                    <Button
                                        color="orange"
                                        floated="right"
                                        onClick={() => window.open(process.env.PUBLIC_URL + '/RichiestaDigitalArtisan.pdf')}
                                    >
                                        Richiesta Registrazione
                                    </Button>
                                )}
                            </div>
                        )}
                    </View>
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee } = state

    return {
        login,
        loginEmployee,
    }
}
export default withRouter(connect(mapStateToProps)(connectNetwork(Login)))

const styles = {
    form: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: -200,
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bg_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        filter: 'blur(5px)',
        WebkitFilter: 'blur(5px)',
    },
    bgEmployee: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${bg_pin_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        filter: 'blur(5px)',
        WebkitFilter: 'blur(5px)',
    },
}
