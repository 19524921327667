import { types, actions } from './requests'
export const LOGINEMPLOYEE_TYPES = {
    ...types('LOGINEMPLOYEE'),
    logout: 'LOGINEMPLOYEE_LOGOUT',
}
export const LOGINEMPLOYEE_ACTIONS = {
    ...actions('LOGINEMPLOYEE'),
    logout: (result, dispatch) =>
        dispatch({
            type: LOGINEMPLOYEE_TYPES.logout,
        }),
}
