export function getLastSWA300Data(data) {
    let { getLastSWA300DataRequest, getLastSWA300DataError, getLastSWA300DataResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_last_swa300_data',
        this.createBundle(
            'get_last_swa300_data',
            { data: data },
            this.GET,
            getLastSWA300DataRequest,
            getLastSWA300DataResult,
            getLastSWA300DataError
        )
    )
}
