import { FILTERS_TYPES } from '../actions/filters'
const Reducer = (
    state = {
        filterobj: {}
    },
    action
) => {
    switch (action.type) {
        case FILTERS_TYPES.result: {
            let { result } = action
            return {
                ...result
            }
        }
        default:
            return state
    }
}
export default Reducer
