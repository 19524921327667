import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Image, Card, Dimmer, Icon, Loader, Menu, Modal, Segment } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
import { buildFileSelector } from '../lib/util'
import { ImageDefault, Empty } from '../components'

class PickImage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            items: 12,
            image: this.props.value ? this.props.value : '',
            showModal: false,
            fileSelector: buildFileSelector(this.handleUpload),
        }
    }

    componentDidMount() {
        const { page, items } = this.state
        const { network } = this.props
        console.info('get image list')
        network.getImageList(page, items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { network, error } = this.props

        if (this.props.value !== prevProps.value) {
            this.setState({
                image: this.props.value,
            })
        }

        if (!this.props.uploadImage.fetching && this.props.uploadImage !== prevProps.uploadImage) {
            if (this.props.uploadImage.status.success) {
                return this.pickImage(this.props.uploadImage.data.file)
            } else {
                return error(createAlert('Errore di caricamento', "L'immagine è troppo grande."))
            }
        }

        if (this.state.page !== prevState.page || this.state.items !== prevState.items) {
            network.getImageList(this.state.page, this.state.items)
        }
    }

    pickImage(image) {
        let { onChange } = this.props
        if (onChange) {
            setTimeout(() => onChange(image), 1000)
        }
        this.closeModal()
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    uploadImage = () => {
        this.state.fileSelector.click()
    }

    handleUpload = (event, name) => {
        const { network } = this.props
        let obj = { loaded: 0 }
        obj[name] = event.target.files[0]
        this.setState(obj)
        //console.log('Selected file', event.target.files[0]);

        network.uploadImage(event.target.files[0])
    }

    render() {
        let { image } = this.state
        const { fetching, images } = this.props

        return (
            <>
                <View key="trigger" style={{ margin: 16 }} fullw between>
                    <Button style={{ height: 64 }} onClick={() => this.setState({ showModal: true })}>
                        Scegli Immagine
                    </Button>

                    <ImageDefault
                        fromRoot={true}
                        style={{
                            width: 200,
                            height: 200,
                            objectFit: 'cover',
                        }}
                        src={this.state.image}
                    />
                </View>
                <Modal key="modal" onClose={this.closeModal} open={this.state.showModal} closeIcon>
                    <Modal.Header>Scelta image</Modal.Header>
                    <Modal.Content image scrolling style={{ padding: 0 }}>
                        <View fullw column>
                            <Menu horizontal style={{ borderRadius: '0px' }}>
                                <Menu.Item as="a" icon="upload" name="Carica image" onClick={this.uploadImage} position={'left'} />
                                <Menu.Item
                                    as="a"
                                    icon="arrow left"
                                    onClick={() => {
                                        this.prev()
                                    }}
                                    position={'right'}
                                />
                                <Menu.Item
                                    as="a"
                                    icon="arrow right"
                                    onClick={() => {
                                        this.next()
                                    }}
                                    position={'none'}
                                />
                            </Menu>
                            <Modal.Description style={{ maxWidth: '100%' }}>
                                {!fetching && (
                                    <View fullw wrap row around style={{ padding: 16 }}>
                                        {images && images.length === 0 && <Empty />}
                                        {images &&
                                            images.length > 0 &&
                                            images.map((value, index) => (
                                                <Card
                                                    key={index}
                                                    onClick={() => {
                                                        this.pickImage(value.picturePath)
                                                    }}
                                                    style={{
                                                        width: '200px',
                                                        height: '200px',
                                                        marginTop: 16,
                                                        marginBottom: 16,
                                                    }}
                                                >
                                                    <ImageDefault
                                                        fromRoot
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        src={value.picturePath}
                                                        ui={false}
                                                    />
                                                    {this.state.picturePath === value.picturePath && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                bottom: 0,
                                                                left: 0,
                                                                right: 0,
                                                                background: 'rgba(99, 177, 255, 0.60)',
                                                                zIndex: 2,
                                                            }}
                                                        />
                                                    )}
                                                </Card>
                                            ))}
                                    </View>
                                )}
                                {fetching && (
                                    <Segment style={{ minHeight: '600px' }}>
                                        <Dimmer active inverted>
                                            <Loader inverted />
                                        </Dimmer>
                                    </Segment>
                                )}
                            </Modal.Description>
                        </View>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={this.closeModal}>
                            Ok <Icon name="check" />
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getImageList: {
            data: { data },
            fetching,
        },
        uploadImage,
    } = state

    return {
        images: data,
        fetching,
        uploadImage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PickImage)))
