export function getLastBabypressaData(data) {
    let { getLastBabypressaDataRequest, getLastBabypressaDataError, getLastBabypressaDataResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_last_babypressa_data',
        this.createBundle(
            'get_last_babypressa_data',
            { data: data },
            this.GET,
            getLastBabypressaDataRequest,
            getLastBabypressaDataResult,
            getLastBabypressaDataError
        )
    )
}
