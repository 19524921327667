import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Label, Table } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Empty } from '../components'

class CustomTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            costDict: {},
            total: 0,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { total } = this.state
        let { onCostChange, shorthand } = this.props

        if (!isNaN(total) && total !== prevState.total) {
            if (onCostChange) {
                onCostChange(total, shorthand)
            }
        }
    }

    render() {
        let { orderBy, name, shorthand, values, child, required, childProps, isBalance, compact, network, selected, ...rest } = this.props
        let { costDict } = this.state
        let Inner = child

        let label = this.props.nolabel ? null : (
            <Label key={`table_label_${shorthand}`} as="a" color="blue" ribbon>
                {name}
            </Label>
        )

        let isEmpty = false
        if (required) {
            isEmpty = true
            for (let i in values) {
                if (values[i][required] !== null) {
                    isEmpty = false
                    break
                }
            }
        }

        if (!childProps) {
            childProps = {
                ...rest,
            }
        }

        let hourtotal = 0
        if (isBalance) {
            //console.log("Hourtotal counting from values", values);
            for (let i in values) {
                let value = values[i]
                if (value.workerHours) {
                    hourtotal += value.workerHours + value.workerMinutes / 60
                } else if (value.machineHours) {
                    hourtotal += value.machineHours + value.machineMinutes / 60
                }
            }
            //console.log("Hourtotal Result = ", hourtotal);
        }

        let total = 0
        if (isBalance) {
            childProps.isBalance = isBalance
            childProps.onCostChange = (newCost, id) => {
                let costDict = this.state.costDict
                let total = 0
                costDict[id] = newCost

                for (let i in costDict) {
                    total += costDict[i]
                }

                total = Math.round(total * 100) / 100

                this.setState({
                    costDict,
                    total,
                })
            }

            for (let i in costDict) {
                total += costDict[i]
            }

            total = Math.round(total * 100) / 100
        }

        if (orderBy) {
            values.sort(orderBy)
        }

        return values && values.length > 0 && !isEmpty
            ? [
                  label,
                  !compact && (
                      <Table fixed style={this.props.style} key={`table_content_${shorthand}`} striped {...{ selectable: !!this.props.onRowClick }}>
                          <Inner type="header" {...childProps} />
                          <Table.Body>
                              {values.map((value, index) => {
                                  let childProp = {
                                      [shorthand]: value,
                                  }

                                  return value[required] !== null ? (
                                      <Inner
                                          onClick={this.props.onRowClick}
                                          type="table"
                                          selected={this.props.selected ? this.props.selected(value) : false}
                                          key={`tablerow-normal-${shorthand}-${index}`}
                                          {...childProp}
                                          {...childProps}
                                      />
                                  ) : (
                                      <View key={`nullvalue-${shorthand}-${index}`} />
                                  )
                              })}
                              {isBalance && <Inner hourtotal={hourtotal} total={total} type="footer" {...childProps} />}
                          </Table.Body>
                      </Table>
                  ),
                  compact && (
                      <View key={`table_compact_${shorthand}`} row fullw fullh wrap>
                          {values.map((value, index) => {
                              let childProp = {
                                  [shorthand]: value,
                              }

                              return value[required] !== null ? (
                                  <Inner
                                      onClick={this.props.onRowClick}
                                      type="compact"
                                      selected={this.props.selected ? this.props.selected(value) : false}
                                      key={`tablerow-compact-${shorthand}-${index}`}
                                      {...childProp}
                                      {...childProps}
                                  />
                              ) : (
                                  <View key={`nullvalue-${shorthand}-${index}`} />
                              )
                          })}
                      </View>
                  ),
              ]
            : [label, <Empty key={`empty_${shorthand}`} type={compact ? 'compact' : 'small'} />]
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getDetailedJobList, search } = state

    return {
        getDetailedJobList,
        search,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CustomTable)))
