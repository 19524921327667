export function getDataImportList(data) {
	let { getDataImportListRequest, getDataImportListError, getDataImportListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_dataimport_list',
		this.createBundle(
			'get_dataimport_list',
			{ dataimport: data },
			this.GET,
			getDataImportListRequest,
			getDataImportListResult,
			getDataImportListError
		)
	);
}
