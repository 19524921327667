export function getLastGT2000Data(data) {
    let { getLastGT2000DataRequest, getLastGT2000DataError, getLastGT2000DataResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_last_gt2000_data',
        this.createBundle(
            'get_last_gt2000_data',
            { data: data },
            this.GET,
            getLastGT2000DataRequest,
            getLastGT2000DataResult,
            getLastGT2000DataError
        )
    )
}
