export function getCostList(job, start, end) {
    let { getCostListRequest, getCostListResult, getCostListError } = this.props

    const reqobj = { job }

    if (start && end) {
        reqobj.start = start
        reqobj.end = end
    }

    this.doAction(
        this.WEB_REQUEST_URL + 'get_cost_list',
        this.createBundle('get_cost_list', reqobj, this.GET, getCostListRequest, getCostListResult, getCostListError)
    )
}
