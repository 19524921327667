import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { withRouter } from 'react-router'

import View from 'lib/View'
import { Tab, Menu } from 'semantic-ui-react'
import { initializePage, baseViewDispatch } from '../lib/util'
import Realtime from './Realtime'
import Analytics from './Analytics'

import _ from 'lodash/lang'
import moment from 'moment-timezone'
import Moment from 'react-moment'

moment.locale('it')
Moment.globalMoment = moment
Moment.globalLocale = 'it'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        initializePage(this, [], {})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {}
    panes = () => [
        {
            menuItem: (
                <Menu.Item style={{ paddingLeft: 30 }} key="real-time">
                    Real-time
                </Menu.Item>
            ),
            render: () => <Realtime />,
        },
        {
            menuItem: 'Analytics',
            render: () => <Analytics />,
        },
    ]

    render() {
        return <Tab menu={{ secondary: true, pointing: true }} panes={this.panes()} />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Dashboard)))
