import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Document } from '../components'

class SingleDocument extends Component {
    constructor(props) {
        super(props)
        let { document } = this.props.match.params

        this.state = {
            document,
        }
    }

    componentDidUpdate() {}

    componentDidMount() {}

    render() {
        let { document } = this.state
        console.log('Loading document', document)
        return (
            <View column fullw fullh>
                <Document forcedOpen={true} showDetails={true} documentId={document} type="full" />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SingleDocument)))