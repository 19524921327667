export function updateMachineType(data) {
    let { updateMachineTypeRequest, updateMachineTypeError, updateMachineTypeResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'update_machine_type',
        this.createBundle(
            'update_machine_type',
            { machinetype: data },
            this.POST,
            updateMachineTypeRequest,
            updateMachineTypeResult,
            updateMachineTypeError
        )
    )
}
