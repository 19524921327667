export function deleteCollection(data) {
	let { deleteCollectionRequest, deleteCollectionError, deleteCollectionResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_collection',
		this.createBundle(
			'delete_collection',
			{ collection: data },
			this.POST,
			deleteCollectionRequest,
			deleteCollectionResult,
			deleteCollectionError
		)
	);
}
