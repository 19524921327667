import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Empty, Worker } from '../components'
import { baseViewDispatch, overviewData } from '../lib/util'

import moment from 'moment-timezone'

const { app, employees, newEl, workerEdit } = routes

class Workers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteWorker, derivedRequest } = this.state
        let { workerId, getFromDerived, organization, workers, fetching, status, network } = this.props

        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteWorker.fetching !== prevState.deleteWorker.fetching && !deleteWorker.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getWorkerList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getWorkerList(this.state.search.data)
        }

        // Get hades data for worker list
        if (workers.data && workers.data.length > 0 && workers.data != prevProps.workers.data) {
            this.setState(
                (pstate) => ({
                    derivedRequest: 'worker_job',
                }),
                () => {
                    let reqs = []
                    for (let worker of workers.data) {
                        let id = worker.workerIdentifier.toString()
                        if (this.state[`${id}-overview`]) return
                        reqs.push({
                            destination: 'worker_job',
                            id,
                            sub_id: 'default',
                            limit: 1,
                        })
                    }
                    network.getFromDerived(reqs)
                }
            )
        }

        // Derived Data
        if (getFromDerived && getFromDerived.fetching !== prevProps.getFromDerived.fetching && !getFromDerived.fetching) {
            if (getFromDerived.status.success && getFromDerived.data) {
                let {
                    getFromDerived: { data: hadesData },
                } = this.props
                switch (derivedRequest) {
                    case 'worker_job':
                        let jobsWorks = []
                        for (let hdata of hadesData) {
                            let { data = [] } = hdata || {}
                            for (let item of data) {
                                let { job, work } = item
                                if (!jobsWorks.some((el) => el.job === job && el.work == work)) {
                                    jobsWorks.push({ job, work })
                                }
                            }
                        }
                        let reqs = []
                        for (let jw of jobsWorks) {
                            let { job, work } = jw

                            reqs.push({
                                destination: 'job',
                                id: `${job}`,
                                sub_id: `${work}`,
                                filters: [
                                    {
                                        key: 'timestamp',
                                        symbol: '>=',
                                        value: moment().subtract(7, 'days'),
                                    },
                                ],
                            })
                        }
                        this.setState({ derivedRequest: 'job' }, () => network.getFromDerived(reqs))
                        break
                    case 'job':
                        for (let hdata of hadesData) {
                            let { data = [] } = hdata || {}
                            let firstRow = data[0] || {}
                            let { id } = firstRow
                            this.setState({ [`${id}-overview`]: [...data] })
                        }
                        break
                }
            }
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_job) {
            extraTools.push({
                name: 'Crea nuovo impiegato',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, employees, newEl, workerEdit))
                },
            })
        }

        this.props.toolbarResult([
            ...extraTools,
            // Not paginated yer
            // {
            // 	name: '',
            // 	icon: 'arrow left',
            // 	position: 'right',
            // 	action: () => {
            // 		this.prev()
            // 	}
            // },
            // {
            // 	name: '',
            // 	icon: 'arrow right',
            // 	position: 'none',
            // 	action: () => {
            // 		this.next()
            // 	}
            // }
        ])

        this.state.network.getWorkerList(this.state.search.data)
    }

    renderWorker(key, worker) {
        let id = worker.workerIdentifier.toString()
        return (
            <Worker
                type="full"
                key={`worker_${key}`}
                worker={worker}
                overview={overviewData(`${worker.workerIdentifier}`, 'operator', this.state[`${id}-overview`])}
            />
        )
    }

    render() {
        let content = <div />

        if (this.state.fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card style={{ width: '100%' }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (this.state.workers.data && this.state.workers.data.length > 0) {
            content = this.state.workers.data.map((value, index) => {
                return this.renderWorker(index, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getWorkerList,
        search,
        deleteWorker,
        organization,
        login,
        hades: {
            hadesToken: {
                status: { success: hadesTokenOk },
            },
            getFromDerived,
        },
    } = state

    return {
        workers: getWorkerList.data,
        fetching: getWorkerList.fetching,
        deleteWorker,
        search,
        login,
        organization,
        getFromDerived,
        hadesTokenOk,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Workers)))
