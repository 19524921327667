export function getCollectionsFull(data) {
	let { getCollectionsFullRequest, getCollectionsFullError, getCollectionsFullResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_collections_full',
		this.createBundle(
			'get_collections_full',
			{ collection: data },
			this.GET,
			getCollectionsFullRequest,
			getCollectionsFullResult,
			getCollectionsFullError
		)
	);
}
