export function deleteDataImport(data) {
	let { deleteDataImportRequest, deleteDataImportError, deleteDataImportResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_dataimport',
		this.createBundle(
			'delete_dataimport',
			{ dataimport: data },
			this.GET,
			deleteDataImportRequest,
			deleteDataImportResult,
			deleteDataImportError
		)
	);
}
