export function deleteMapping(data) {
	let { deleteMappingRequest, deleteMappingError, deleteMappingResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_mapping',
		this.createBundle(
			'delete_mapping',
			{ mapping: data },
			this.POST,
			deleteMappingRequest,
			deleteMappingResult,
			deleteMappingError
		)
	);
}
