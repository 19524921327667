import { types, actions } from './requests'

export const GETFROM_TYPES = {
    ...types('GETFROM'),
}
export const GETFROM_DERIVED_TYPES = {
    ...types('GETFROM_DERIVED'),
}
export const HADESTOKEN_TYPES = {
    ...types('HADESTOKEN'),
}

export const GETFROM_ACTIONS = {
    ...actions('GETFROM'),
}
export const GETFROM_DERIVED_ACTIONS = {
    ...actions('GETFROM_DERIVED'),
}
export const HADESTOKEN_ACTIONS = {
    ...actions('HADESTOKEN'),
}
