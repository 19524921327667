export function getArticleList(page, amount, search, deposit) {
    let { getArticleListRequest, getArticleListError, getArticleListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'app/stock/get_article_list',
        this.createBundle(
            'app/stock/get_article_list',
            { page, amount, search, deposit },
            this.POST,
            getArticleListRequest,
            getArticleListResult,
            getArticleListError
        )
    )
}
