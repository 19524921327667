import React from 'react'
import { Table, Label, Segment, Header, Icon } from 'semantic-ui-react'

const SimpleTable = ({ label, labelColor, color, heads = [], bodies = [], total = [] }) => (
    <>
        {label && (
            <Label color={labelColor} ribbon>
                {label}
            </Label>
        )}
        {bodies.length == 0 && (
            <Segment placeholder>
                <Header icon>
                    <Icon name="eye slash outline" />
                    Nessun risultato disponibile
                </Header>
            </Segment>
        )}
        {bodies.length > 0 && (
            <Table color={color} key={color} inverted>
                <Table.Header>
                    <Table.Row>
                        {heads.map((value, index) => (
                            <Table.HeaderCell key={index}>{value}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {bodies.map((rows, rindex) => (
                        <Table.Row key={rindex}>
                            {rows.map((value, index) => (
                                <Table.Cell key={index}>{value}</Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
                {total.length > 0 && (
                    <Table.Footer>
                        <Table.Row key={'total'}>
                            <Table.Cell>
                                <Label ribbon>Totale</Label>
                            </Table.Cell>
                            {total.map((value, index) => (
                                <Table.Cell key={index}>{value}</Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Footer>
                )}
            </Table>
        )}
    </>
)

export default SimpleTable
