import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class ProductEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { product } = this.props.match.params

        if (!id) {
            id = product
        }

        console.log('ProductEditor params match', this.props.match, id)

        this.state = {
            id,
            code: id,
        }
    }

    componentDidMount() {
        let { code } = this.state
        console.log('ProductEditor code is', code)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (code) {
            this.state.network.getProduct(code)
        }
        this.state.network.getCategoryList()
        this.state.network.getCorpList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        console.log('JobEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                console.log(
                    'Create result is: ',
                    this.props.createResult,
                    this.props.createStatus,
                    this.props.createStatus.success ? 'Success!' : 'Failure!'
                )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getProduct } = this.props
        if (getProduct && getProduct.fetching !== prevProps.getProduct.fetching && !getProduct.fetching) {
            if (getProduct.status.success) {
                let product = getProduct.data.data
                console.log('Setting state with product:', product)
                let { id, ...rest } = product
                this.setState(rest)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { id, code, id_corp, id_category, description, price } = this.state

        if (code === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createProduct(id, {
            code,
            id_corp,
            id_category,
            description,
            price,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, code, id_category, id_corp, description, options, corpOptions, price, getProduct } = this.state

        console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getProduct.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-product-name"
                            control={Input}
                            label="Codice prodotto"
                            name="code"
                            value={code}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-product-name"
                            control={Input}
                            label="Descrizione"
                            name="description"
                            value={description}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-product-type"
                            control={Select}
                            options={options}
                            label="Categoria prodotto"
                            placeholder="Categoria prodotto"
                            name="id_category"
                            value={id_category}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Categoria selected', id)
                                this.setState({ id_category: id })
                            }}
                        />
                        <Form.Field
                            id="form-input-control-product-type"
                            control={Select}
                            options={corpOptions}
                            label="Corpo"
                            placeholder="Corpo"
                            name="id_corp"
                            value={id_corp}
                            onChange={(e, data) => {
                                let id = data.value
                                console.log('Corp selected', id)
                                this.setState({ id_corp: id })
                            }}
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
						<Form.Field
							id="form-textarea-control-image"
							control={Input}
							label="Immagine"
							placeholder="Immagine"
							name="productImage"
							value={productImage}
							disabled
							onChange={(e) => this.handleInput(e)}
						/>
						<PickImage
							onChange={(image) => {
								this.setState({ productImage: image });
							}}
							value={this.state.productImage}
						/>
					</Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-product-cost"
                            control={Input}
                            type="number"
                            label="Prezzo"
                            placeholder="0.0"
                            step="0.1"
                            name="price"
                            value={price}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getCorpList: {
            data: { corps },
            fetching: fetchCorp,
        },
        getCategoryList: {
            data: { category },
            fetching: fetchCategory,
        },
        createProduct: { data: createResult, fetching: creating, status: createStatus },
        getProduct,
    } = state

    let options = []
    for (let i in category) {
        let type = category[i]
        options.push({
            key: type.id,
            text: type.name,
            value: type.id,
        })
    }

    let corpOptions = []
    for (let i in corps) {
        let type = corps[i]
        corpOptions.push({
            key: type.id,
            text: type.name,
            value: type.id,
        })
    }

    return {
        options,
        corpOptions,
        fetchCorp,
        fetchCategory,
        createResult,
        creating,
        getProduct,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ProductEditor)))
