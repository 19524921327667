export function getFamily(data) {
	let { getFamilyRequest, getFamilyError, getFamilyResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_family',
		this.createBundle(
			'get_family',
			{ family: data },
			this.GET,
			getFamilyRequest,
			getFamilyResult,
			getFamilyError
		)
	);
}
